import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"

import LineaDiagonale from "../components/linea-diagonale"
import Layout from "../components/layout"
import Filters from "../components/filters"
import Carousel from "../components/carousel"
import FiltersMobile from "../components/filters-mobile"
import { GiftBox } from "../components/gift-box"
import magnifyingGlassIcon from "../img/magnifying-glass.svg"

import heroImage from "../img/home/hero-new.jpg"
import oggettistica from "../img/home/oggettistica.png"
import bottiglieSpeciali from "../img/home/speciali-transparent.png"
import bottiglieSpecialiOfftrade from "../img/home/speciali-offtrade.png"
import quattroSeiBottiglie from "../img/home/4-6bottiglie-transparent.png"
import unaTreBottiglie from "../img/home/1-3bottiglie-transparent.png"
import beautyshots from "../img/home/beautyshots.jpg"

import {
  brandHasAtLeaseOneProductWithPriceDefinedForCurrentProfile,
  productHasPriceDefinedForCurrentProfile,
} from "../utils"

import "./index.css"
import { getImageUrl } from "../utils/getImageUrl"
import { FormattedMessage } from "react-intl"

export default function App() {
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const products = useSelector(state => state.products)
  const filters = useSelector(state => state.filters)
  if (!auth.isLoggedIn && window.location.pathname !== `/login`) {
    history.push("/login", { redirectTo: "/confezioni-regalo" })
    return null
  }

  function openMenu() {
    dispatch({ type: "MENU_OPEN" })
  }

  function handleFilterChange() {
    history.push("/catalogo/")
  }

  function filtra1_3bottiglie() {
    dispatch({ type: "FILTER_FORCE", payload: { totaleBottiglie: ["1_3"] } })
    history.push("/catalogo/")
  }

  function filtra4_6bottiglie() {
    dispatch({ type: "FILTER_FORCE", payload: { totaleBottiglie: ["4_6"] } })
    history.push("/catalogo/")
  }

  function filtraSpeciali() {
    dispatch({ type: "FILTER_FORCE", payload: { edizione: ["Edizione Speciale"] } })
    history.push("/catalogo/")
  }

  function filtraBrand(brand) {
    dispatch({ type: "FILTER_FORCE", payload: { brand: [brand] } })
    history.push("/catalogo/")
  }

  function filtraOggettistica() {
    const allOggettistica = products.allOggettistica.map(e => e.descrizione)
    dispatch({ type: "FILTER_FORCE", payload: { tipologiaConfezione: allOggettistica } })
    history.push("/catalogo/")
  }

  return (
    <Layout>
      <div
        className="Hero relative overflow-y-hidden xl:overflow-y-visible"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="Hero__Text [ absolute inset-0 z-10 ] [ flex flex-col ]">
          <div
            className="
              flex flex-col items-center justify-center flex-1
              text-white
              px-8
            "
          >
            <div className="Home__Title mb-12 font-BodoniBook text-4xl md:text-6xl text-center leading-none text-white">
              Confezioni Regalo
            </div>
            <input
              type="text"
              placeholder="Cerca"
              className="Home__Cerca mb-8 p-4 font-LatoRegular text-lg"
              style={{ backgroundImage: `url(${magnifyingGlassIcon})` }}
              onClick={openMenu}
            />
            {/* <Link to="/catalogo" className="Home__VediTutto font-LatoBold uppercase text-11px tracking-2">
              Vedi tutto
            </Link> */}
          </div>

          <Filters force onChange={handleFilterChange} />
        </div>
      </div>

      <FiltersMobile />

      {/* <div className="Home__QuckFilterNumBottiglie grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-dirty">
        <div className="px-8 py-16 cursor-pointer flex flex-col items-center" onClick={filtra1_3bottiglie}>
          <img alt="1-3 bottiglie" loading="eager" src={unaTreBottiglie} />
          <div className="font-BodoniBook text-20px text-center">1-3 bottiglie</div>
        </div>
        <div className="px-8 py-16 cursor-pointer" onClick={filtra4_6bottiglie}>
          <img alt="4-6 bottiglie" loading="eager" src={quattroSeiBottiglie} />
          <div className="font-BodoniBook text-20px text-center">4-6 bottiglie</div>
        </div>
        <div className="px-8 py-16 cursor-pointer" onClick={filtraSpeciali}>
          {auth.listino === "gddo" && <img alt="bottiglie speciali" loading="eager" src={bottiglieSpecialiOfftrade} />}
          {auth.listino !== "gddo" && <img alt="bottiglie speciali" loading="eager" src={bottiglieSpeciali} />}

          <div className="font-BodoniBook text-20px text-center">Speciali</div>
        </div>
        <div className="px-8 py-16 cursor-pointer" onClick={filtraOggettistica}>
          <img alt="oggettistica" loading="eager" src={oggettistica} />
          <div className="font-BodoniBook text-20px text-center">Oggettistica</div>
        </div>
      </div> */}

      {/* <div
        className="Home__Banner relative overflow-hidden"
        // onClick={() => filtraBrand("Perrier-Jouët")}
        style={{ backgroundImage: `url(${beautyshots})` }}
      ></div> */}

      {products.allBrands
        .filter(brand =>
          brandHasAtLeaseOneProductWithPriceDefinedForCurrentProfile(products.allProducts, brand, auth.listino)
        )
        .map(brand => {
          const boxesForThisBrand = products.allProducts.filter(box =>
            box.brands.find(boxBrand => boxBrand.Nome === brand.Nome)
          )

          return (
            <div className={`Home__BrandWrapper`} key={brand.id}>
              <div className="Home__Brand [ px-4 lg:px-32 py-16 lg:py-32 ]">
                <div className="flex flex-col-reverse lg:flex-row lg:items-center justify-between">
                  <div className="[ text-40px lg:text-60px font-BodoniBook leading-tight ] mt-12 lg:mt-0">
                    {brand.Nome}
                  </div>
                  <div className="Home__Brand__Logo relative flex items-center justify-end">
                    <img src={getImageUrl(brand.Logo.url)} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                  </div>
                </div>

                <button
                  className="font-LatoBold uppercase text-11px tracking-2 px-4 py-2 flex flex-row items-center gap-2"
                  onClick={() => filtraBrand(brand.Nome)}
                >
                  <LineaDiagonale />
                  <FormattedMessage id="pos.discover.more" />
                </button>

                <div className="Home__Brand__Products">
                  <Carousel>
                    {boxesForThisBrand
                      .filter(node => {
                        return productHasPriceDefinedForCurrentProfile(node, auth.listino)
                      })
                      .map(giftBox => (
                        <GiftBox product={giftBox} key={giftBox.id} />
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          )
        })}
    </Layout>
  )
}
