import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory, useLocation, useRouteMatch } from "react-router"
import { getImageUrl } from "../../utils/getImageUrl"

//assets
import ChevronIcon from "../../components/chevron-icon"
import hero from "../../img/catalog/hero.jpg"
import magnifyingGlassIcon from "../../img/magnifying-glass.svg"

//components
import Header from "./../../components/header/index"
import Menu from "./../../components/menu/index"
import { PosItem } from "../../components/pos-item"
import LineaDiagonale from "../../components/linea-diagonale"
import Carousel from "../../components/carousel"
import Rodal from "rodal"
import { FormattedMessage, useIntl } from "react-intl"
import FilterItem from "../../components/filter-item"
import { isAnyFilterSelected } from "../../utils"

export const CatalogoPos = () => {
  const heroImage = hero

  const [currentBrand, setCurrentBrand] = useState(null)
  const isMenuOpen = useSelector(state => state.menu)
  const locale = useSelector(state => state.tenute.locale)
  const posBrands = useSelector(state => state.posproducts.allBrands.map(brand => brand.Nome)).sort((a, b) =>
    a > b ? 1 : -1
  )

  const posBrandsObjects = useSelector(state => state.posproducts.allBrands)
  const posTypes = useSelector(state =>
    state.posproducts.allTypes.map(type => (locale === "it" ? type.descrizione_ita : type.descrizione_eng))
  )
  const intl = useIntl()

  const posMaterials = useSelector(state =>
    state.posproducts.allMaterials.map(material =>
      locale === "it" ? material.descrizione_ita : material.descrizione_eng
    )
  )
  const auth = useSelector(state => state.auth)
  const history = useHistory()

  const posproducts = useSelector(state => state.posproducts.filteredProducts)
  const posPrices = ["0-30", "30-80", "80-100", "100-120", "120-150", "150-200"]
  const posFilters = useSelector(state => state.posproducts.filters)
  const [modalVisible, setModalVisible] = useState(false)

  const location = useLocation()
  const { tenutaId } = useParams()
  const dispatch = useDispatch()

  const isFilterActive = isAnyFilterSelected(posFilters)

  useEffect(() => {
    document.body.style.overflow = "auto"
  }, [])

  const getProducts = async () => {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/pos-items?_limit=1000`)
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: "LOADED_OBJECTS", payload: data })
      })
  }

  useEffect(() => {
    if (!tenutaId && !isFilterActive) {
      getProducts()
      dispatch({ type: "POS_FILTER_BRAND", payload: [] })
    }
  }, [])

  useEffect(() => {
    if (tenutaId) {
      const brandToUseAsFilter = posBrandsObjects.find(brandItem => brandItem.id === parseInt(tenutaId))
      setCurrentBrand(brandToUseAsFilter)
      dispatch({ type: "POS_FILTER_BRAND", payload: [brandToUseAsFilter.Nome] })
    }
  }, [])

  const openMenu = () => {
    dispatch({ type: "MENU_OPEN" })
  }

  const switchToAnotherBrand = e => {
    if (e.length === 0) {
      window.location.href = `/pos`
    } else {
      const brandToSwitchTo = posBrandsObjects.find(brandItem => brandItem.Nome === e[e.length - 1])
      window.location.href = `/pos/${brandToSwitchTo.id}`
    }
  }

  if (!auth.isLoggedIn && location.pathname !== `/login`) {
    history.push("/login", { redirectTo: "/pos" })
    return null
  }

  const getItemPrice = productItem => {
    const itemPriceSource = `prezzo_${auth.listinoPOS === "europa" ? "confezione_eu" : "confezione"}`
    const itemPrice = productItem[itemPriceSource]

    return itemPrice
  }

  const getBrandProducts = brandItem => {
    const brandProducts = posproducts.filter(productItem => {
      if (productItem.brand && productItem.brand.Nome && getItemPrice(productItem) > 0) {
        return productItem.brand.Nome === brandItem
      }
      return false
    })

    return brandProducts
  }

  return (
    <>
      <div className={`Layout`}>
        <Header />
        <Menu isOpen={isMenuOpen} posProducts={posproducts} isCatalogoPos />
        <div
          className="Catalog__Hero relative overflow-y-hidden xl:overflow-y-visible"
          style={{
            backgroundImage: `url(${
              currentBrand
                ? currentBrand.copertina.url
                  ? getImageUrl(currentBrand.copertina.url)
                  : getImageUrl(heroImage)
                : heroImage
            })`,
          }}
        >
          <div className="Hero__Text [ absolute inset-0 z-10 ] [ flex flex-col ]">
            <div
              className="
              flex flex-col items-center justify-center flex-1
              text-white
              px-8
            "
            >
              <div className="Home__Title mb-12 font-BodoniBook text-4xl md:text-6xl text-center leading-none text-white">
                {currentBrand ? currentBrand.Nome : <FormattedMessage id="tenute.landing.catalogo_pos" />}
              </div>
              <input
                type="text"
                placeholder={intl.formatMessage({ id: "tenute.cerca" })}
                className="Home__Cerca mb-8 p-4 font-LatoRegular text-lg"
                style={{ backgroundImage: `url(${magnifyingGlassIcon})` }}
                onClick={openMenu}
              />
            </div>
            <div className="hidden xl:flex w-full  items-center justify-around text-white">
              <FilterItem
                name={intl.formatMessage({ id: "pos.filter.label.brand" })}
                options={posBrands}
                selected={posFilters.brand}
                onChange={e => {
                  tenutaId ? switchToAnotherBrand(e) : dispatch({ type: "POS_FILTER_BRAND", payload: e })
                }}
              />

              <FilterItem
                name={intl.formatMessage({ id: "pos.filter.label.tipi" })}
                options={posTypes}
                selected={posFilters.type}
                sortOptions
                onChange={e => dispatch({ type: "POS_FILTER_TYPE", payload: e })}
              />
              <FilterItem
                name={intl.formatMessage({ id: "pos.filter.label.materiali" })}
                options={posMaterials}
                selected={posFilters.material}
                sortOptions
                onChange={e => dispatch({ type: "POS_FILTER_MATERIAL", payload: e })}
              />
              <FilterItem
                name={intl.formatMessage({ id: "pos.filter.label.prezzi" })}
                options={posPrices}
                selected={posFilters.price}
                onChange={e => dispatch({ type: "POS_FILTER_PRICE", payload: e })}
              />
            </div>
          </div>
        </div>
        <div className="p-6 xl:hidden">
          <div
            className="
          font-LatoBold uppercase text-11px tracking-2 
          border border-gray 
          py-6 px-8
          flex items-center justify-between
        "
            onClick={() => {
              document.body.style.overflow = "hidden"
              setModalVisible(true)
            }}
          >
            <FormattedMessage id="pos.info.filtra" />
            <ChevronIcon />
          </div>

          <FilterModal
            posInfo={{
              posBrands: posBrands,
              posTypes: posTypes,
              posMaterials: posMaterials,
              posPrices: posPrices,
              posFilters: posFilters,
            }}
            dispatch={dispatch}
            isOpen={modalVisible}
            onClose={() => {
              document.body.style.overflow = "auto"
              setModalVisible(false)
            }}
            switchToAnotherBrand={tenutaId ? switchToAnotherBrand : undefined}
          />
        </div>

        {tenutaId ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
            {posproducts &&
              posproducts.map((item, index) => (
                <PosItem key={`pos-item-${item.codice_articolo}`} posItem={item} striped />
              ))}
          </div>
        ) : (
          posBrands.map(brandItem => {
            const brandProducts = getBrandProducts(brandItem)
            if (brandProducts && brandProducts.length > 0) {
              return (
                <div className={`Home__BrandWrapper`} key={brandProducts[0].brand.id}>
                  <div className="Home__Brand [ px-4 lg:px-32 py-16 lg:py-32 ]">
                    <div className="flex flex-col-reverse lg:flex-row lg:items-center justify-between">
                      <div className="[ text-40px lg:text-60px font-BodoniBook leading-tight ] mt-12 lg:mt-0">
                        {brandItem}
                      </div>
                      <div className="Home__Brand__Logo relative flex items-center justify-end">
                        <img src={brandProducts[0].brand.Logo.url} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                      </div>
                    </div>
                    <a href={`/pos/${brandProducts[0].brand.id}`}>
                      <button className="pointer-events-none font-LatoBold uppercase text-11px tracking-2 px-4 py-2 flex flex-row items-center gap-2">
                        <LineaDiagonale />
                        <FormattedMessage id="pos.discover.more" />
                      </button>
                    </a>
                    <div className="Home__Brand__Products">
                      <Carousel>
                        {brandProducts.map(item => {
                          item.price = getItemPrice(item)
                          return <PosItem key={`pos-item-${item.codice_articolo}`} posItem={item} striped />
                        })}
                      </Carousel>
                    </div>
                  </div>
                </div>
              )
            }
          })
        )}
      </div>
    </>
  )
}

// function FilterItem({ name, options, onChange, selected, force, isMobile = false, sortOptions }) {
//   const [visible, setVisible] = useState(false)
//   const memoizedSortedOptions = useMemo(() => {
//     if (sortOptions) {
//       return options.sort()
//     } else {
//       return options
//     }
//   }, [options])

//   function handleClick(option) {
//     // Se ho cliccato "Vedi tutti" resetto i filtri
//     if (option === "all") {
//       return onChange([])
//     }

//     // Se force è a true (solo in home page) ritorno solo l'opzione cliccata
//     if (force) {
//       return onChange([option])
//     }

//     // Se l'opzione cliccata è già selezionata, la rimuovo, altrimenti la aggiungo
//     const newValue = selected && selected.includes(option) ? selected.filter(o => o !== option) : [...selected, option]

//     onChange(newValue)
//   }

//   function handleClickTitle() {
//     if (isMobile) {
//       setVisible(!visible)
//     }
//   }

//   return (
//     <div
//       className="flex items-center flex-1 justify-center py-8 relative cursor-pointer"
//       onMouseEnter={() => setVisible(true)}
//       onMouseLeave={() => setVisible(false)}
//       onClick={handleClickTitle}
//     >
//       <span className="font-LatoBold text-11px tracking-2 uppercase xl:text-white">
//         {name}
//         {selected.length > 0 && <span> ({selected.length})</span>}
//       </span>
//       <span className="ml-2">
//         <ChevronIcon />
//       </span>

//       {visible && (
//         <div
//           className={`Filters__Panel ${
//             name.toLowerCase() === "vino" ? "Filters__Panel--vino" : ""
//           } absolute top-0 w-full bg-white px-4 select-none`}
//         >
//           <div className="flex items-center flex-1 justify-center py-8 font-LatoBold text-11px tracking-2 text-black">
//             <span className="font-LatoBold text-11px tracking-2 uppercase">{name}</span>
//             <span className="ml-2 transform rotate-180">
//               <ChevronIcon />
//             </span>
//           </div>

//           <div className="Filter__Options text-black font-BodoniBook text-20px leading-none pb-8">
//             {selected.length === 0 && (
//               <div className="flex items-center" onClick={() => handleClick("all")}>
//                 <div
//                   className={`checkbox flex-shrink-0 mr-4 ${
//                     selected.length === 0 ? "selected bg-brown" : "border border-gray"
//                   }`}
//                 ></div>
//                 <div className="py-2">
//                   <FormattedMessage id="pos.info.all" />
//                 </div>
//               </div>
//             )}

//             {selected.length > 0 && (
//               <div
//                 className="py-2 px-8 border border-gray text-gray text-center uppercase text-11px font-LatoBold tracking-2 mb-4 hover:bg-gray hover:text-white"
//                 onClick={() => handleClick("all")}
//               >
//                 Reset
//               </div>
//             )}

//             {memoizedSortedOptions.map((option, k) => {
//               if (option) {
//                 // Se c'è una virgola si parla del nome di un vino (etichetta, denominazione) e dobbiamo splittarlo per dare 2 stili diversi

//                 const array = option.split(",")
//                 let label = array[0]

//                 // Se c'è un underscore stiamo parlando di numero bottiglie: 1_3, 4_6 e dobbiamo rimpiazzarlo con "da 1 a 3"...
//                 if (option.includes("_")) {
//                   const numOggettiArray = option.split("_")
//                   label = `Da ${numOggettiArray[0]} a ${numOggettiArray[1]}`
//                 }

//                 return (
//                   <div key={k} className="flex items-center" onClick={() => handleClick(option)}>
//                     <div
//                       className={`checkbox flex-shrink-0 mr-4 hover:bg-gray ${
//                         selected.includes(option) ? "selected bg-brown" : "border border-gray"
//                       }`}
//                     ></div>
//                     <div
//                       className={`py-2 hover:text-gray ${
//                         name === "Tipologia" || (name === "Tipologia vino" && !label.includes(" ")) ? "capitalize" : ""
//                       }`}
//                     >
//                       {label}
//                       {array.length > 1 && <div className="mt-1 italic text-gray text-sm">{array[1]}</div>}
//                     </div>
//                   </div>
//                 )
//               }
//             })}
//           </div>
//         </div>
//       )}
//     </div>
//   )
// }

function FilterModal({ posInfo, dispatch, isOpen, onClose, switchToAnotherBrand }) {
  const { posBrands, posTypes, posMaterials, posFilters, posPrices } = posInfo

  const modal = (
    <Rodal
      measure="%"
      width={100}
      height={100}
      visible={isOpen}
      onClose={() => onClose("x")}
      animation="fade"
      duration={300}
      className="FiltersModal"
    >
      <div className="py-16">
        <div className="xl:flex items-center justify-center ">
          <FilterItem
            isMobile
            name="Brand"
            options={posBrands}
            selected={posFilters.brand}
            onChange={e => {
              if (switchToAnotherBrand) {
                switchToAnotherBrand(e)
              } else {
                dispatch({ type: "POS_FILTER_BRAND", payload: e })
              }
            }}
          />

          <FilterItem
            isMobile
            name="Types"
            options={posTypes}
            selected={posFilters.type}
            onChange={e => dispatch({ type: "POS_FILTER_TYPE", payload: e })}
          />
          <FilterItem
            isMobile
            name="Material"
            options={posMaterials}
            selected={posFilters.material}
            onChange={e => dispatch({ type: "POS_FILTER_MATERIAL", payload: e })}
          />
          <FilterItem
            isMobile
            name="Price"
            options={posPrices}
            selected={posFilters.price}
            onChange={e => dispatch({ type: "POS_FILTER_PRICE", payload: e })}
          />
        </div>
      </div>
    </Rodal>
  )

  return ReactDOM.createPortal(modal, document.body)
}
