import { isNull } from "lodash"

const initialState = {
  allBrands: [],
  allWines: [],
  viniAntinori: [],
  viniDistribuiti: [],
  filters: {
    distribuzione: "Antinori", // Antinori || Distribuiti
    tenuta: [],
    formato: [],
    vino: [],
    bollicine: false,
  },
  filteredWines: [],
  filteredBrands: [],
  areeGeografiche: [],
  tipologieVino: [],
}

export default function winesReducer(state = initialState, action) {
  switch (action.type) {
    case "LOADED_BRANDS":
      const allBrands = [...action.payload]
      const areeGeografiche = []

      allBrands.forEach(brand => {
        // Estraggo tutte le aree vinicole esistenti
        if (
          !!brand.wine_growing_area &&
          !areeGeografiche.find(a => !isNull(a) && a.id === brand.wine_growing_area.id)
        ) {
          areeGeografiche.push(brand.wine_growing_area)
        }
      })

      return {
        ...state,
        areeGeografiche,
      }

    case "LOADED_WINES":
      const brandsObject = {}
      const allWines = [...action.payload]
      const tipologieVino = []

      allWines.forEach(wine => {
        if (!brandsObject[wine.brand.Nome]) {
          brandsObject[wine.brand.Nome] = { ...wine.brand, wines: [] }
        }
        brandsObject[wine.brand.Nome].wines.push(wine)

        // Estraggo tutte le tipologie vino esistenti
        if (!!wine.wine_type && !tipologieVino.find(t => !isNull(t) && t.id === wine.wine_type.id)) {
          tipologieVino.push(wine.wine_type)
        }
      })

      const brandsArray = Object.keys(brandsObject)
        .map(key => brandsObject[key])
        .filter(brand => brand.wines.length > 0)
        .sort((a, b) => (a.ordine && b.ordine ? a.ordine - b.ordine : 1))
        .map(b => ({ ...b, Distribuzione: b.wines[0].Distribuzione }))

      const viniAntinori = getFilteredWines(allWines, { ...state.filters, distribuzione: "Antinori" })
      const viniDistribuiti = getFilteredWines(allWines, { ...state.filters, distribuzione: "Distribuiti" })

      return {
        ...state,
        allBrands: brandsArray,
        filteredBrands: getFilteredBrands(brandsArray, { ...state.filters }),
        allWines,
        filteredWines: getFilteredWines(allWines, state.filters),
        viniAntinori,
        viniDistribuiti,
        tipologieVino,
      }

    case "CHANGE_FILTER_DISTRIBUZIONE":
      const newFiltersDist = { ...state.filters, distribuzione: action.payload, tenuta: [] }

      return {
        ...state,
        filters: newFiltersDist,
        filteredWines: getFilteredWines(state.allWines, newFiltersDist),
        filteredBrands: getFilteredBrands(state.allBrands, { ...newFiltersDist }),
      }

    case "CHANGE_FILTER_TENUTA": {
      const newFilters = getNextFilters(state.filters, action.payload, "tenuta")

      return {
        ...state,
        filters: newFilters,
        filteredWines: getFilteredWines(state.allWines, newFilters),
        filteredBrands: getFilteredBrands(state.allBrands, newFilters),
      }
    }
    case "CHANGE_FILTER_VINO": {
      const newFilters = getNextFilters(state.filters, action.payload, "vino")
      return {
        ...state,
        filters: newFilters,
        filteredWines: getFilteredWines(state.allWines, newFilters),
      }
    }
    case "CHANGE_FILTER_BOLLICINE": {
      const newFilters = { ...state.filters, bollicine: action.payload }
      return {
        ...state,
        filters: newFilters,
        filteredWines: getFilteredWines(state.allWines, newFilters),
      }
    }

    case "CHANGE_FILTER_FORMATO": {
      const newFilters = getNextFilters(state.filters, action.payload, "formato")

      return {
        ...state,
        filters: newFilters,
        filteredWines: getFilteredWines(state.allWines, newFilters),
      }
    }

    case "FILTERS_RESET": {
      const newFilters = {
        ...state.filters,
        tenuta: [],
        formato: [],
        vino: [],
        bollicine: null,
      }
      return {
        ...state,
        filters: newFilters,
        filteredWines: getFilteredWines(state.allWines, newFilters),
        filteredBrands: getFilteredBrands(state.allBrands, newFilters),
      }
    }

    default:
      return state
  }
}

function definePriority(wine, filters) {
  //wine_type.id = 2 = bollicine
  //wine_type.id = 3 = bianco
  //wine_type.id = 4 = rosato
  //wine_type.id = 5 = rosso
  let priority = wine.wine_type.id === 3 ? 5 : wine.wine_type.id === 4 ? 4 : wine.wine_type.id === 5 ? 3 : 2
  let multiplier = wine.bollicine === true ? 2 : 1 //se c'è il filtro bollicine selezionato le bollicine vanno in cima quindi moltiplicatore più alto
  multiplier = filters.bollicine ? multiplier : filters.vino.length > 0 ? (wine.bollicine === true ? 1 : 2) : multiplier //se però c'è anche un colore di vino selezionato le bollicine vanno in fondo al colore di vino

  //a questo punto il moltiplicatore risulta:
  // 2 con filtro bollicine attivo e vino frizzante, 1 senza filtro bollicine attivo e vino fermo
  // 1 con filtro vino colore attivo e vino frizzante, 2 con filtro colore attivo e vino fermo
  // nel caso in cui seleziono filtro bollicine + filtro colore la logica diventa ad es : bianco fermo, bianco frizzante, rosato fermo rosato frizzante
  // per questo il moltiplicatore viene aggiunto alla priorità anzichè moltiplicato. In modo da non frammentare i colori dei vini tipo : bianco fermo, rosato fermo, bianco frizzante, rosato frizzante
  return filters.vino.length > 0 && !filters.bollicine ? priority + multiplier / 2 : priority * multiplier
}

function getFilteredWines(wines, newFilters) {
  const winesToReturn = wines
    .filter(wine => {
      const formati = wine.formati.map(f => f.formato)
      const isFilterDistribuzioneOk = wine.Distribuzione === newFilters.distribuzione
      const isFilterBollicineOk = newFilters.bollicine && newFilters.vino.length === 0 ? wine.bollicine : true
      //newFilters.vino.length > 0 ? true : newFilters.bollicine ? newFilters.bollicine === wine.bollicine : true
      const isFilterTenutaOk =
        newFilters.tenuta.length === 0 || newFilters.tenuta.find(f => wine.brand.wine_growing_area === f)
      const isFilterVinoOk =
        newFilters.bollicine && wine.bollicine
          ? true
          : newFilters.vino.length === 0 || newFilters.vino.find(f => wine.wine_type.id === f.id)

      let isFormatoFound = false

      newFilters.formato.forEach(formatoFilter => {
        if (formati.find(f => formatoFilter === f)) {
          isFormatoFound = true
        }
      })

      const isFilterFormatoOk = newFilters.formato.length === 0 || isFormatoFound

      return isFilterDistribuzioneOk && isFilterTenutaOk && isFilterFormatoOk && isFilterVinoOk && isFilterBollicineOk
    })
    .sort((a, b) => {
      const priorityA = definePriority(a, newFilters)
      const priorityB = definePriority(b, newFilters)
      return priorityA === priorityB ? 0 : priorityA > priorityB ? -1 : 1
    })
  return winesToReturn
}

function getFilteredBrands(brands, newFilters) {
  return brands.filter(brand => {
    const isFilterDistribuzioneOk = brand.Distribuzione === newFilters.distribuzione
    const isBrandAreaVinicolaOk =
      newFilters.tenuta.length === 0 || newFilters.tenuta.find(t => brand.wine_growing_area === t)

    return isFilterDistribuzioneOk && isBrandAreaVinicolaOk
  })
}

export function getNextFilters(currentFilters, payload, filter) {
  // Reset filtro

  if (!payload) {
    return { ...currentFilters, [filter]: [] }
  }

  const filterIndex = currentFilters[filter].findIndex(value => value === payload)

  if (filterIndex >= 0) {
    const newFilters = [...currentFilters[filter]]
    newFilters.splice(filterIndex, 1)

    return { ...currentFilters, [filter]: newFilters }
  }
  return { ...currentFilters, [filter]: [...currentFilters[filter], payload] }
}
