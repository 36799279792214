import React, { useEffect, useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { tenuteActionsTypes } from "../../redux/tenute-reducer"
import { getImageUrl } from "../../utils/getImageUrl"
import FormatoBottiglia from "../formato-bottiglia"
import Wine from "../wine"
import getLinkCorretto from "../../utils/getLinkCorretto"
import { useWindowWidth } from "@react-hook/window-size"
import { WishlistPanel } from "../wishlistPanel"

export default function VinoGriglia() {
  const params = useParams()
  const idVino = params.idVino
  const filteredWines = useSelector(state => state.wines.filteredWines)
  const vino = filteredWines.find(wine => wine.id === parseInt(idVino, 10))
  const history = useHistory()
  const dispatch = useDispatch()
  const locale = useSelector(state => state.tenute.locale)
  const testi = { it: "testo_italiano", en: "testo_inglese", de: "testo_tedesco", ch: "testo_cinese" }
  const allBrands = useSelector(state => state.wines.allBrands)
  const thisWinesBrand = allBrands.find(b => vino && b.Nome === vino.brand.Nome)
  const otherBrandWines = thisWinesBrand ? thisWinesBrand.wines.filter(w => w.id !== vino.id) : []
  const pageContainer = useRef(null)
  const windowWidth = useWindowWidth()
  const [showPopupWishlist, setShowPopupWishlist] = useState({
    showPopup: false,
    wine: null,
    popupStyle: {},
    popupTarget: null,
  })

  // Scroll back to top on wine change
  useEffect(() => {
    if (pageContainer.current) {
      pageContainer.current.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [vino])

  if (!vino) {
    history.push("/prodotti")
    return null
  }

  function calcPopupPosition(target) {
    let rect = target.getBoundingClientRect()
    let gridRect = document.getElementById("griglia")
    let scrollLeft = gridRect.scrollLeft
    let scrollTop = gridRect.scrollTop
    let definedWidth = windowWidth < 1024 ? (windowWidth < 768 ? 0.41 : 0.26) : 0.1766
    let preTest = rect.left + scrollLeft - windowWidth * definedWidth
    return {
      top: rect.top + scrollTop - gridRect.getBoundingClientRect().top + 32,
      left: preTest < 0 ? preTest - scrollLeft + 42 : preTest - scrollLeft + 28,
    }
  }

  function togglePopupWishlis(e, wine, collapse = false) {
    const popupPosition = calcPopupPosition(e.target)
    setShowPopupWishlist({
      showPopup: showPopupWishlist.wine
        ? showPopupWishlist.wine.id === wine.id
          ? !showPopupWishlist.showPopup
          : true
        : true,
      wine: showPopupWishlist.wine ? (showPopupWishlist.wine.id === wine.id ? null : wine) : wine,
      popupStyle: {
        position: "absolute",
        top: popupPosition.top,
        left: popupPosition.left,
      },
      popupTarget: e.target,
    })
  }

  // const isInWishlist = wishlist.find(w => w.id === vino.id)

  // function addToWishlist(wine) {
  //   dispatch({ type: "WINES_WISHLIST_ADD", payload: wine })
  // }

  return (
    <div className="absolute inset-0 bg-tenute z-10 overflow-y-auto" ref={pageContainer}>
      <motion.div
        className="pb-16"
        initial={{ left: 0, opacity: 1 }}
        animate={{ left: 0, transition: { type: "tween", duration: 0.75 }, opacity: 1 }}
        exit={{ opacity: 0 }}
        key={`${vino.id}-vino`}
      >
        <div id="griglia" className="TenutaGriglia w-full text-black px-12">
          {/* Foto */}
          <div style={{ gridArea: "foto" }}>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { type: "tween", duration: 1 } }}
              className={`object-cover w-full h-full`}
              src={getImageUrl(vino.Foto.url, 1800)}
              alt="Copertina"
            />
          </div>

          {/* Sidebar */}
          <div style={{ gridArea: "sidebar" }}>
            <motion.div className="bg-white px-12" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {/* Nome */}
              <motion.h2
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5 } }}
                className="pt-10 font-BodoniBook text-5xl"
              >
                {vino.Nome}
              </motion.h2>

              {/* Denominazione */}
              <motion.div
                className="Tenuta__Denominazione font-LatoBold mt-2 text-gray uppercase text-sm tracking-2"
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0, transition: { delay: 0.5, type: "tween", duration: 0.6 } }}
                exit={{ opacity: 0 }}
              >
                {vino.Denominazione}
              </motion.div>

              {/* Formato bottiglie */}
              <motion.div
                className="Tenuta__Formati grid grid-cols-6 items-end gap-4 mb-16"
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0, transition: { type: "tween", duration: 0.6, delay: 0.2 } }}
                exit={{ opacity: 0 }}
              >
                <AnimatePresence>
                  {vino.formati
                    .sort((a, b) => a.formato - b.formato)
                    .map((f, i) => (
                      <FormatoBottiglia formato={f.formato} key={i} k={i} />
                    ))}
                </AnimatePresence>
              </motion.div>

              {/* Btn wishlist */}
              <motion.div
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0, transition: { type: "tween", duration: 0.6, delay: 0.5 } }}
                exit={{ opacity: 0 }}
              >
                <WishlistPanel
                  noWrap
                  showPopup
                  wine={vino}
                  showIcon={windowWidth < 768 || windowWidth > 1024 ? true : false}
                />
                {/* <WishlistPopup wrap={false} showPopup showIcon={(windowWidth < 768) || (windowWidth > 1024) ? true : false} wine={vino} /> */}
              </motion.div>

              {/* 
              <motion.div
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0, transition: { type: "tween", duration: 0.6, delay: 0.5 } }}
                exit={{ opacity: 0 }}
                className=""
              >
                {!isInWishlist && (
                  <button
                    className="font-LatoBold uppercase text-11px mt-16 tracking-2 px-4 py-2 flex flex-row items-center gap-2 cursor-pointer"
                    onClick={() => addToWishlist(vino)}
                  >
                    <LineaDiagonale />
                    <FormattedMessage id="tenute.aggiungi_wishlist" />
                  </button>
                )}

                {isInWishlist && (
                  <div className="font-LatoBold uppercase text-11px mt-24 tracking-2 px-4 py-2 flex flex-row items-center gap-2">
                    <WishlistIcon color="black" />
                    <div className="ml-4">
                      <FormattedMessage id="tenute.aggiunto_wishlist" />
                    </div>
                  </div>
                )}
              </motion.div> */}

              {/* Testo */}
              <div className="font-BodoniBook mt-12 text-xl leading-relaxed">
                <motion.div
                  className="Tenuta__Testo font-BodoniBook mt-12 text-xl leading-relaxed overflow-y-auto"
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0, transition: { delay: 0.75, type: "tween", duration: 0.6 } }}
                  exit={{ opacity: 0 }}
                >
                  {vino[testi[locale]]}
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0, transition: { delay: 1, type: "tween", duration: 0.6 } }}
                  className="mt-4 cursor-pointer"
                >
                  {vino.ExternalLink && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-BodoniBook text-sm text-brown"
                      href={vino.ExternalLink.Url}
                    >
                      {vino.ExternalLink.Label}
                    </a>
                  )}
                </motion.div>
              </div>

              {/* Footer */}
              <motion.div
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0, transition: { delay: 1.25, type: "tween", duration: 0.6 } }}
                exit={{ opacity: 0 }}
                className="py-8"
              >
                <div className="border border-gray border-t mb-6"></div>
                <div className="flex items-center justify-between">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-BodoniBook text-xl text-brown"
                    href={getLinkCorretto(vino.brand.link_sito)}
                  >
                    <FormattedMessage id="tenute.prodotti.visita_il_sito" />
                  </a>
                </div>
              </motion.div>
            </motion.div>
          </div>

          {/* Vini */}
          {otherBrandWines.length > 0 && (
            <div style={{ gridArea: "vini" }}>
              <div className="w-full flex items-center">
                <h3 className="hidden lg:block w-3/5 flex-grow font-BodoniBook text-2xl">
                  <FormattedMessage id="tenute.vini_della_tenuta" />
                </h3>
                <div className={"w-full lg:w-3/5 xl:w-2/5"}>
                  <WishlistPanel hideQuantity showPopup noWrap wine={thisWinesBrand.wines} />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-12">
                {otherBrandWines.map(wine => (
                  <Wine
                    key={wine.id}
                    wine={wine}
                    showAddToWishList
                    togglePopupWishlis={togglePopupWishlis}
                    focused={showPopupWishlist.wine ? showPopupWishlist.wine.id : null}
                    tipoVista="griglia"
                    onClick={() => dispatch({ type: tenuteActionsTypes.PRODOTTI_VISTA_CHANGE, payload: "vini" })}
                  />
                ))}
              </div>
            </div>
          )}
          {/* {showPopupWishlist.showPopup && showPopupWishlist.wine != null && (
            <WishlistPopup
              showPopup={showPopupWishlist.showPopup}
              wine={showPopupWishlist.wine}
              popupStyle={showPopupWishlist.popupStyle}
              resetPopup={e => {
                setShowPopupWishlist({ ...showPopupWishlist, wine: null, showPopup: false, popupStyle: {} })
              }}
            />
          )} */}
          {showPopupWishlist.wine && (
            <WishlistPanel
              colorName="tenute"
              e={showPopupWishlist.popupTarget}
              wine={showPopupWishlist.wine}
              reference={document.getElementById("griglia")}
              referenceSize="griglia-small"
              resetPopup={e => setShowPopupWishlist({ wine: null, showPopupWishlist: false, popupTarget: null })}
            />
          )}
        </div>
      </motion.div>
    </div>
  )
}
