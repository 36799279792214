/**
 * I link alle tenute provengono dal gestionale, e a volte sono stati inseriti
 * senza "https://" davanti.
 * Per non rompere tutto, usiamo questa funzione così anche i link imperfetti
 * funzionano senza complicare la vita a chi inserisce i dati nel CMS, che non
 * ha idea di cosa sia http e di come costruire un URL corretto.
 *
 * @param {string} url
 * @returns string
 */
export default function getLinkCorretto(url) {
  // Se non c'è proprio il link facciamo fallback sul sito generico di Antinori
  if (!url) {
    return "https://www.antinori.it"
  }

  // Se la parola "http" è già presente siamo a posto
  if (url.includes("http")) {
    return url
  }

  // Se non è presente la parola "http" l'aggiungiamo noi
  return `https://${url}`
}
