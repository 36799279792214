let initialState = {
  giftboxWishlist: [],
  positemWishlist: [],
}

const localStorageGiftboxWishlist = localStorage.getItem("giftBoxWishlist")
const localStoragePosItemWishlsit = localStorage.getItem("posItemWishlist")

if (typeof localStorageGiftboxWishlist !== "undefined") {
  try {
    initialState.giftboxWishlist = JSON.parse(localStorageGiftboxWishlist)
    initialState.giftboxWishlist = initialState.giftboxWishlist ? initialState.giftboxWishlist : []
  } catch (error) {
    initialState.giftboxWishlist = []
  }
} else {
  initialState.giftboxWishlist = []
}

if (typeof localStoragePosItemWishlsit !== "undefined") {
  try {
    initialState.positemWishlist = JSON.parse(localStoragePosItemWishlsit)
    initialState.positemWishlist = initialState.positemWishlist ? initialState.positemWishlist : []
  } catch (error) {
    initialState.positemWishlist = []
  }
} else {
  initialState.positemWishlist = []
}

export default function wishlistReducer(state = initialState, action) {
  let currentCategory = action.category ? action.category : "giftboxWishlist"
  let newState = state

  switch (action.type) {
    case "WISHLIST_ADD":
      newState = { ...state }
      newState[currentCategory] = [...newState[currentCategory], action.payload]
      break
    case "WISHLIST_REMOVE":
      // debugger
      newState = { ...state }
      newState[currentCategory] = newState[currentCategory].filter(product => product.id !== action.payload.id)
      break
    case "WISHLIST_RESET":
      newState = { giftboxWishlist: [], positemWishlist: [] }
      break
    case "WISHLIST_UPDATE_ALBUM":
      newState[currentCategory] = newState[currentCategory].map(product => {
        if (product.id === action.payload.id) {
          product.quantity = action.payload.quantity
        }

        return product
      })
      break
    default:
      newState = state
      break
  }

  if (typeof window !== "undefined") {
    localStorage.setItem("giftBoxWishlist", JSON.stringify(newState.giftboxWishlist))
    localStorage.setItem("posItemWishlist", JSON.stringify(newState.positemWishlist))
  }

  return newState
}
