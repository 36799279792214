import React from "react"
import { useSelector, useDispatch } from "react-redux"
import FilterItem from "../filter-item"
import "./style.css"

function Filters({ isMobile = false, force = false, onChange = () => {} }) {
  const productsState = useSelector(state => state.products)
  const allBrand = []
  const allTipologiaConfezione = []
  const allEdizione = []
  const allFormatoBottiglia = []
  const allOggettistica = []
  const allVino = []
  const allTipologiaVino = []

  productsState.allProducts.forEach(node => {
    // Brand
    node.brands.forEach(brand => {
      if (brand.Nome && !allBrand.includes(brand.Nome)) {
        allBrand.push(brand.Nome)
      }
    })

    // Tipologia Confezione
    if (node.tipo && !allTipologiaConfezione.includes(node.tipo)) {
      //esclusi cartotecnica e legno da filtro tipologia su richiesta del cliente
      //https://www.notion.so/mirror-agency/GIFTBOX-Rinominare-il-filtro-tipologia-in-Oggettistica-e-togliere-le-voci-Cartotecnica-e-Legno-77b48f76605a47979991c3ae043db48c?pvs=4
      if (!["cartotecnica", "legno"].includes(node.tipo.toLowerCase())) allTipologiaConfezione.push(node.tipo)
    }

    // Edizione
    if (node.edizione && !allEdizione.includes(node.edizione)) {
      allEdizione.push(node.edizione)
    }

    // Formato bottiglia
    node.bottiglie.forEach(bottiglia => {
      if (bottiglia.bottle_format && !allFormatoBottiglia.includes(bottiglia.bottle_format.formato)) {
        allFormatoBottiglia.push(bottiglia.bottle_format.formato)
      }

      if (bottiglia.label && !allVino.includes(bottiglia.label)) {
        allVino.push(bottiglia.label)
      }

      if (bottiglia.tipologia_vino && !allTipologiaVino.includes(bottiglia.tipologia_vino)) {
        allTipologiaVino.push(bottiglia.tipologia_vino)
      }
    })
  })

  productsState.allOggettistica.forEach(node => {
    allOggettistica.push(node)
  })

  const {
    brand,
    tipologiaConfezione,
    edizione,
    formatoBottiglia,
    vino,
    totaleBottiglie,
    prezzo,
    scalaSconti,
    tipologiaVino,
  } = useSelector(state => state.filters)
  const dispatch = useDispatch()

  return (
    <div
      className={`
        flex items-center justify-around px-12
        ${isMobile ? "text-black" : "text-white"}
        ${!isMobile ? "px-12" : ""}
        ${!isMobile ? "hidden  xl:flex px-12" : ""}
      `}
    >
      {/* Brand */}

      <FilterItem
        isMobile={isMobile}
        sortOptions
        name="brand"
        options={allBrand}
        selected={brand}
        onChange={selectedBrands => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { brand: selectedBrands } })
          } else {
            dispatch({ type: `FILTER_BRAND`, payload: selectedBrands })
          }
          onChange()
        }}
        force={force}
      />

      {/* Tipologia vino */}

      <FilterItem
        isMobile={isMobile}
        sortOptions
        name="Tipologia vino"
        options={allTipologiaVino}
        selected={tipologiaVino}
        onChange={selectedTipologiaVino => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { tipologiaVino: selectedTipologiaVino } })
          } else {
            dispatch({ type: `FILTER_TIPOLOGIA_VINO`, payload: selectedTipologiaVino })
          }
          onChange()
        }}
        force={force}
      />

      {/* Confezionamento */}

      <FilterItem
        isMobile={isMobile}
        sortOptions
        name="Confezionamento"
        options={["1", "2", "3", "4", "5", "6"]}
        selected={totaleBottiglie}
        onChange={selectedTotaleBottiglie => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { totaleBottiglie: selectedTotaleBottiglie } })
          } else {
            dispatch({ type: `FILTER_TOTALE_BOTTIGLIE`, payload: selectedTotaleBottiglie })
          }
          onChange()
        }}
        force={force}
      />

      {/* Formato */}

      <FilterItem
        isMobile={isMobile}
        sortOptions
        name="Formato"
        options={allFormatoBottiglia}
        selected={formatoBottiglia}
        onChange={selectedFormatoBottiglia => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { formatoBottiglia: selectedFormatoBottiglia } })
          } else {
            dispatch({ type: `FILTER_FORMATO_BOTTIGLIA`, payload: selectedFormatoBottiglia })
          }
          onChange()
        }}
        force={force}
      />

      {/* Oggettistica */}

      <FilterItem
        isMobile={isMobile}
        sortOptions
        name="Oggettistica"
        options={[...allTipologiaConfezione, ...allOggettistica.map(o => o.descrizione)]}
        selected={tipologiaConfezione}
        onChange={selectedTipologiaConfezione => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { tipologiaConfezione: selectedTipologiaConfezione } })
          } else {
            dispatch({ type: `FILTER_TIPOLOGIA_CONFEZIONE`, payload: selectedTipologiaConfezione })
          }
          onChange()
        }}
        force={force}
      />

      {/* Edizione */}

      <FilterItem
        isMobile={isMobile}
        sortOptions
        name="Edizione"
        options={allEdizione}
        selected={edizione}
        onChange={selectedEdizione => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { edizione: selectedEdizione } })
          } else {
            dispatch({ type: `FILTER_EDIZIONE`, payload: selectedEdizione })
          }
          onChange()
        }}
        force={force}
      />

      {/* Prezzo */}

      <FilterItem
        isMobile={isMobile}
        name="Prezzo"
        options={["0-30", "30-50", "50-80", "80-100", "100-150", "150-200", "200-250", "250-300", "300-oltre"]}
        selected={prezzo}
        onChange={selectedPrezzo => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { prezzo: selectedPrezzo } })
          } else {
            dispatch({ type: `FILTER_PREZZO`, payload: selectedPrezzo })
          }
          onChange()
        }}
        force={force}
      />

      {/* Scala sconti */}

      <FilterItem
        isMobile={isMobile}
        sortOptions
        name="Scala sconti"
        options={["Sì", "No"]}
        selected={scalaSconti}
        onChange={selectedScalaSconti => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { scalaSconti: selectedScalaSconti } })
          } else {
            dispatch({ type: `FILTER_SCALA_SCONTI`, payload: selectedScalaSconti })
          }
          onChange()
        }}
        force={force}
      />

      {/* Vino */}

      {/* <FilterItem
        isMobile={isMobile}
        sortOptions
        name="vino"
        options={allVino}
        selected={vino}
        onChange={selectedVino => {
          if (force) {
            dispatch({ type: "FILTER_FORCE", payload: { vino: selectedVino } })
          } else {
            dispatch({ type: `FILTER_VINO`, payload: selectedVino })
          }
          onChange()
        }}
        force={force}
      /> */}
    </div>
  )
}

// function FilterItem({ name, options, onChange, selected, force, isMobile = false }) {
//   const [visible, setVisible] = useState(false)
//   const memoizedSortedOptions = useMemo(() => {
//     return options.sort()
//   }, [options])

//   function handleClick(option) {
//     // Se ho cliccato "Vedi tutti" resetto i filtri
//     if (option === "all") {
//       return onChange([])
//     }

//     // Se force è a true (solo in home page) ritorno solo l'opzione cliccata
//     if (force) {
//       return onChange([option])
//     }

//     // Se l'opzione cliccata è già selezionata, la rimuovo, altrimenti la aggiungo
//     const newValue = selected && selected.includes(option) ? selected.filter(o => o !== option) : [...selected, option]

//     onChange(newValue)
//   }

//   function handleClickTitle() {
//     if (isMobile) {
//       setVisible(!visible)
//     }
//   }

//   return (
//     <div
//       className="flex items-center flex-1 justify-center py-8 relative cursor-pointer"
//       onMouseEnter={() => setVisible(true)}
//       onMouseLeave={() => setVisible(false)}
//       onClick={handleClickTitle}
//     >
//       <span className="font-LatoBold text-11px tracking-2 uppercase">
//         {name}
//         {selected.length > 0 && <span> ({selected.length})</span>}
//       </span>
//       <span className="ml-2">
//         <ChevronIcon />
//       </span>

//       {visible && (
//         <div
//           className={`Filters__Panel ${
//             name.toLowerCase() === "vino" ? "Filters__Panel--vino" : ""
//           } absolute top-0 w-full bg-white px-4 select-none`}
//         >
//           <div className="flex items-center flex-1 justify-center py-8 font-LatoBold text-11px tracking-2 text-black">
//             <span className="font-LatoBold text-11px tracking-2 uppercase">{name}</span>
//             <span className="ml-2 transform rotate-180">
//               <ChevronIcon />
//             </span>
//           </div>

//           <div className="Filter__Options text-black font-BodoniBook text-20px leading-none pb-8">
//             {selected.length === 0 && (
//               <div className="flex items-center" onClick={() => handleClick("all")}>
//                 <div
//                   className={`checkbox flex-shrink-0 mr-4 ${
//                     selected.length === 0 ? "selected bg-brown" : "border border-gray"
//                   }`}
//                 ></div>
//                 <div className="py-2">Vedi tutti</div>
//               </div>
//             )}

//             {selected.length > 0 && (
//               <div
//                 className="py-2 px-8 border border-gray text-gray text-center uppercase text-11px font-LatoBold tracking-2 mb-4 hover:bg-gray hover:text-white"
//                 onClick={() => handleClick("all")}
//               >
//                 Reset
//               </div>
//             )}

//             {memoizedSortedOptions.map((option, k) => {
//               if (option) {
//                 // Se c'è una virgola si parla del nome di un vino (etichetta, denominazione) e dobbiamo splittarlo per dare 2 stili diversi
//                 const array = option.split(",")
//                 let label = array[0]

//                 // Se c'è un underscore stiamo parlando di numero bottiglie: 1_3, 4_6 e dobbiamo rimpiazzarlo con "da 1 a 3"...
//                 if (option.includes("_")) {
//                   const numOggettiArray = option.split("_")
//                   label = `Da ${numOggettiArray[0]} a ${numOggettiArray[1]}`
//                 }

//                 return (
//                   <div key={k} className="flex items-center" onClick={() => handleClick(option)}>
//                     <div
//                       className={`checkbox flex-shrink-0 mr-4 hover:bg-gray ${
//                         selected.includes(option) ? "selected bg-brown" : "border border-gray"
//                       }`}
//                     ></div>
//                     <div
//                       className={`py-2 hover:text-gray ${
//                         name === "Tipologia" || (name === "Tipologia vino" && !label.includes(" ")) ? "capitalize" : ""
//                       }`}
//                     >
//                       {label}
//                       {array.length > 1 && <div className="mt-1 italic text-gray text-sm">{array[1]}</div>}
//                     </div>
//                   </div>
//                 )
//               }
//             })}
//           </div>
//         </div>
//       )}
//     </div>
//   )
// }

export default Filters
