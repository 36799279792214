import React from "react"
import Toggle from "../toggle"
import { motion } from "framer-motion"

import "./index.css"
export const Grid = ({ entity, toggleDraftPublished, type }) => {
  const editingLink = "admin/plugins/content-manager/collectionType/application"
  const editingSuffix = type === "GIFTBOXES" ? "gift-box.gift-box" : "pos-item.pos-item"

  const getColSpan = colName => {
    switch (colName) {
      case "bottiglie":
        return 1
      default:
        return 1
    }
  }

  return (
    <>
      <div className="overflow-x-scroll">
        <table className="min-w-full w-max divide-y divide-slate-200 table-fixed ">
          <thead className="w-full bg-black text-white font-LatoBold ">
            <motion.tr initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {entity &&
                entity.length > 0 &&
                Object.keys(entity[0]).map((columnHeader, index) => (
                  <th
                    key={`column-header-${index}`}
                    scope="col"
                    colSpan={getColSpan(columnHeader)}
                    className={`py-3 px-4 text-sm tracking-wider text-left  uppercase `}
                  >
                    {columnHeader}
                  </th>
                ))}

              <th key={`edit`} scope="col" className="py-3 px-4 text-sm tracking-wider text-left  uppercase ">
                edit
              </th>
            </motion.tr>
          </thead>
          <tbody className="bg-white  ">
            {entity &&
              entity &&
              entity.length > 0 &&
              entity.map((giftBoxItem, index) => (
                <motion.tr
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2, delay: index * 0.02 }}
                  key={`giftBox-item-${giftBoxItem.id}`}
                  className="hover grid-row"
                >
                  {Object.keys(giftBoxItem).map((columnValue, index) => {
                    switch (columnValue) {
                      case "published":
                        return (
                          <td
                            key={`giftbox-${giftBoxItem.codice}-column-published`}
                            className="py-4 px-4 text-sm text-slate-900 whitespace-nowrap "
                          >
                            <Toggle
                              initialState={giftBoxItem.published}
                              toggleFunction={() => toggleDraftPublished(giftBoxItem.id, !giftBoxItem.published)}
                            />
                          </td>
                        )
                        break
                      case "image":
                        return (
                          <td
                            key={`giftbox-${giftBoxItem.id}-column-published`}
                            className="py-4 px-4 text-sm text-slate-900 whitespace-nowrap "
                          >
                            <div className="h-24 w-24 overflow-hidden relative">
                              <img
                                src={giftBoxItem.image}
                                alt=""
                                className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                              />
                            </div>
                          </td>
                        )
                        break
                      case "bottiglie":
                        return (
                          <td
                            key={`giftbox-${giftBoxItem.id}-column-${columnValue}`}
                            className="py-4 px-4 text-sm text-slate-900 whitespace-nowrap "
                          >
                            {giftBoxItem.bottiglie.map(bottiglia => (
                              <div key={`giftbox-${giftBoxItem.id}-bottiglia-${bottiglia.id}`}>{bottiglia.label};</div>
                            ))}
                          </td>
                        )
                        break
                      case "brand":
                        return (
                          <td
                            key={`giftbox-${giftBoxItem.id}-column-${columnValue}`}
                            className="py-4 px-4 text-sm text-slate-900 whitespace-nowrap "
                          >
                            {typeof giftBoxItem.brand === "string"
                              ? giftBoxItem.brand
                              : giftBoxItem.brand?.map((brand, index) => (
                                  <div key={`giftbox-${giftBoxItem.id}-brand-${brand.id}`}>{brand.Nome}</div>
                                ))}
                          </td>
                        )
                        break
                      default:
                        return (
                          <td
                            key={`giftbox-${giftBoxItem.id}-column-${columnValue}`}
                            className={`py-4 px-4 text-sm text-slate-900 whitespace-nowrap  ${
                              columnValue.toLowerCase().includes("pr_") && "text-right"
                            }`}
                          >
                            {giftBoxItem[columnValue]}
                          </td>
                        )
                        break
                    }
                  })}

                  <td className="py-4 px-4 text-sm text-slate-900 whitespace-nowrap ">
                    <a
                      className=" bg-slate-900"
                      href={`${process.env.REACT_APP_API_BASE_URL}/${editingLink}::${editingSuffix}/${giftBoxItem.id}`}
                      target="_blank"
                    >
                      <div className="p-2 px-10 bg-lightBrown hover:bg-brown uppercase text-sm tracking-wider transition duration-300 cursor-pointer text-white text-center w-max  hover  ">
                        Edit{" "}
                      </div>
                    </a>
                  </td>
                </motion.tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default Grid
