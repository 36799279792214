import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { motion } from "framer-motion"
import { FormattedMessage, injectIntl, useIntl } from "react-intl"

import LineaDiagonale from "../../components/linea-diagonale"
import HeaderWishlistTenute from "./components/header-wishlist-tenute"
import { Album } from "../../components/album"
import Spinner from "../../components/spinner"
import { WishlistCreationModal } from "../../components/wishlist-creation-modal"
import Message from "../../components/modal-message"

import "./style.css"

export const WishlistTenutePage = () => {
  const intl = useIntl()
  let wishlist = useSelector(state => state.winesWishlist)
  const brands = useSelector(state => state.wines.allBrands)
  const dispatch = useDispatch()
  const [isCreatingWishlist, setIsCreatingWishlist] = useState(false)
  const [generatingPDF, setGeneratingPDF] = useState(false)
  const [email, setEmail] = useState("")
  const [isSelecting, setIsSelecting] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [clientName, setClientName] = useState("")
  const [messageInfo, setMessageInfo] = useState({
    title: "",
    message: "",
  })

  useEffect(() => {
    document.body.classList.add("bg-black")
    // if (wishlist.length > 0) {
    //   if (wishlist[0].hasOwnProperty("Foto")) {
    //     const newDefaultWishlist = {
    //       name: "Antinori default",
    //       id: 0,
    //       items: wishlist,
    //     }
    //     dispatch({ type: "WINES_WISHLIST_REPLACE_OLD", payload: newDefaultWishlist })
    //   }
    // }

    return function cleanup() {
      document.body.classList.remove("bg-black")
    }
  }, [dispatch])

  // function resetWishlist() {
  //   dispatch({ type: "WINES_WISHLIST_RESET" })
  // }

  const manageSelectedItems = album => {
    if (!Array.isArray(selectedItems)) {
      return
    }
    const itemFound = selectedItems.find(item => item.id === album.id)

    if (itemFound) {
      let newSelectedItems = selectedItems.filter(item => item.id !== album.id)
      setSelectedItems([...newSelectedItems])
    } else {
      setSelectedItems(prevValue => [...prevValue, album])
    }
  }

  const requestPDFBuild = async () => {
    const wishlistToSend = []

    if (selectedItems.length === 0) {
      setMessageInfo({
        title: "Attenzione",
        message: "Devi aver selezionato almeno un vino per poter inoltrare la mail",
      })
      return
    }

    setGeneratingPDF(true)

    for (const wishlist of selectedItems) {
      const selectedEstates = [] // Sposta questa dichiarazione all'interno del ciclo

      wishlist.items.forEach(wine => {
        let relatedEstate = selectedEstates.find(estate => estate.id === wine.brand.id)
        if (!relatedEstate) {
          // CONTROLLO NUMERO VINI DELLA TENUTA
          let amountOfWinesInWishlist = brands.find(
            brand => brand.id === wine.brand.id && brand.Distribuzione === wine.Distribuzione
          )

          // CREO OGGETTO ESTATE
          let newEstate = {
            id: wine.brand.id,
            wines: [{ id: wine.id, quantity: wine.quantity || 1 }],
            full: false,
            brandWinesCount: amountOfWinesInWishlist?.wines?.length || 0,
          }

          selectedEstates.push(newEstate)
        } else {
          if (!relatedEstate.wines.find(item => item.id === wine.id)) {
            relatedEstate.wines.push({ id: wine.id, quantity: wine.quantity || 1 }) // Corretto per aggiungere quantità
            relatedEstate.full = relatedEstate.wines.length === relatedEstate.brandWinesCount
          }
        }
      })

      wishlistToSend.push({
        name: wishlist.name,
        tenute: selectedEstates,
      })
    }

    let responses = []
    for (let item of wishlistToSend) {
      const postObj = {
        to: email,
        clientName: clientName,
        wishlist: item,
      }
      try {
        // const resetRes = await fetch(`https://antinori-cataloghi-api.com:3000/pdf?products=${productsIds}&to=${email}`, {
        const resetRes = await fetch(`${process.env.REACT_APP_API_BACKEND_URL}/wishlist/pdf`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postObj),
        })

        if (resetRes.ok) {
          responses.push(true)
        }
      } catch (e) {
        setGeneratingPDF(false)
        setMessageInfo({
          title: "Errore",
          message: "Errore nell'invio della selezione, riprovare o contattare l'assistenza",
        })
      }
    }

    if (responses.length === wishlistToSend.length) {
      setMessageInfo({
        title: "Fatto",
        message: "La selezione di wishlist è stata inviata correttamente all'indirizzo mail fornito",
      })
    }
    setGeneratingPDF(false)
    setIsSelecting(false)
    setSelectedItems([])
    setEmail("")
  }

  return (
    <div className="absolute inset-0 z-20">
      <HeaderWishlistTenute />
      <div className="Wishlist h-full bg-black text-white px-6  lg:px-32 py-8 overflow-y-auto">
        {/* Wishlist vuota */}

        {wishlist.length === 0 && (
          <div className="Wishlist__Empty h-full flex flex-col items-center justify-center">
            <div className="font-BodoniBook text-center text-4xl lg:text-6xl text-white leading-none">
              <FormattedMessage id="tenute.wishlist.wishlist_vuota" />
            </div>
            <Link
              to="/prodotti"
              className="font-LatoBold uppercase text-11px text-white mt-8 tracking-2 px-4 py-2 flex flex-row items-center gap-2"
            >
              <LineaDiagonale white />
              <FormattedMessage id="tenute.wishlist.vai_al_catalogo" />
            </Link>
          </div>
        )}

        {/* Wishlist piena */}

        {wishlist.length > 0 && (
          <>
            <h1 className="font-BodoniBook mt-32 text-40px lg:text-60px block leading-none text-center text-white">
              <FormattedMessage id="tenute.wishlist.la_tua_wishlist" />
            </h1>
            <form
              onSubmit={e => {
                e.preventDefault()
                requestPDFBuild()
              }}
              className="mt-24 flex items-center gap-6 pr-[1.25rem]"
            >
              <input
                type="text"
                required
                value={clientName}
                onChange={e => setClientName(e.target.value)}
                className="outline-none border-b-2 w-full placeholder-white placeholder-opacity-50 border-white  border-opacity-50 text-white bg-transparent py-2 font-LatoRegular lg:text-lg"
                placeholder={intl.formatMessage({ id: "tenute.wishlist.inserisci_nome" })}
              />
              <input
                type="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="Wishlist__InputEmail text-white placeholder-opacity-50 bg-transparent py-2 font-LatoRegular lg:text-lg tracking-widest"
                placeholder={intl.formatMessage({ id: "tenute.wishlist.inserisci_email" })}
              />

              <button className="Wishlist__SubmitBtn w-6">
                {generatingPDF && <Spinner />}
                {/* {!generatingPDF && (
                  <svg stroke="white" strokeWidth="2" viewBox="0 0 50 50">
                    <line x1="3" y1="25" x2="47" y2="25"></line>
                    <line x1="47" y1="25" x2="25" y2="3"></line>
                    <line x1="47" y1="25" x2="25" y2="47"></line>
                  </svg>
                )} */}
              </button>
              <button className="p-3 px-12 bg-brown text-white hover:bg-white hover:text-brown">
                <FormattedMessage id="wishlist.invia" />
              </button>
            </form>
            <div className=" mt-16 tracking-widest text-white bg-transparent py-2 font-LatoRegular lg:text-lg">
              {/* <span
                className="Animated__Border inline relative text-brown transition duration-300 font-bold pb-2  cursor-pointer"
                onClick={e => setIsSelecting(!isSelecting)}
              >
               
              </span>{" "} */}
              <FormattedMessage id="wishlist.seleziona_le_wishlist" />
              <FormattedMessage id="wishlist.per_ricevere_pdf" />
            </div>

            <div className="Wishlist__Prodotti mt-32 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-16 pr-5 lg:pr-12">
              {wishlist.map(item => {
                return (
                  <Album
                    key={item.id}
                    album={item}
                    selectedItems={selectedItems}
                    showRemoveIcon={!isSelecting}
                    changeSelection={e => manageSelectedItems(item)}
                    showCheckbox
                  />
                )
              })}
              <Album toggleCreation={e => setIsCreatingWishlist(true)} />
            </div>
          </>
        )}
      </div>
      <motion.img
        className="absolute block right-0 top-1/2 transform -translate-y-1/2 pr-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5, type: "tween", delay: 1 } }}
        src={`${process.env.PUBLIC_URL}/logo-ocm.svg`}
        alt="ocm logo"
      />
      {isCreatingWishlist && (
        <div className="z-20">
          <WishlistCreationModal wine={null} resetModal={e => setIsCreatingWishlist(false)} />
          {/* <WishlistPopup showPopup={false} showCreationModal resetPopup={e => setIsCreatingWishlist(false)} /> */}
        </div>
      )}
      <Message title={messageInfo.title} close={e => setMessageInfo({ title: "", message: "" })}>
        {messageInfo.message}
      </Message>
    </div>
  )
}
