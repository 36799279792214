import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { tenuteActionsTypes } from "../../../../redux/tenute-reducer"
import HeaderWishlistTenute from "../../../wishlist-tenute/components/header-wishlist-tenute"

export const TenutePasswordReset = () => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [showResetError, setShowResetError] = useState(false)
  const [showPasswordDontMatchError, setShowPasswordDontMatchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const changePassword = useCallback(
    async e => {
      e.preventDefault()
      setShowResetError(false)
      setShowPasswordDontMatchError(false)
      setIsFetching(true)
      if (password !== passwordConfirmation) {
        setShowPasswordDontMatchError(true)
      } else {
        try {
          const resetRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: query.get("code"),
              password: password,
              passwordConfirmation: passwordConfirmation,
            }),
          })
          if (resetRes.ok) {
            const responseData = await resetRes.json()
            dispatch({ type: tenuteActionsTypes.TENUTE_LOGIN, payload: responseData })
            history.push("/prodotti")
          } else {
            setShowResetError(true)
          }
        } catch (e) {
          // some network error
          setShowResetError(true)
        }
        setIsFetching(false)
      }
    },
    [dispatch, history, password, passwordConfirmation, query]
  )

  return (
    <div className="absolute inset-0 z-20">
      <HeaderWishlistTenute />
      <div className="ml-auto mr-auto flex flex-col items-center justify-center h-full w-full gap-8 lg:w-1/4">
        <h1>Reset password</h1>
        {showResetError && (
          <span className="text-xs" style={{ color: "#9b2c2c" }}>
            Errore durante il reset della password
          </span>
        )}
        {showPasswordDontMatchError && (
          <span className="text-xs" style={{ color: "#9b2c2c" }}>
            Le password non coincidono
          </span>
        )}
        <form onSubmit={changePassword} className="w-full">
          <div className="flex flex-col items-center w-full">
            <input
              required
              value={password}
              type="password"
              name="password"
              className="border border-gray text-black w-full p-1 text-sm"
              placeholder="Password"
              onChange={e => {
                setPassword(e.target.value)
                setShowPasswordDontMatchError(false)
              }}
            />
            <input
              required
              value={passwordConfirmation}
              type="password"
              name="password_confirm"
              className="border border-gray text-black w-full p-1 text-sm mt-4"
              placeholder="Conferma password*"
              onChange={e => {
                setPasswordConfirmation(e.target.value)
                setShowPasswordDontMatchError(false)
              }}
            />
            <button
              type="submit"
              className={`w-full bg-black p-2 uppercase text-xs text-white mt-4 ${isFetching ? "opacity-50" : ""}`}
            >
              Conferma
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
