export default function sortTipologie(a, b) {
  const positions = {
    bollicine: 1,
    bianco: 2,
    rosato: 3,
    rosso: 4,
    "dessert e distillati": 6,
    specialità: 5,
  }

  return positions[a.italiano.toLowerCase()] - positions[b.italiano.toLowerCase()]
}
