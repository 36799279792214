import _ from "lodash"

const checkIfIsNumber = string => {
  const numberRegex = /^\d+$/
  return numberRegex.test(string)
}
const buildPublicationFilter = state => {
  switch (state) {
    case "ALL":
      return ""
    case "DRAFT":
      return `&published_at_null=true`
    case "LIVE":
      return `&published_at_null=false`
    default:
      return ""
  }
}

const buildCountUrl = (type, pagination) => {
  switch (type) {
    case "GIFTBOX":
      return `/count${buildGiftBoxUrlParamSection(pagination)}`
    case "POS":
      return `/count${buildPosUrlParamSection(pagination)}`
    default:
      return ""
  }
}

const buildPosUrlParamSection = pagination => {
  const start = pagination.currentPage ? (pagination.currentPage - 1) * pagination.itemsPerPage : 0
  const offset = `_start=${start}&_limit=${pagination.itemsPerPage}`
  const brandFilterString =
    pagination.filters.brand && pagination.filters.brand !== ""
      ? `&brand.Nome_contains=${pagination.filters.brand}`
      : ``
  const codeFilterString =
    pagination.filters.code && pagination.filters.code !== ""
      ? `&codice_articolo_contains=${pagination.filters.code}`
      : ``
  const stateFilterString = buildPublicationFilter(pagination.filters.state)
  return `?_publicationState=preview${stateFilterString}&_sort=codice_articolo:${pagination.order}&${offset}${codeFilterString}${brandFilterString}`
}

const buildGiftBoxUrlParamSection = pagination => {
  const start = pagination.currentPage ? (pagination.currentPage - 1) * pagination.itemsPerPage : 0
  const codeFilterString =
    pagination.filters.code && pagination.filters.code !== ""
      ? `codice_confezione_contains=${pagination.filters.code}`
      : ``
  const stateFilterString = buildPublicationFilter(pagination.filters.state)
  const offset = `_start=${start}&_limit=${pagination.itemsPerPage}`
  return `?_publicationState=preview${stateFilterString}&_sort=id:${pagination.order}&${offset}&${codeFilterString}`
}

export const useGeneralHelpers = () => {
  return {
    checkIfIsNumber: e => checkIfIsNumber(e),
    buildPublicationFilter: state => buildPublicationFilter(state),
    buildPosUrlParamSection: pagination => buildPosUrlParamSection(pagination),
    buildGiftBoxUrlParamSection: pagination => buildPosUrlParamSection(pagination),
    buildCountUrl: (type, pagination) => buildCountUrl(type, pagination),
  }
}
