import React from "react"
import ReactDOM from "react-dom"
import { useDispatch, useSelector } from "react-redux"
import Rodal from "rodal"
import { FormattedMessage } from "react-intl"

import ChevronIcon from "../chevron-icon"
import Header from "../header"

import "./details.css"
import { getImageUrl } from "../../utils/getImageUrl"
import { WishlistButton } from "../wishlist-button"

export default function Details({ isOpen, onClose, product }) {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const productPhotoPath = product.featured_media?.url
  const locale = useSelector(state => state.tenute.locale)
  const wishlist = useSelector(state => state.wishList)
  const isInWishlist = wishlist.positemWishlist && wishlist.positemWishlist.find(posItem => posItem.id === product.id)

  const modal = (
    <Rodal
      measure="%"
      width={100}
      height={100}
      visible={isOpen}
      onClose={() => onClose("x")}
      animation="fade"
      duration={400}
      customStyles={{ display: "flex" }}
      className="ProductDetailModal"
    >
      <Header isMinimal />
      <div className="Confezione relative [ p-4 lg:p-6 pt-32 lg:pt-40 ] [ lg:grid lg:grid-cols-2 items-center ]">
        <div
          className="lg:absolute lg:top-0 lg:mt-40 pl-8 left-0 z-10 cursor-pointer hover:text-gray"
          onClick={onClose}
        >
          <div className="flex items-center">
            <ChevronIcon className="transform rotate-90 mr-2" />

            <FormattedMessage id="pos.info.indietro" />
          </div>
        </div>

        <img src={getImageUrl(productPhotoPath)} style={{ flex: 1 }} />

        <div>
          {product.brand && (
            <div className="Details__LogoContainer mb-12 pt-8 lg:pt-0 flex">
              <img src={getImageUrl(product.brand.Logo.url)} className="object-contain" />
            </div>
          )}

          <div className="Confezione__Data__Descrizione [ text-40px lg:text-60px font-BodoniBook leading-tight ] mb-12">
            {locale === "it" ? product?.descrizione_ita || "" : product?.descrizione_eng || ""}
          </div>

          <div>
            <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
              <FormattedMessage id="pos.info.codice" />
            </span>
            <span className="font-BodoniBook text-20px ml-2">{product.codice_articolo}</span>
          </div>

          <div>
            <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
              <FormattedMessage id="pos.info.tipologia" />
            </span>
            <span className="font-BodoniBook text-20px ml-2">
              {locale === "it"
                ? product.pos_item_type?.descrizione_ita || ""
                : product.pos_item_type?.descrizione_eng || ""}
            </span>
          </div>

          {product.material && (
            <div className="flex flex-row">
              <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px leading-9 tracking-1">
                <FormattedMessage id="pos.info.materiale" />
              </span>
              <span className="font-BodoniBook text-20px ml-2 inline capitalize">{product.material.Material}</span>
            </div>
          )}

          <div>
            <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
              <FormattedMessage id="pos.info.prezzo" />
            </span>
            <span className="font-BodoniBook text-20px ml-2">€ {product.prezzo_confezione.toFixed(2)} Iva escl.</span>
          </div>

          {product.unita_confezione > 1 && (
            <div>
              <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
                <FormattedMessage id="pos.info.unita" />
              </span>
              <span className="font-BodoniBook text-20px ml-2 capitalize">{product.unita_confezione}</span>
            </div>
          )}

          {product.stock && (
            <div>
              <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
                <FormattedMessage id="pos.info.statomag" />
              </span>
              <span className="font-BodoniBook text-20px ml-2 capitalize">
                {" "}
                {locale === "it" ? product.stock?.descrizione_ita || "" : product.stock?.descrizione_eng || ""}
              </span>
            </div>
          )}

          <WishlistButton category={"POSITEM"} product={product} isInWishlist={isInWishlist} isInDetailsPage />
        </div>
      </div>
    </Rodal>
  )

  return ReactDOM.createPortal(modal, document.body)
}
