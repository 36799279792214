import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import Scrollbars from "react-custom-scrollbars"
import { useSelector } from "react-redux"
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router"
import HeaderVini from "../../components/header-vini"
import ListItem from "../../components/list-item"
import Tenuta from "../../components/tenuta"
import TenutaGriglia from "../../components/tenuta/tenuta-griglia"
import Griglia from "./components/griglia"
import { useWindowWidth } from "@react-hook/window-size"
import { isNull } from "lodash"
import estateHasDescriptionForSelectedLocale from "../../utils/estateHasDescriptionForSelectedLocale"
import { WishlistPanel } from "../../components/wishlistPanel"

export default function Tenute() {
  const location = useLocation()
  const filteredBrands = useSelector(state => state.wines.filteredBrands)
  const areeGeografiche = useSelector(state => state.wines.areeGeografiche)
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const tipoVista = useSelector(state => state.tenute.vista)
  const [selectedBrand, setSelectedBrand] = useState(filteredBrands[0])
  const windowWidth = useWindowWidth()
  const locale = useSelector(state => state.tenute.locale)

  const [showPopupWishlist, setShowPopupWishlist] = useState({
    showPopup: false,
    wine: null,
    popupStyle: {},
    popupTarget: null,
  })

  useEffect(() => {
    previewBrand(filteredBrands[0])
  }, [filteredBrands])

  function previewBrand(brand) {
    if (windowWidth < 768 || brand.id === selectedBrand.id) {
      return
    }

    setSelectedBrand(brand)
    history.push(`${path}/${brand.Nome}`)
  }

  function togglePopupWishlist(e, brand, collapse = false) {
    console.log(brand)
    // const popupPosition = calcPopupPosition(e.target)
    setShowPopupWishlist({
      showPopup: showPopupWishlist.brand
        ? showPopupWishlist.brand.id === brand.id
          ? !showPopupWishlist.showPopup
          : true
        : true,
      brand: showPopupWishlist.brand ? (showPopupWishlist.brand.id === brand.id ? null : brand) : brand,
      // popupStyle: {
      //   position: "absolute",
      //   top: popupPosition.top,
      //   left: popupPosition.left,
      // },
      popupTarget: e.target,
    })
  }
  return (
    <motion.div className="md:absolute inset-auto md:inset-0 bg-tenute text-black h-screen z-0 overflow-hidden">
      {tipoVista === "lista" && (
        <div className="Tenute__BrandList overflow-hidden absolute left-0 bottom-0 lg:pb-8">
          <Scrollbars
            style={{ width: "100%", height: "100%" }}
            autoHide
            autoHideTimeout={350}
            autoHideDuration={200}
            renderTrackHorizontal={() => <div></div>}
            renderThumbHorizontal={() => <div></div>}
          >
            <ul id="lista" className="Tenute__ColLeft__Scroll">
              {filteredBrands
                .filter(b => !isNull(b))
                .filter(estate => estateHasDescriptionForSelectedLocale(estate, locale))
                .map((brand, index) => {
                  const areaGeografica = areeGeografiche.find(a => a.id === brand.wine_growing_area)

                  return (
                    <ListItem
                      key={brand.id}
                      index={index}
                      item={brand}
                      togglePopupWishlis={togglePopupWishlist}
                      onHover={() => previewBrand(brand)}
                      onClick={e => {
                        setShowPopupWishlist({ ...showPopupWishlist, wine: null, showPopup: false })
                        history.push(`${url}/${brand.Nome}/details`)
                      }}
                      // onClick={() => history.push(`${url}/${brand.Nome}/details`)}
                      title={brand.Nome}
                      subtitle={
                        <>
                          {areaGeografica[`label_${locale}`]} <span> | {brand.zona}</span>
                        </>
                      }
                      isSelected={selectedBrand?.Nome === brand.Nome}
                      focused={showPopupWishlist.brand?.id}
                    />
                  )
                })}
            </ul>
          </Scrollbars>
        </div>
      )}

      {tipoVista === "griglia" && (
        <>
          <HeaderVini showFilters showSwitchGrigliaLista />
          <Griglia contenuto="tenute" />

          <Switch key={path} location={location}>
            <Route path={`${path}/:nomeTenuta/details`}>
              <HeaderVini showFilters />
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                {windowWidth >= 768 && <TenutaGriglia />}
                {windowWidth < 768 && <Tenuta isOpen tipoVista={tipoVista} />}
              </motion.div>
            </Route>
          </Switch>
        </>
      )}

      {tipoVista === "lista" && (
        <Switch key={path} location={location}>
          <Route exact path={`${path}/`}>
            <HeaderVini showFilters showSwitchGrigliaLista />
            {windowWidth >= 768 && <Tenuta nomeTenutaProp={selectedBrand.Nome} tipoVista={tipoVista} />}
          </Route>
          <Route exact path={`${path}/:nomeTenuta`}>
            <HeaderVini showFilters showSwitchGrigliaLista />
            <Tenuta tipoVista={tipoVista} />
          </Route>
          <Route path={`${path}/:nomeTenuta/details`}>
            <HeaderVini showFilters />
            <Tenuta tipoVista={tipoVista} isOpen />
          </Route>
        </Switch>
      )}

      {showPopupWishlist.brand && (
        <WishlistPanel
          colorName="white"
          e={showPopupWishlist.popupTarget}
          wine={showPopupWishlist.brand.wines}
          reference={document.getElementById("lista")}
          referenceSize="lista"
          resetPopup={e => setShowPopupWishlist({ wine: null, showPopupWishlist: false, popupTarget: null })}
        />
      )}
    </motion.div>
  )
}
