/**
 * Questa funzione prende in input il path di un'immagine caricata su Strapi e
 * ritorna l'URL completa di quell'immagine.
 * Questo perché Strapi ritorna solo il path a partire dalla cartella /uploads.
 *
 * E comunque abbiamo introdotto Cloudinary come provider per l'upload quindi
 * alcune immagini avranno come path l'intero URL di Cloudinary, mentre altre
 * saranno semplici upload su Strapi (per questo l'IF).
 *
 * Nell'URL di Cloudinary aggiungiamo il parametro `f_auto` per apportare una
 * trasformazione automatica a tutte le immagini che ottimizza il formato per il
 * browser (webp su browser veri, jpg/png su quelli pacco).
 *
 * @param {string} path Il path dell'immagine ritornato da Strapi
 */

export function getValidatedImageUrl(obj) {
  let result = ""
  if (obj.foto) {
    result = obj.foto.url
    const hasFormats = "formats" in obj.foto
    const hasLargeFormat = hasFormats && "large" in obj.foto.formats
    result = hasLargeFormat ? obj.foto.formats.large : obj.foto
  } else {
    console.log(obj.id)
  }
  return result.url || ""
}
export function getImageUrl(path, width) {
  if (!path) {
    return
  }
  if (path && path.includes("cloudinary")) {
    return path.split("/upload/").join(`/upload/q_auto:eco,f_auto,dpr_auto,${width ? `w_${width}` : "w_auto"}/`)
  } else {
    return `${process.env.REACT_APP_API_BASE_URL}${path}`
  }
}
