import React from "react"

export default function Spinner(props) {
  return (
    <svg
      style={{
        margin: "auto",
        background: "0 0",
        marginLeft: "-20px",
      }}
      width={60}
      height={60}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      display="block"
      {...props}
    >
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-1.328502415458937s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(30 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-1.2077294685990336s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(60 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-1.0869565217391304s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(90 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-0.9661835748792269s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(120 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-0.8454106280193235s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(150 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-0.7246376811594202s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(180 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-0.6038647342995168s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(210 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-0.48309178743961345s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(240 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-0.3623188405797101s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(270 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-0.24154589371980673s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(300 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="-0.12077294685990336s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48.5} y={24} rx={1.5} ry={4.56} width={3} height={12} fill="#fff" transform="rotate(330 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.4492753623188404s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  )
}
