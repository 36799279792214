import { filter } from "lodash"

const initialState = {
  allBrands: [],
  allProducts: [],
  allTypes: [],
  allMaterials: [],
  filteredProducts: [],
  filters: {
    brand: [],
    type: [],
    material: [],
    price: [],
  },
}

export default function posReducer(state = initialState, action) {
  switch (action.type) {
    case "LOADED_OBJECTS":
      const brands = []
      const materials = []
      const types = []
      const allProducts = [...action.payload]
      allProducts.forEach(product => {
        if (product.brand) {
          const isFound = brands.find((brand, index) => brand.id === product.brand.id)
          !isFound && brands.push(product.brand)
        }
        if (product.pos_item_type) {
          const isFound = types.find((type, index) => type.id === product.pos_item_type.id)
          !isFound && types.push(product.pos_item_type)
        }
        if (product.material) {
          const isFound = materials.find((material, index) => material.id === product.material.id)
          !isFound && materials.push(product.material)
        }
      })

      return {
        filters: state.filters,
        allProducts,
        allBrands: brands,
        allTypes: types,
        allMaterials: materials,
        filteredProducts: allProducts,
      }
    case "POS_FILTER_BRAND":
      const brandFilters = { ...state.filters, brand: action.payload ? action.payload : [] }

      return {
        ...state,
        filteredProducts: [...getFilteredProducts(state, brandFilters)],
        filters: { ...state.filters, brand: action.payload ? action.payload : [] },
      }
    case "POS_FILTER_TYPE":
      const typeFilters = { ...state.filters, type: action.payload ? action.payload : [] }

      return {
        ...state,
        filteredProducts: [...getFilteredProducts(state, typeFilters)],
        filters: { ...state.filters, type: action.payload ? action.payload : [] },
      }
    case "POS_FILTER_MATERIAL":
      const materialFilters = { ...state.filters, material: action.payload ? action.payload : [] }
      return {
        ...state,
        filteredProducts: [...getFilteredProducts(state, materialFilters)],
        filters: { ...state.filters, material: action.payload ? action.payload : [] },
      }
    case "POS_FILTER_PRICE":
      const priceFilters = { ...state.filters, price: action.payload ? action.payload : [] }
      return {
        ...state,
        filteredProducts: [...getFilteredProducts(state, priceFilters)],
        filters: { ...state.filters, price: action.payload ? action.payload : [] },
      }
    default:
      return state
  }
}

const getFilteredProducts = (state, filters) => {
  const filteredProducts = state.allProducts.filter((product, index) => {
    let isfilterBrandOk = false

    if (product.brand && filters.brand) {
      isfilterBrandOk = filters.brand.length === 0 || filters.brand.includes(product.brand.Nome)
    }

    if (!product.material) {
      return
    }

    const isFilterTypeOk =
      filters.type.length === 0 ||
      filters.type.includes(product.pos_item_type.descrizione_ita) ||
      filters.type.includes(product.pos_item_type.descrizione_eng)
    const isFilterMaterialOk =
      filters.material.length === 0 ||
      filters.material.includes(product.material.descrizione_ita) ||
      filters.material.includes(product.material.descrizione_eng)
    const isFilterPriceOk =
      filters.price.length === 0 || checkIfPriceIsIncluded(filters.price, product.prezzo_confezione)
    return isfilterBrandOk && isFilterTypeOk && isFilterMaterialOk && isFilterPriceOk
  })

  return filteredProducts
}

const checkIfPriceIsIncluded = (array, price) => {
  const isFound = array.filter((item, index) => {
    const prices = item.split("-")
    const priceMin = prices[0]
    const priceMax = prices[1]
    return priceMin <= price && priceMax >= price
  })
  return isFound.length > 0 ? true : false
}
