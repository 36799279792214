import React from "react"
import _ from "lodash"
import { motion } from "framer-motion"
import { useSelector } from "react-redux"
import "./style.css"
import WishlistIcon from "../wishlist-icon"
export default function ListItem({
  onClick,
  onHover,
  isSelected,
  item,
  index,
  title,
  subtitle,
  isFocused,
  togglePopupWishlis = () => {},
}) {
  const delayedHover = _.debounce(() => onHover(item), 300, { leading: false, trailing: true })
  const wishlist = useSelector(state => state.winesWishlist)
  const wishlistPresence = checkPresenceInWishlits(item)

  function checkPresenceInWishlits(item) {
    if (wishlist) {
      if (item.hasOwnProperty("wines")) {
        const isEstateFound = wishlist.filter(currentWishlist => {
          if (currentWishlist.items) {
            const winesInWishlist = currentWishlist.items.filter(wine => wine.brand.id === item.id)
            return winesInWishlist.length === item.wines.length
          } else {
            return false
          }
        })
        return isEstateFound
      } else {
        const isWineFound = wishlist.filter(
          wish =>
            wish.items.filter(wine => {
              return item.id === wine.id
            }).length > 0
        )
        return isWineFound
      }
    }
  }

  function handleMouseOut() {
    delayedHover.cancel()
  }

  return (
    <div className=" flex overflow-hidden font-BodoniBook text-2xl lg:text-3xl cursor-pointer py-4 first:pt-0 last:pb-0">
      {/* {item?.Foto && (
      
      )} */}
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.5 + 0.03 * index, duration: 1 } }}
        className="flex-initial py-3"
        onClick={e => {
          e.preventDefault()
          if (togglePopupWishlis) {
            togglePopupWishlis(e, item)
          }
        }}
      >
        <WishlistIcon
          color="#2B2B2B"
          fill={wishlistPresence?.length > 0 || (isFocused ? isFocused === item.id : false)}
        />
      </motion.div>
      <motion.li
        onHoverStart={delayedHover}
        onHoverEnd={handleMouseOut}
        onClick={() => onClick(item)}
        className={`flex-initial w-10/12 ml-2 ${isSelected ? "list-disc" : ""}`}
        initial={{ y: "100%", x: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.5 + 0.03 * index, duration: 1 } }}
        style={{ maxWidth: "80%", wordBreak: "break-word" }}
      >
        {title}
        <div className="ListItem__Subtitle text-gray uppercase font-LatoBold tracking-widest">{subtitle}</div>
      </motion.li>
    </div>
  )
}
