import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { FormattedMessage, useIntl } from "react-intl"
import Rodal from "rodal"
import "./style.css"

export const WishlistCreationModal = ({ wine, resetModal = () => {} }) => {
  console.log("🤖 • WishlistCreationModal • wine:", wine)

  const windowWidth = useWindowWidth()
  const [newWishlistName, setNewWishlistName] = useState("")
  let wishLists = useSelector(state => state.winesWishlist)
  const dispatch = useDispatch()

  function createWishlist() {
    if (newWishlistName.length === 0) {
      alert(intl.formatMessage({ id: "wishlist.popup.alert_creazione" }))
      return
    }
    let newWishlist = {
      id: getMaxIndex(wishLists) + 1,
      name: newWishlistName,
      items: Array.isArray(wine) ? wine : wine ? [wine] : [],
    }
    dispatch({ type: "WINES_WISHLIST_CREATE", payload: newWishlist })
    closeModal(newWishlist)
  }
  //RECUPER ID PER NUOVA LISTA
  function getMaxIndex(wishList) {
    if (wishList.length !== 0) {
      let max = wishList.map(item => item.id).sort((a, b) => b - a)
      return max[0]
    } else {
      return 0
    }
  }
  function closeModal(createdWishlist = null) {
    resetModal(createdWishlist)
  }

  const intl = useIntl()
  return (
    <Rodal
      measure={"px"}
      width={windowWidth <= 640 ? windowWidth * 0.95 : 560}
      height={windowWidth <= 640 ? 430 : 392}
      visible={true}
      animation="fade"
      duration={300}
      onClose={closeModal}
    >
      <div className="bg-tenute h-full w-full">
        <div className="font-LatoBold text-black py-6 px-8">
          <FormattedMessage id="wishlst.popup.crea_una_nuova_lista" />
        </div>
        <div className="border-t h-0.5 border-black"> </div>
        <div className="py-6 px-8 border-1">
          <label className="font-LatoBold text-black block w-full">
            <FormattedMessage id="wishlist.popup.nome_della_lista" />
          </label>
          <input
            className="block bg-transparent my-5 p-4 w-full text-black outline-none placeholder-black placeholder-opacity-50 border border-black border-opacity-50"
            type="text"
            name="wishlistName"
            value={newWishlistName}
            placeholder={`${intl.formatMessage({ id: "wishlist.popup.placeholder" })}*`}
            onChange={e => setNewWishlistName(e.target.value)}
          ></input>
          <div className="grid grid-cols-2 gap-x-12 gap-y-2  justify-between mt-8">
            <button
              className="col-span-2 sm:col-span-1 w-full bg-gray w-5/12 uppercase text-white text-center px-4 py-5 font-LatoBold text-11px mt-4 tracking-2"
              onClick={e => closeModal()}
            >
              <FormattedMessage id="wishlist.popup.annulla" />
            </button>
            <button
              className="col-span-2 sm:col-span-1 w-full bg-brown w-5/12 uppercase text-white text-center px-3 py-5 font-LatoBold text-11px mt-4 tracking-2"
              onClick={e => createWishlist()}
            >
              <FormattedMessage id="wishlist.popup.crea" />
            </button>
          </div>
        </div>
      </div>
    </Rodal>
  )
}
