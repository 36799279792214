import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { motion } from "framer-motion"
import { useWindowWidth } from "@react-hook/window-size"

import Wine from "../../../../components/wine"
import TenutaThumb from "../tenuta-thumb"
import { WishlistPanel } from "../../../../components/wishlistPanel"
import "./style.css"

export default function Griglia({ contenuto }) {
  const windowWidth = useWindowWidth()
  const filteredWines = useSelector(state => state.wines.filteredWines)
  const filteredBrands = useSelector(state => state.wines.filteredBrands)
  const locale = useSelector(state => state.tenute.locale)
  const tipoVista = useSelector(state => state.tenute.vista)
  const locales = {
    it: "italiano",
    en: "inglese",
    de: "tedesco",
    ch: "cinese",
  }

  const winesAvailableForThisLocale = filteredWines.filter(wine => !!wine[`testo_${locales[locale]}`])
  const estatesAvailableForThisLocale = filteredBrands.filter(estate => !!estate[`testo_${locales[locale]}`])

  const [showPopupWishlist, setShowPopupWishlist] = useState({
    showPopup: false,
    wine: null,
    popupStyle: {},
    popupTarget: null,
  })

  function calcPopupPosition(target) {
    let rect = target.getBoundingClientRect()
    let gridRect = document.querySelector(".Griglia")
    let scrollLeft = gridRect.scrollLeft
    let scrollTop = gridRect.scrollTop
    let definedWidth = windowWidth < 1024 ? (windowWidth < 768 ? 0.41 : 0.26) : 0.1766
    return {
      top: rect.top + scrollTop - gridRect.getBoundingClientRect().top,
      left: rect.left + scrollLeft - windowWidth * definedWidth,
    }
  }

  useEffect(() => {
    if (!showPopupWishlist.popupTarget) {
      return
    }
    const popupPosition = calcPopupPosition(showPopupWishlist.popupTarget)
    setShowPopupWishlist({
      ...showPopupWishlist,
      popupStyle: {
        position: "absolute",
        top: popupPosition.top,
        left: popupPosition.left,
      },
    })
  }, [windowWidth])

  useEffect(() => {
    setShowPopupWishlist({
      ...showPopupWishlist,
      showPopup: false,
      wine: null,
    })
  }, [filteredWines, tipoVista])

  function togglePopupWishlis(e, wine, collapse = false) {
    const popupPosition = calcPopupPosition(e.target)
    setShowPopupWishlist({
      showPopup: showPopupWishlist.wine
        ? showPopupWishlist.wine.id === wine.id
          ? !showPopupWishlist.showPopup
          : true
        : true,
      wine: showPopupWishlist.wine ? (showPopupWishlist.wine.id === wine.id ? null : wine) : wine,
      popupStyle: {
        position: "absolute",
        top: popupPosition.top,
        left: popupPosition.left,
      },
      popupTarget: e.target,
    })
  }

  return (
    <div className={`Griglia overflow-y-auto bg-tenute ${windowWidth < 768 ? "py-8" : "py-16"}`}>
      <div
        className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-4 gap-y-8 ${
          windowWidth < 768 ? "px-4" : "px-8"
        }`}
      >
        {contenuto === "vini" &&
          winesAvailableForThisLocale.map((wine, index) => (
            <motion.div key={wine.id}>
              <Wine
                togglePopupWishlis={togglePopupWishlis}
                tipoVista="griglia"
                wine={wine}
                index={index}
                showAddToWishList
                focused={showPopupWishlist.wine ? showPopupWishlist.wine.id : null}
              />
            </motion.div>
          ))}

        {/* {contenuto === "vini" && showPopupWishlist.showPopup && showPopupWishlist.wine && ( 
              <WishlistPopup
                showPopup={showPopupWishlist.showPopup}
                wine={showPopupWishlist.wine} 
                popupStyle={showPopupWishlist.popupStyle}
                resetPopup={e => setShowPopupWishlist({...showPopupWishlist, wine: null, showPopup: false, popupStyle : {}})}
              />
        )} */}
        {contenuto === "vini" && showPopupWishlist.wine && (
          <div className="absolute top-0 left-0  w-full h-full">
            {
              <WishlistPanel
                colorName="tenute"
                e={showPopupWishlist.popupTarget}
                reference={document.querySelector(".Griglia")}
                wine={showPopupWishlist.wine}
                resetPopup={e => setShowPopupWishlist({ wine: null, showPopupWishlist: false, popupTarget: null })}
              />
            }
          </div>
        )}
        {contenuto === "tenute" &&
          estatesAvailableForThisLocale.map((tenuta, index) => (
            <motion.div key={tenuta.id}>
              <TenutaThumb tenuta={tenuta} index={index} />
            </motion.div>
          ))}
      </div>
    </div>
  )
}
