import { useCallback, useState } from "react"

export const useLogin = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const login = useCallback(async () => {
    setIsLoggingIn(true)
    setLoginError(false)
    console.log(email, password)
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/local`, {
      method: "POST",
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    })

    if (res.ok) {
      const jsonData = await res.json()
      setIsLoggingIn(false)
      return jsonData
    } else {
      const jsonData = await res.json()

      if (jsonData?.message?.[0]?.messages?.[0]?.id) {
        setLoginError(jsonData.message[0].messages[0].id)
      } else {
        setLoginError(true)
      }

      setIsLoggingIn(false)
      return undefined
    }
  }, [email, password])

  return {
    email,
    setEmail,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    loginError,
    isLoggingIn,
    login,
  }
}

export const useRegistration = (initialUser, onError) => {
  const [user, setUser] = useState(initialUser || { email: "", password: "", username: "" })
  const [registrationError, setRegistrationError] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)

  const register = useCallback(async () => {
    setRegistrationError(false)
    setIsRegistering(true)

    // const userGeolocation = await (await fetch(`${config.BASE_URL}/users/geolocation`)).json()

    // if (userGeolocation && userGeolocation.country !== "IT") {
    //   setRegistrationError("geolocation")
    //   setIsRegistering(false)
    //   onError("geolocation")
    //   return undefined
    // }

    const res = await fetch(process.env.REACT_APP_API_BASE_URL + "/auth/local/register", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...user,
        username: user.username || user.email,
      }),
    })

    const jsonData = await res.json()

    if (res.ok) {
      setIsRegistering(false)
      return jsonData
    } else {
      const errorId = jsonData?.message?.[0].messages?.[0]?.id
      setRegistrationError(errorId)
      setIsRegistering(false)
      onError(errorId)
      return undefined
    }
  }, [onError, user])

  return {
    user,
    setUser,
    registrationError,
    isRegistering,
    register,
  }
}

export const usePasswordForgot = () => {
  const [email, setEmail] = useState("")
  const [requestError, setRequestError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)

  const resetRequest = useCallback(async () => {
    setRequestError(false)
    setIsRequesting(true)

    const res = await fetch(process.env.REACT_APP_API_BASE_URL + "/auth/forgot-password", {
      method: "POST",
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })

    if (res.ok) {
      setIsRequesting(false)
      return true
    } else {
      setRequestError(true)
      setIsRequesting(false)
      return false
    }
  }, [email])

  return {
    email,
    setEmail,
    requestError,
    isRequesting,
    resetRequest,
  }
}

export const usePasswordChange = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [requestError, setRequestError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)

  const changeRequest = useCallback(
    async code => {
      setRequestError(false)
      setIsRequesting(true)

      const res = await fetch(process.env.REACT_APP_API_BASE_URL + "/auth/reset-password", {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          code: code,
          password: password,
          passwordConfirmation: repeatPassword,
        }),
      })

      if (res.ok) {
        const jsonData = await res.json()
        setIsRequesting(false)
        return jsonData
      } else {
        setRequestError(true)
        setIsRequesting(false)
        return undefined
      }
    },
    [email, password, repeatPassword]
  )

  return {
    email,
    setEmail,
    password,
    setPassword,
    repeatPassword,
    setRepeatPassword,
    requestError,
    isRequesting,
    changeRequest,
  }
}
