import React, { useState, useEffect } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { useLogin } from "../../helpers/useLogin"
import { AnimatePresence, motion } from "framer-motion"
import loginBg from "../../img/catalog/hero.jpg"
import "./style.css"

export default function LoginPage() {
  const history = useHistory()
  // const [email, setEmail] = useState("")
  // const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const direction = useSelector(state => state.auth.direction)
  const location = useLocation()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { email, setEmail, password, setPassword, loginError, login } = useLogin()

  useEffect(() => {
    if (location.state?.redirectTo) {
      if (auth.isLoggedIn) {
        history.push(location.state.redirectTo)
      }
    } else {
      history.push("/")
    }
  }, [auth])

  const handleLogin = async () => {
    const data = await login()
    if (data && data.user) {
      dispatch({ type: "LOGIN", payload: data })
    } else {
      setShowErrorMessage(true)
    }
  }

  useEffect(() => {
    console.log(showErrorMessage)
  }, [showErrorMessage])
  return (
    <>
      <div className="Login">
        <img src={loginBg} />
        <div className="absolute w-full h-full bg-black bg-opacity-75"></div>
        <div className="Login__Content w-3/5 md:w-3/6 lg:w-2/6 px-8 md:px-32 py-16 absolute z-10">
          <Link to="/" className="text-white w-full mb-8">
            <span className="inline-block w-full text-center">
              <FormattedMessage id="tenute.wishlist.indietro" />
            </span>
            <svg
              className="block mx-auto mt-2"
              width="15"
              height="9"
              viewBox="0 0 15 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="1.20605" y1="4.20581" x2="14.3825" y2="4.20581" stroke="white" />
              <path d="M4.5 1L1 4.5L4.5 8" stroke="white" />
            </svg>
          </Link>
          <h3 className="text-6xl text-center font-BodoniBook text-white">
            {" "}
            <FormattedMessage id="login.signin" />
          </h3>
          <div className="Login__Title font-BodoniBook text-brown text-4xl md:text-4xl text-center leading-none">
            {location.state && (
              <>
                {location.state.redirectTo?.includes("confezioni-regalo") ? (
                  <FormattedMessage id="tenute.landing.confezioni_regalo" />
                ) : (
                  <FormattedMessage id="tenute.landing.catalogo_pos" />
                )}
              </>
            )}
          </div>
          <form
            className="Login__Form  grid grid-rows-3 gap-4 mt-16 "
            onSubmit={event => {
              event.preventDefault()
              handleLogin()
            }}
          >
            <div className="Login__Input w-full mx-auto md:w-7/9">
              <input
                autoCorrect="off"
                autoCapitalize="none"
                type="text"
                vaue={email}
                onChange={e => setEmail(e.target.value)}
                className="w-full font-LatoRegular tracking-widest bg-black text-white placeholder-white border-b bg-opacity-50 border-b-gray px-3 py-4 focus:outline-none"
                placeholder="Username"
                autoComplete="current-password"
              />
            </div>
            <div className="Login__Input w-full mx-auto md:w-7/9">
              <input
                type="password"
                autoCorrect="off"
                autoCapitalize="none"
                vaue={password}
                onChange={e => setPassword(e.target.value)}
                className="w-full font-LatoRegular tracking-widest bg-black text-white placeholder-white border-b bg-opacity-50 border-b-gray px-3 py-4 focus:outline-none "
                placeholder="Password"
                autoComplete="current-password"
              />
            </div>
            <div className="Login__Button w-full sm:w-3/4 md:w-2/4 mx-auto">
              <button className="bg-brown block w-full uppercase text-white text-center p-5 font-LatoBold text-11px mt-4 tracking-2">
                <FormattedMessage id="login.signin" />
              </button>
            </div>
            <Link to="/" className="text-white text-center italic font-LatoRegular tracking-wide mt-6 cursor-pointer">
              <FormattedMessage id="tenute.wishlist.password_dimenticata" />
            </Link>
          </form>
        </div>
      </div>

      <AnimatePresence exitBeforeEnter>
        {showErrorMessage === true && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "tween", ease: "linear" }}
            key={`error-message`}
            className=" z-10 w-screen h-screen p-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black  flex  flex-col items-center justify-center"
          >
            <div className="text-white text-center text-3xl font-LatoRegular">
              <FormattedMessage id="login.error" />
            </div>
            <div className=" mt-6 w-max mx-auto">
              <button
                onClick={e => {
                  setShowErrorMessage(false)
                }}
                className="bg-brown block px-10 mx-auto uppercase text-white text-center p-5 font-LatoBold text-11px mt-4 tracking-2"
              >
                <FormattedMessage id="login.retry" />
              </button>
              <div className="text-white text-center italic font-LatoRegular tracking-wide w-max mt-4 cursor-pointer">
                <Link to="/" className="">
                  <FormattedMessage id="tenute.wishlist.password_dimenticata" />
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
