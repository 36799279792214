import React from "react"
import Rodal from "rodal"
import ReactDOM from "react-dom"
import magnifyingGlassIcon from "../../img/magnifying-glass.svg"
import "./style.css"
import Fuse from "fuse.js"
import { GiftBox } from "../gift-box"
import { productHasPriceDefinedForCurrentProfile } from "../../utils"
import { connect } from "react-redux"
import { PosItem } from "./../pos-item"
import { FormattedMessage } from "react-intl"

class MenuComponent extends React.Component {
  fuseProdottiOptions = {
    keys: [
      {
        name: "codice_confezione",
        weight: 1,
      },
      {
        name: "brands.Nome",
        weight: 0.5,
      },
      {
        name: "bottiglie.label",
        weight: 0.5,
      },
      {
        name: "descrizione",
        weight: 0.5,
      },
    ],

    threshold: 0.5,
    minMatchCharLength: 2,
    includeScore: true,
  }

  state = {
    query: "",
    searchResults: [],
  }

  constructor() {
    super()
    this.myRef = React.createRef()
  }

  componentDidUpdate(oldProps) {
    if (!oldProps.isOpen && this.props.isOpen) {
      setTimeout(() => {
        this.myRef.current.focus()
      }, 500)
    }
  }

  handleChange = e => {
    const query = e.target.value

    if (this.props.isCatalogoPos) {
      this.setState({ query: query }, () => {
        const searchResults = this.props.posProducts.filter(product => {
          const isDescrItaIncludedInQuery = product.descrizione_eng
            ? product.descrizione_ita.toLowerCase().includes(query.toLowerCase())
            : false
          const isDescrEngIncludedInQuery = product.descrizione_eng
            ? product.descrizione_eng.toLowerCase().includes(query.toLowerCase())
            : false
          return isDescrEngIncludedInQuery || isDescrItaIncludedInQuery
        })

        return this.setState({ searchResults })
      })
    } else {
      this.setState({ query }, () => {
        const prodottoConCodiceConfezioneUgualeAParoleChiave = this.visibleProducts.find(
          p => p.codice_confezione.toLowerCase() === this.state.query.toLowerCase()
        )

        if (prodottoConCodiceConfezioneUgualeAParoleChiave) {
          return this.setState({
            searchResults: [{ item: prodottoConCodiceConfezioneUgualeAParoleChiave }],
          })
        }

        const searchResults = this.fuseProdotti.search(this.state.query)

        this.setState({ searchResults })
      })
    }
  }

  logout = () => {
    this.props.dispatch({ type: "LOGOUT" })
    this.props.dispatch({ type: "MENU_CLOSE" })
  }

  render() {
    const { isOpen, onClose, products, oggettistica } = this.props
    const listino = this.props.auth.listino
    let profilo = this.props.auth.listino

    if (this.props.auth.listino === "gddo") {
      profilo = "offtrade"
    }

    if (this.props.auth.listino === "clienti_privati") {
      profilo = "privati"
    }

    if (!this.visibleProducts) {
      this.visibleProducts = products.filter(product => productHasPriceDefinedForCurrentProfile(product, listino))
    }

    if (!this.fuseProdotti) {
      this.fuseProdotti = new Fuse([...this.visibleProducts, ...oggettistica], this.fuseProdottiOptions)
    }

    const { searchResults } = this.state

    const modal = (
      <Rodal
        measure="%"
        width={100}
        height={100}
        visible={isOpen}
        onClose={() => onClose("x")}
        animation="fade"
        duration={300}
      >
        <div className="MenuModal text-white bg-black overflow-auto">
          <div className="MenuModal__Cerca flex justify-center px-8">
            <input
              ref={this.myRef}
              type="text"
              placeholder="Cerca"
              className="Home__Cerca mb-8 p-4 font-LatoRegular text-lg pl-6"
              style={{ backgroundImage: `url(${magnifyingGlassIcon})` }}
              value={this.state.query}
              onChange={this.handleChange}
            />
          </div>

          {/* Risultati ricerca */}

          {searchResults.length > 0 && (
            <>
              <div className="mt-8 px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                {searchResults.map((product, key) => {
                  const { item } = product
                  return (
                    <div key={key}>
                      {/* {item.codice_confezione} */}
                      {this.props.isCatalogoPos ? (
                        <PosItem key={`pos-item-${product.codice_articolo}`} posItem={product} striped />
                      ) : (
                        <GiftBox key={key} product={item} />
                      )}
                    </div>
                  )
                })}
              </div>
            </>
          )}

          {/* Menu footer */}
          <div className="font-LatoBold text-11px tracking-2 uppercase flex items-center justify-center absolute bottom-0 pb-6 w-full lg:hidden">
            <div>
              <FormattedMessage id="header.labels.profilo" />: {profilo}
            </div>
            <span className="px-3">|</span>
            <div className="cursor-pointer" onClick={this.logout}>
              Log out
            </div>
          </div>
        </div>
      </Rodal>
    )

    return ReactDOM.createPortal(modal, document.body)
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  products: state.products.allProducts,
  oggettistica: state.products.allOggettistica,
})

const Menu = connect(mapStateToProps)(MenuComponent)

export default Menu
