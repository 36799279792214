import React from "react"
import Rodal from "rodal"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { motion } from "framer-motion"

import magnifyingGlassIcon from "../../../../img/magnifying-glass.svg"
import HeaderSearchVini from "./header-search"
import Wine from "../../../../components/wine"

class SearchViniComponent extends React.Component {
  state = {
    query: "",
    searchResults: [],
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidUpdate(oldProps) {
    if (!oldProps.isOpen && this.props.isOpen) {
      setTimeout(() => this.myRef.current.focus(), 500)
    }

    if (oldProps.isOpen && !this.props.isOpen) {
      setTimeout(() => this.setState({ query: "", searchResults: [] }), 500)
    }
  }

  handleChange = e => {
    const query = e.target.value
    const { wines, locale } = this.props

    const winesAvailableForThisLocale = wines.filter(wine => {
      switch (locale) {
        case "it":
          return !!wine.testo_italiano
        case "en":
          return !!wine.testo_inglese
        case "de":
          return !!wine.testo_tedesco
        case "ch":
          return !!wine.testo_cinese //CINESE
        default:
          return true
      }
    })

    const searchResults = winesAvailableForThisLocale.filter(wine => {
      return (
        wine.Nome.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
        wine.brand.Nome.toLowerCase().indexOf(query.toLowerCase()) >= 0
      )
    })

    this.setState({ query, searchResults })
  }

  render() {
    const { isOpen, onClose = () => null, intl } = this.props
    const { searchResults } = this.state

    const modal = (
      <Rodal
        measure="%"
        width={100}
        height={100}
        visible={isOpen}
        onClose={() => onClose("x")}
        animation="fade"
        duration={300}
      >
        <div className="MenuModal text-white bg-black overflow-auto">
          <HeaderSearchVini />

          <div className="flex justify-center px-8">
            <input
              ref={this.myRef}
              type="text"
              placeholder={intl.formatMessage({ id: "tenute.cerca" })}
              className="Home__Cerca mb-8 p-4 font-LatoRegular text-lg pl-6"
              style={{ backgroundImage: `url(${magnifyingGlassIcon})` }}
              value={this.state.query}
              onChange={this.handleChange}
            />
          </div>

          {/* Risultati ricerca */}

          {searchResults && searchResults.length > 0 && (
            <>
              <div className="mt-8 px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 pr-12">
                {searchResults.map((item, key) => (
                  <Wine nameColor="light" key={key} wine={item} />
                ))}
              </div>
            </>
          )}
        </div>

        <motion.img
          className="absolute block right-0 top-1/2 transform -translate-y-1/2 pr-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5, type: "tween", delay: 1 } }}
          src={`${process.env.PUBLIC_URL}/logo-ocm.svg`}
          alt="ocm logo"
        />
      </Rodal>
    )

    return ReactDOM.createPortal(modal, document.body)
  }
}

const mapStateToProps = state => ({
  wines: state.wines.allWines,
  locale: state.tenute.locale,
})

const SearchVini = connect(mapStateToProps)(SearchViniComponent)

export default injectIntl(SearchVini)
