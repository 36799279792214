import React, { useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import { useOnClickOutside } from "../../../helpers/useOnClickOutside"
import sortTipologie from "../../../utils/sortTipologieVino"
import { isNull } from "lodash"

export default function FiltersProdotti({ onClose }) {
  const container = useRef(null)
  const dispatch = useDispatch()
  const [isFiltroTenutaVisibile, setIsFiltroTenutaVisibile] = useState()
  const [isFiltroTipologiaVisibile, setIsFiltroTipologiaVisibile] = useState()
  const [isFiltroFormatoVisibile, setIsFiltroFormatoVisibile] = useState()
  const vistaProdottiTenuteVini = useSelector(state => state.tenute.vistaProdottiTenuteVini)
  const locale = useSelector(state => state.tenute.locale)

  const filters = useSelector(state => state.wines.filters)
  const allWines = useSelector(state => state.wines.allWines)
  const allAreeGeografiche = useSelector(state => state.wines.areeGeografiche)
  const viniFiltratiPerDistribuzione = allWines.filter(wine => wine.Distribuzione === filters.distribuzione)

  const locales = {
    it: "italiano",
    en: "inglese",
    de: "tedesco",
    ch: "cinese",
  }

  useOnClickOutside(container, () => onClose())

  const areeGeografiche = viniFiltratiPerDistribuzione.reduce((aree, vino) => {
    if (aree.includes(vino.brand.wine_growing_area)) {
      return aree
    }

    return [...aree, vino.brand.wine_growing_area]
  }, [])

  const tipologieVino = viniFiltratiPerDistribuzione.reduce((tipologie, vino) => {
    if (tipologie.find(t => t.id === vino.wine_type.id)) {
      return tipologie
    }

    return [...tipologie, vino.wine_type]
  }, [])

  const formati = viniFiltratiPerDistribuzione
    .reduce((formati, vino) => {
      const newFormatsFound = []

      vino.formati.forEach(formato => {
        if (!formati.includes(formato.formato)) {
          newFormatsFound.push(formato.formato)
        }
      })

      return [...formati, ...newFormatsFound]
    }, [])
    .sort((a, b) => parseFloat(a) - parseFloat(b))

  function changeFilterTenuta(payload) {
    dispatch({ type: "CHANGE_FILTER_TENUTA", payload })
  }

  function changeFilterVino(payload) {
    if (!payload) {
      dispatch({ type: "CHANGE_FILTER_BOLLICINE", payload: false })
    }
    dispatch({ type: "CHANGE_FILTER_VINO", payload })
  }

  function changeFilterFormato(payload) {
    dispatch({ type: "CHANGE_FILTER_FORMATO", payload })
  }

  function changeFilterBollicine(payload) {
    dispatch({ type: "CHANGE_FILTER_BOLLICINE", payload })
  }

  function toggleFiltroTenutaOpen() {
    setIsFiltroTenutaVisibile(isVisible => !isVisible)
    setIsFiltroTipologiaVisibile(false)
    setIsFiltroFormatoVisibile(false)
  }

  function toggleFiltroTipologiaOpen() {
    setIsFiltroTipologiaVisibile(isVisible => !isVisible)
    setIsFiltroTenutaVisibile(false)
    setIsFiltroFormatoVisibile(false)
  }

  function toggleFiltroFormatoOpen() {
    setIsFiltroFormatoVisibile(isVisible => !isVisible)
    setIsFiltroTenutaVisibile(false)
    setIsFiltroTipologiaVisibile(false)
  }

  function resetAllFilters() {
    dispatch({ type: "FILTERS_RESET" })
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="FiltersProdotti absolute bg-white text-black"
      ref={container}
    >
      {/* Area vinicola */}

      <div className="border-gray border-b py-6 px-8">
        <div
          className="cursor-pointer font-LatoBold text-xs uppercase tracking-2 flex items-center"
          onClick={toggleFiltroTenutaOpen}
        >
          <FormattedMessage id="tenute.area_vinicola" />
          {filters.tenuta.length > 0 && <span>({filters.tenuta.length})</span>}
          <motion.svg
            className={`ml-2 `}
            width={13}
            height={6}
            viewBox="0 0 13 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            animate={{ rotate: isFiltroTenutaVisibile ? 180 : 0, transition: { type: "tween", duration: 0.5 } }}
          >
            <path d="M6.5 6L.87 0h11.26L6.5 6z" fill="#000" />
          </motion.svg>
        </div>
        <AnimatePresence>
          {isFiltroTenutaVisibile && (
            <motion.div
              className="mt-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "tween", duration: 0.5 }}
            >
              <div
                className={`font-BodoniBook text-xl cursor-pointer ${filters.tenuta.length === 0 ? "text-brown" : ""}`}
                onClick={() => changeFilterTenuta(null)}
              >
                <FormattedMessage id="tenute.vedi_tutti" />
              </div>

              {areeGeografiche
                .filter(a => !isNull(a))
                .map(area => (
                  <div
                    key={`area-${area}`}
                    className={`font-BodoniBook text-xl cursor-pointer mt-2 ${
                      filters.tenuta.indexOf(area) >= 0 ? "text-brown" : ""
                    }`}
                    onClick={() => changeFilterTenuta(area)}
                  >
                    {allAreeGeografiche.find(a => a.id === area)[`label_${locale}`]}
                  </div>
                ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {vistaProdottiTenuteVini === "vini" && (
        <>
          {/* Tipologia Vino */}

          <div className="border-gray border-b py-6 px-8">
            <div
              className="cursor-pointer font-LatoBold text-xs uppercase tracking-2 flex items-center"
              onClick={toggleFiltroTipologiaOpen}
            >
              <FormattedMessage id="tenute.tipologia_vino" />
              {(filters.vino.length > 0 || filters.bollicine) && (
                <span> ({filters.vino.length + (filters.bollicine ? 1 : 0)})</span>
              )}
              <motion.svg
                className={`ml-2 `}
                width={13}
                height={6}
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                animate={{ rotate: isFiltroTipologiaVisibile ? 180 : 0, transition: { type: "tween", duration: 0.5 } }}
              >
                <path d="M6.5 6L.87 0h11.26L6.5 6z" fill="#000" />
              </motion.svg>
            </div>
            <AnimatePresence>
              {isFiltroTipologiaVisibile && (
                <motion.div
                  className="mt-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: "tween", duration: 0.5 }}
                >
                  <div
                    className={`font-BodoniBook text-xl cursor-pointer ${
                      filters.vino.length === 0 && filters.bollicine === false ? "text-brown" : ""
                    }`}
                    onClick={() => changeFilterVino(null)}
                  >
                    <FormattedMessage id="tenute.vedi_tutti" />
                  </div>

                  {/* Tutte le bollicine */}
                  <div
                    className={`relative font-BodoniBook text-xl cursor-pointer mt-4 ${
                      filters.bollicine && "text-brown"
                    } `}
                  >
                    <span
                      className="inline-block align-middle w-3/5"
                      onClick={e => changeFilterBollicine(!filters.bollicine)}
                    >
                      <FormattedMessage id="vini.filtri.bollicine" />
                    </span>
                  </div>

                  {/* Wine types */}
                  {tipologieVino.sort(sortTipologie).map(tipologia => (
                    <div
                      key={tipologia.id}
                      onClick={() => changeFilterVino(tipologia)}
                      className={`relative font-BodoniBook text-xl cursor-pointer mt-4 ${
                        filters.vino.indexOf(tipologia) >= 0 ? "text-brown" : ""
                      }`}
                    >
                      <span className="inline-block align-middle w-3/5">
                        {tipologia[locales[locale]].replace("_", " e ").replace("Specialita", "Specialità")}{" "}
                      </span>
                    </div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Formato */}

          <div className="border-gray border-b py-6 px-8">
            <div
              className="cursor-pointer font-LatoBold text-xs uppercase tracking-2 flex items-center"
              onClick={toggleFiltroFormatoOpen}
            >
              <FormattedMessage id="tenute.formato" />
              {filters.formato.length > 0 && <span>({filters.formato.length})</span>}
              <motion.svg
                className={`ml-2 `}
                width={13}
                height={6}
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                animate={{ rotate: isFiltroFormatoVisibile ? 180 : 0, transition: { type: "tween", duration: 0.5 } }}
              >
                <path d="M6.5 6L.87 0h11.26L6.5 6z" fill="#000" />
              </motion.svg>
            </div>
            <AnimatePresence>
              {isFiltroFormatoVisibile && (
                <motion.div
                  className="mt-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: "tween", duration: 0.5 }}
                >
                  <div
                    className={`font-BodoniBook text-xl cursor-pointer ${
                      filters.formato.length === 0 ? "text-brown" : ""
                    }`}
                    onClick={() => changeFilterFormato(null)}
                  >
                    <FormattedMessage id="tenute.vedi_tutti" />
                  </div>

                  {formati.map(formato => (
                    <div
                      key={formato}
                      className={`font-BodoniBook text-xl cursor-pointer mt-4 ${
                        filters.formato.indexOf(formato) >= 0 ? "text-brown" : ""
                      }`}
                      onClick={() => changeFilterFormato(formato)}
                    >
                      {formato.replace("_", " e ")}
                    </div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </>
      )}
      {/* Reset all */}

      <div className="border-gray bg-antracite hover:bg-black text-white border-b   py-6 px-8">
        <div
          className="cursor-pointer font-LatoBold text-xs uppercase tracking-2 flex items-center"
          onClick={resetAllFilters}
        >
          <FormattedMessage id="tenute.reset_all" />
        </div>
      </div>
    </motion.div>
  )
}
