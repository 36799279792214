import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { WishlistButton } from "../wishlist-button"
import Details from "./details"
import trashIcon from "../../img/trash-icon.svg"

export const PosItem = ({ posItem, striped, showRemoveIcon }) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const dispatch = useDispatch()
  const locale = useSelector(state => state.tenute.locale)
  const wishlist = useSelector(state => state.wishList)
  const [itemQuantity, setItemQuantity] = useState(posItem.quantity ? posItem.quantity : 1)
  const isInWishlist = wishlist.positemWishlist && wishlist.positemWishlist.find(product => product.id === posItem.id)

  const { descrizione_ita, descrizione_eng, price } = posItem

  const brandName = posItem.brand?.Nome ? posItem.brand.Nome : ""

  const featured_media = posItem?.featured_media?.url

  const closeMenu = () => {
    dispatch({ type: "MENU_CLOSE" })
  }

  const openDetail = () => {
    setIsDetailOpen(true)
    document.body.style.overflow = "hidden"
  }

  const closeDetail = () => {
    setIsDetailOpen(false)
    document.body.style.overflow = "auto"
  }

  const handleClick = () => {
    closeMenu()
    openDetail()
  }

  function removeItem(e) {
    e.preventDefault()
    dispatch({ type: "WISHLIST_REMOVE", payload: posItem, category: "positemWishlist" })
  }

  const handleQuantityChange = event => {
    const itemQuantity = event.target.value
    if (!itemQuantity || itemQuantity <= 0) {
      posItem.quantity = 1
      return
    }
    posItem.quantity = +itemQuantity
    setItemQuantity(posItem.quantity)
    dispatch({
      type: "WISHLIST_UPDATE_ALBUM",
      payload: { product: posItem },
    })
  }

  return (
    <div className={`flex flex-col justify-center ${striped ? "Catalog__Item" : ""}`}>
      <div className="p-8 pb-0 w-full cursor-pointer" onClick={handleClick}>
        <div style={{ paddingBottom: "100%" }} className="w-full  relative overflow-hidden">
          {featured_media && (
            <img
              className="hover:scale-95 transition duration-700 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-full max-h-full "
              loading="eager"
              src={featured_media}
            />
          )}
        </div>
        <div className="flex flex-col items-center pt-2">
          <h4 className="text-center text-sm font-LatoBold text-gray tracking-2 uppercase">
            {locale === "it" ? descrizione_ita : descrizione_eng}
          </h4>
          {posItem.brand && <h2 className="font-LatoRegular text-center mt-4 text-lg">{brandName}</h2>}
          <div className="text-center">€ {price?.toFixed(2)} </div>
        </div>
      </div>

      <div className="flex gap-x-6 items-center justify-center py-2">
        {showRemoveIcon && <img className="p-4  cursor-pointer" src={trashIcon} onClick={removeItem} />}
        {showRemoveIcon && (
          <input
            className="border-slate-200/30 h-[26px] border-[1px] text-right w-10 text-white bg-black"
            type="number"
            min={1}
            value={itemQuantity}
            onChange={handleQuantityChange}
          />
        )}
      </div>

      <div className="text-center p-8 pt-0">
        {!showRemoveIcon && <WishlistButton category={"POSITEM"} product={posItem} isInWishlist={isInWishlist} />}
      </div>

      <Details isOpen={isDetailOpen} onClose={closeDetail} product={posItem} />
    </div>
  )
}
export default PosItem
