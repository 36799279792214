import { getImageUrl } from "../utils/getImageUrl"

const getBestPhotoPath = foto => {
  let path = foto.url
  if (foto.formats) {
    if (foto.formats.thumbnail) {
      path = foto.formats.thumbnail.url
    } else if (foto.formats.small) {
      path = foto.formats.small.url
    }
  }
  return path
}
export class GiftBox {
  constructor(giftBox) {
    this.id = giftBox.id
    this.image = giftBox.foto ? getImageUrl(getBestPhotoPath(giftBox.foto)) : ""
    this.codice = giftBox.codice_confezione
    this.published = giftBox.published_at !== null
    this.bottiglie = giftBox.bottiglie

    this.pr_italia = giftBox.prezzo_italia
    this.pr_b2b = giftBox.prezzo_b2b
    this.pr_gddo = giftBox.prezzo_gddo
    this.pr_privati = giftBox.prezzo_clienti_privati

    this.tipo = giftBox.tipo
    this.descrizione = giftBox.descrizione
    this.brand = giftBox.brands
  }

  extractDataFromArray(array, valueKey) {
    let data = ``
    if (array && array.length > 0) {
      array.forEach((item, index) => {
        if (item && item[valueKey]) {
          data += index === 0 ? `${item[valueKey]}` : `, ${item[valueKey]}`
        }
      })
    }
    return data
  }

  extractCsvData() {
    const data = {
      id: this.id,
      codice: this.codice,
      published: this.published,
      bottiglie: this.extractDataFromArray(this.bottiglie, "label"),

      //prices
      pr_italia: this.pr_italia,
      pr_b2b: this.pr_b2b,
      pr_gddo: this.pr_gddo,
      pr_privati: this.pr_privati,

      tipo: this.tipo,
      descrizione: this.descrizione,
      brands: this.extractDataFromArray(this.brand, "Nome"),
    }
    return data
  }
}
