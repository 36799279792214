import React, { useState } from "react"

export default function Toggle({ initialState, toggleFunction }) {
  const [isActive, setIsActive] = useState(initialState)

  return (
    <>
      <div className="flex relative space-x-2 items-center">
        <div
          onClick={e => {
            setIsActive(!isActive)
            toggleFunction && toggleFunction()
          }}
          className={`h-6 w-12 relative rounded-full transition duration-300  ${
            isActive ? "bg-lightBrown" : "bg-slate-400"
          }`}
        >
          <div
            style={{
              height: 26,
              width: 26,
              top: -1,
              left: !isActive ? `0%` : `100%`,
              transform: `translateX(${!isActive ? `-1px` : `calc(-100% + 1px)`})`,
              transition: `0.3s`,
            }}
            className={`absolute pointer-events-none bg-white rounded-full shadow-lg cursor-pointer `}
          ></div>
        </div>
        <div className=" uppercase tracking-slug text-xs ">{isActive ? "Published" : "Draft"} </div>
      </div>
    </>
  )
}
