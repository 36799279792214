import React from "react"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

import "./style.css"
import { getImageUrl } from "../../../../utils/getImageUrl"
import { useSelector } from "react-redux"

export default function TenutaThumb({ tenuta, index }) {
  const locale = useSelector(state => state.tenute.locale)
  const areeGeografiche = useSelector(state => state.wines.areeGeografiche)
  const areaGeografica = areeGeografiche.find(a => a.id === tenuta.wine_growing_area)

  return (
    <Link className="Wine relative" to={`/prodotti/${tenuta.Nome}/details`}>
      {/* Foto */}
      <div className="outer">
        <div className="inner">
          <motion.div
            className="absolute top-1/2 transform -translate-y-1/2"
            initial={{ height: 0 }}
            animate={{ height: "100%", transition: { type: "tween", duration: 1, delay: 0.1 * index } }}
          >
            <img
              className="GiftBox__Photo w-full h-full object-cover"
              src={getImageUrl(tenuta.copertina.url, 800)}
              alt={tenuta.Nome}
            />
          </motion.div>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0, transition: { type: "tween", delay: 0.8, duration: 0.6 } }}
        className={`font-BodoniBook text-2xl text-black`}
      >
        {/* Nome */}
        <div className={`font-BodoniBook text-2xl text-black text-center mt-6`}>{tenuta.Nome}</div>

        <div className="text-center text-sm font-LatoBold text-brown tracking-2 uppercase">
          {areaGeografica[`label_${locale}`]} <span> | {tenuta.zona}</span>
        </div>
      </motion.div>
    </Link>
  )
}
