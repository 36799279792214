import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import Header from "./header"
import Menu from "./menu"

import "./layout.css"
import { useEffect } from "react"

export default function Layout({ children, fullHeight }) {
  const isMenuOpen = useSelector(state => state.menu)
  const products = useSelector(state => state.products.allProducts)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState()
  const [hasLoadedOggettistica, setHasLoadedOggettistica] = useState(false)
  const [hasLoadedProducts, setHasLoadedProducts] = useState(false)

  async function fetchProducts() {
    const giftBoxes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/gift-boxes?_limit=1000`).then(resp =>
      resp.json()
    )
    dispatch({ type: "LOADED_GIFT_BOXES", payload: giftBoxes })
    setHasLoadedProducts(true)

    if (hasLoadedOggettistica) {
      setIsLoading(false)
    }
  }

  async function fetchOggettistica() {
    const oggettistica = await fetch(`${process.env.REACT_APP_API_BASE_URL}/items?_limit=1000`).then(resp =>
      resp.json()
    )
    dispatch({ type: "LOADED_OGGETTISTICA", payload: oggettistica })
    setHasLoadedOggettistica(true)

    if (hasLoadedProducts) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!hasLoadedProducts) {
      setIsLoading(true)
      fetchProducts()
      fetchOggettistica()
    }
  }, [])

  if (isLoading && products.length === 0) {
    return <div>Caricamento</div>
  }

  return (
    <div className={`Layout ${fullHeight ? "Layout--fullHeight" : null}`}>
      <Header />
      <Menu isOpen={isMenuOpen} />
      {children}
    </div>
  )
}
