import React from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import Logo from "../../../../components/logo"

export default function HeaderSearchVini() {
  const dispatch = useDispatch()

  return (
    <header className="HeaderVini flex items-center justify-between bg-black text-white px-8 absolute top-0 left-0 w-full z-10">
      <div className="grid grid-cols-5 gap-10 items-center">
        <Link to="/" onClick={() => dispatch({ type: "VINI_MENU_CLOSE" })}>
          <Logo className="h-12 text-white fill-current" />
        </Link>
      </div>

      {/* Right (search + wishlist + vini) */}
      <div className="flex items-center cursor-pointer" onClick={() => dispatch({ type: "VINI_MENU_CLOSE" })}>
        <svg width={37} height={37} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path stroke="#fff" d="M9.546 8.839l18.385 18.385M8.839 27.224L27.224 8.839" />
        </svg>
      </div>
    </header>
  )
}
