const initialState = {
  brand: [],
  tipologiaConfezione: [],
  edizione: [],
  formatoBottiglia: [],
  vino: [],
  totaleBottiglie: [],
  prezzo: [],
  scalaSconti: [],
  tipologiaVino: [],
}

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case "FILTER_BRAND":
      return { ...state, brand: action.payload }
    case "FILTER_TIPOLOGIA_CONFEZIONE":
      return Object.assign({}, state, { tipologiaConfezione: action.payload })
    case "FILTER_TIPOLOGIA_VINO":
      return Object.assign({}, state, { tipologiaVino: action.payload })
    case "FILTER_EDIZIONE":
      return Object.assign({}, state, { edizione: action.payload })
    case "FILTER_FORMATO_BOTTIGLIA":
      return Object.assign({}, state, { formatoBottiglia: action.payload })
    case "FILTER_VINO":
      return Object.assign({}, state, { vino: action.payload })
    case "FILTER_TOTALE_BOTTIGLIE":
      return Object.assign({}, state, { totaleBottiglie: action.payload })
    case "FILTER_PREZZO":
      return Object.assign({}, state, { prezzo: action.payload })
    case "FILTER_SCALA_SCONTI":
      return Object.assign({}, state, { scalaSconti: action.payload })
    case "FILTER_FORCE":
      return { ...initialState, ...action.payload }
    default:
      return state
  }
}
