import React from "react"
import { useWindowWidth } from "@react-hook/window-size"

import "./style.css"
import HeaderViniMobile from "./mobile"
import HeaderViniDesktop from "./desktop"

export default function HeaderVini(props) {
  const windowWidth = useWindowWidth()

  if (windowWidth < 922) {
    return <HeaderViniMobile {...props} />
  }

  return <HeaderViniDesktop {...props} />
}
