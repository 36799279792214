import React from "react"
import { useWindowWidth } from "@react-hook/window-size"
import Rodal from "rodal"
import "./style.css"

export default function Message(props) {
  const windowWidth = useWindowWidth()

  function closeModal() {
    // console.log(props.close)
    if (props.close) {
      props.close()
    }
  }
  return (
    <>
      {" "}
      {props.children && (
        <Rodal
          measure={"px"}
          width={windowWidth <= 640 ? windowWidth * 0.95 : 560}
          height={windowWidth <= 640 ? 290 : 290}
          visible={props.children ? true : false}
          closeOnEsc={true}
          showCloseButton={true}
          onClose={e => closeModal()}
          animation="fade"
          duration={300}
        >
          <div className="bg-tenute relative w-full h-full">
            <div className="font-LatoBold text-black py-6 px-8 text-center">{props.title}</div>
            <div className="border-t h-0.5 border-black"> </div>
            <div className="py-6 px-8 border-1">
              <label className="font-LatoBold block w-full text-center">{props.children}</label>
              <div className="absolute  bottom-1 left-1/2 w-full transform -translate-x-1/2">
                <button
                  className=" bg-brown w-5/12 uppercase text-white text-center px-4 py-5 font-LatoBold text-11px mt-8 block mx-auto tracking-2"
                  onClick={e => closeModal()}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Rodal>
      )}
    </>
  )
}
