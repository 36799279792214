import * as React from "react"

function LineaDiagonale({ white = false }) {
  return (
    <>
      <div className="flex items-center justify-center">
        <svg height={40} width={40}>
          <path d="M40 .5l-39 39" stroke={white ? "white" : "black"} fill="none" strokeLinecap="square" />
        </svg>
      </div>
    </>
  )
}

export default LineaDiagonale
