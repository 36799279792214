import * as React from "react"
import "./style.css"

function WishlistIcon({ color, nonVuota, fill}) {
  return (
    <div className={`WishlistIcon relative ${nonVuota ? "nonVuota" : ""} `}>
      <svg width={11} height={24}>
        {!fill 
          ? <path 
            d="M1.238 20.783l4.262-4.4 4.262 4.4a.7.7 0 00.513.217.861.861 0 00.283-.054.736.736 0 00.442-.688V.742A.726.726 0 0010.275 0H.725A.726.726 0 000 .742v19.516c0 .308.177.561.442.688.283.108.601.054.796-.163zM1.45 1.484h8.082v16.982l-3.537-3.657a.7.7 0 00-.513-.218.7.7 0 00-.513.218l-3.537 3.657V1.484h.018z"
            fill={color || "#F5F5F5"}
            fillRule="evenodd"/>
          : <path fillRule="evenodd" clipRule="evenodd" d="M1.23792 21.9927L5.5 17.3375L9.76208 21.9927C9.90357 22.146 10.0804 22.2226 10.2749 22.2226C10.3633 22.2226 10.4518 22.2035 10.5579 22.1652C10.8231 22.0502 11 21.7629 11 21.4372V0.785455C11 0.344834 10.6817 0 10.2749 0H0.725098C0.318348 0 0 0.344834 0 0.785455V21.4372C0 21.7629 0.176869 22.0311 0.442139 22.1652C0.725099 22.2801 1.04339 22.2226 1.23792 21.9927Z" fill={color || "#F5F5F5"}/>
        }
      
      </svg>
    </div>
  )
}




export default WishlistIcon
