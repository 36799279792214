import { AnimatePresence, motion } from "framer-motion"
import _ from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Link } from "react-router-dom"
import { getImageUrl } from "../../utils/getImageUrl"
import useWindowSize from "../../utils/useWindowSize"
import Chevron from "../chevron"
import FormatoBottiglia from "../formato-bottiglia"
import { useWindowWidth } from "@react-hook/window-size"
import "./style.css"
import getLinkCorretto from "../../utils/getLinkCorretto"
import { WishlistPanel } from "../wishlistPanel"
export default function Vino({ tenuta, tipoVista, onClose }) {
  const { idVino } = useParams()
  const locale = useSelector(state => state.tenute.locale)

  const winesAvailableForThisLocale = tenuta?.wines?.filter(wine => {
    switch (locale) {
      case "it":
        return !!wine.testo_italiano
      case "en":
        return !!wine.testo_inglese
      case "de":
        return !!wine.testo_tedesco
      case "ch":
        return !!wine.testo_cinese //CINESE
      default:
        return true
    }
  })

  const vino = winesAvailableForThisLocale.find(wine => wine.id === parseInt(idVino, 10))
  const index = winesAvailableForThisLocale.findIndex(wine => wine.id === parseInt(idVino, 10))
  const [opacity, setOpacity] = useState(1)
  const history = useHistory()
  const testo = useRef(null)
  const windowSize = useWindowSize()

  const [isSmallScreen, setIsSmallScreen] = useState()
  const windowWidth = useWindowWidth()

  // Regolo lo scroll in base al contenuto
  useEffect(() => {
    if (!testo.current) {
      return
    }

    const rectTesto = testo.current.getBoundingClientRect()

    if (rectTesto.bottom > window.innerHeight / 2) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }, [testo, windowSize])

  if (!vino) {
    history.push("/prodotti")
    return null
  }

  function fadeOut(path, closeTenuta) {
    if (tipoVista === "griglia" && closeTenuta) {
      onClose()
    }

    setOpacity(0)
    setTimeout(() => {
      history.push(path)
    }, 500)
  }

  const handleWheel = _.debounce(deltaY => {
    let path

    // Scroll down
    if (deltaY > 0) {
      if (index === winesAvailableForThisLocale.length - 1) {
        return
      }

      path = `/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[index + 1].id}`
      history.push(path)
    }

    // Scroll up
    if (deltaY < 0) {
      if (index === 0) {
        fadeOut(`/prodotti/${tenuta.Nome}/details`)
      }

      if (index !== 0) {
        history.push(`/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[index - 1].id}`)
      }
    }
  })

  const handleWheelDebounce = e => {
    handleWheel(e.deltaY)
  }

  const testi = { it: "testo_italiano", en: "testo_inglese", de: "testo_tedesco", ch: "testo_cinese" }

  function Container(props) {
    if (windowWidth < 768) {
      return <div {...props}>{props.children}</div>
    }

    return <motion.div {...props}>{props.children}</motion.div>
  }

  return (
    <motion.div
      className="Vino fixed lg:absolute left-0 right-0 bottom-0 text-black z-20 lg:flex bg-tenute"
      animate={{ opacity }}
      exit={{ transition: { delay: 0.5 } }}
      key={`${vino.id}-vino`}
    >
      <div style={{ width: windowWidth > 921 ? "67vw" : "100vw" }}>
        <Container
          className="Vino__Foto relative lg:absolute lg:h-full bg-black overflow-y-hidden"
          initial={{ width: 0 }}
          animate={{ width: windowWidth > 921 ? "67vw" : "100vw", transition: { type: "tween", duration: 1 } }}
          exit={{ width: 0, transition: { delay: 1 } }}
          onWheel={handleWheelDebounce}
        >
          {vino.Foto && vino.Foto.url && (
            <motion.img
              initial={{ scale: 1.2 }}
              animate={{ scale: 1, transition: { type: "tween", duration: 1 } }}
              className="w-full h-full object-cover object-center"
              src={getImageUrl(vino.Foto.url, 1800)}
              alt="Copertina"
            />
          )}

          {!vino.Foto || (!vino.Foto.url && <div className="w-full h-full bg-black"></div>)}
        </Container>

        {/* Scroll hint */}

        {index < winesAvailableForThisLocale.length - 1 && windowWidth >= 768 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="Tenuta__ArrowContainer absolute left-0 bottom-0"
          >
            <Link
              to={`/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[index + 1].id}`}
              className="absolute bottom-0 left-1/2 transform -translate-x-1/2 pb-8 flex flex-col items-center"
            >
              <svg width={52} height={28} viewBox="0 0 52 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1l25 25L51 1" stroke="#fff" strokeWidth={2} />
              </svg>
            </Link>
          </motion.div>
        )}
      </div>

      <div
        className={`Vino__Testo lg:absolute lg:h-full right-0 bg-tenute text-black p-4 ${
          isSmallScreen
            ? windowWidth < 768
              ? "overflow-y-visible"
              : "overflow-y-auto"
            : windowWidth < 768
            ? "overflow-y-visible"
            : "overflow-y-hidden"
        }`}
      >
        {/* Nome */}
        <motion.div
          className="Tenuta__Nome font-BodoniBook text-4xl lg:text-5xl leading-none mt-2"
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.5, type: "tween", duration: 0.6 } }}
          exit={{ opacity: 0 }}
          /* ref={nome} */
        >
          {vino.Nome}
        </motion.div>

        {/* Denominazione */}
        <motion.div
          className="Tenuta__Denominazione font-LatoBold mt-2 text-gray uppercase text-sm tracking-2"
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.5, type: "tween", duration: 0.6 } }}
          exit={{ opacity: 0 }}
        >
          {vino.Denominazione}
        </motion.div>

        {/* Testo */}
        <motion.div
          className={`Tenuta__Testo font-BodoniBook mt-12 text-xl leading-relaxed pr-4 ${
            windowWidth < 768 ? "overflow-y-visible" : "overflow-y-auto"
          }`}
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.75, type: "tween", duration: 0.6 } }}
          exit={{ opacity: 0 }}
          ref={testo}
        >
          {vino[testi[locale]]}
        </motion.div>

        {/* Btn wishlist */}
        <motion.div
          // className={windowWidth < 1024 ? "mt-16 mb-12 w-1/3" : "mt-16 mb-12 w-4/5 mx-auto"}
          className="Tenuta__Testo font-BodoniBook mt-12 text-xl leading-relaxed overflow-y-auto pr-4"
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0, transition: { type: "tween", duration: 0.6, delay: 0.5 } }}
          exit={{ opacity: 0 }}
        >
          <div className="my-8 w-full sm:w-1/2 md:w-1/3 lg:w-full">
            <WishlistPanel
              showIcon={windowWidth < 768 || windowWidth > 1024 ? true : false}
              showPopup
              noWrap
              wine={vino}
            />
          </div>
        </motion.div>

        {/* <motion.div
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0, transition: { type: "tween", duration: 0.6, delay: 0.5 } }}
          exit={{ opacity: 0 }}
          className={`Tenuta__BtnWishlist ${
            isSmallScreen || windowWidth < 768 ? "Tenuta__BtnWishlist--small" : "lg:absolute bottom-0 left-0 pl-12"
          }`}
        >
          {!isInWishlist && (
            <button
              ref={btnWishlist}
              className="font-LatoBold uppercase text-11px mt-16 tracking-2 px-4 py-2 flex flex-row items-center gap-2 cursor-pointer pb-8"
              onClick={() => addToWishlist(vino)}
            >
              <LineaDiagonale />
              <FormattedMessage id="tenute.aggiungi_wishlist" />
            </button>
          )}

          {isInWishlist && (
            <div className="font-LatoBold uppercase text-11px mt-24 tracking-2 px-4 py-2 flex flex-row items-center gap-2 pb-8">
              <WishlistIcon color="black" />
              <div className="ml-4">
                <FormattedMessage id="tenute.aggiunto_wishlist" />
              </div>
            </div>
          )}
        </motion.div> */}

        {/* Formato bottiglie */}

        <motion.div
          className={`Tenuta__Formati grid grid-cols-6 items-end gap-4 ${
            isSmallScreen || windowWidth < 768 ? "" : "lg:absolute bottom-0 left-0 right-0 px-12 lg:mb-32"
          }`}
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0, transition: { type: "tween", duration: 0.6, delay: 0.2 } }}
          exit={{ opacity: 0 }}
        >
          <AnimatePresence>
            {vino.formati
              .sort((a, b) => a.formato - b.formato)
              .map((f, i) => (
                <FormatoBottiglia formato={f.formato} key={i} k={i} />
              ))}
          </AnimatePresence>
        </motion.div>

        {/* Footer */}
        <motion.div
          className={`${isSmallScreen ? "mt-8" : "lg:absolute bottom-0 left-0 right-0 p-4 lg:px-12 lg:py-8"}`}
        >
          <div className="border border-gray border-t mb-6"></div>
          <div className="flex items-center justify-between">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="font-BodoniBook text-xl text-brown"
              href={getLinkCorretto(tenuta.link_sito)}
            >
              <FormattedMessage id="tenute.prodotti.visita_il_sito" />
            </a>

            <div className="Tenuta__Pagination grid items-center grid-cols-3">
              {index === 0 && (
                <div
                  className="flex justify-center cursor-pointer p-2"
                  onClick={() => fadeOut(`/prodotti/${tenuta.Nome}/details`)}
                >
                  <Chevron />
                </div>
              )}

              {index !== 0 && (
                <Link
                  className="flex justify-center cursor-pointer p-2"
                  to={`/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[index - 1].id}`}
                >
                  <Chevron />
                </Link>
              )}

              <div className="flex justify-center font-LatoBold text-sm">
                {index + 2} / {winesAvailableForThisLocale.length + 1}
              </div>

              <Link
                className="flex justify-center transform rotate-180 cursor-pointer p-2"
                to={
                  index === winesAvailableForThisLocale.length - 1
                    ? `/prodotti/${tenuta.Nome}/details/${vino.id}`
                    : `/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[index + 1].id}`
                }
                onClick={e => {
                  if (index === winesAvailableForThisLocale.length - 1) {
                    e.preventDefault()
                  }
                }}
              >
                <Chevron disabled={index === winesAvailableForThisLocale.length - 1} />
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}
