import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { saveState } from "./redux/local-storage"
import { BrowserRouter as Router } from "react-router-dom"

import { Provider } from "react-redux"
import createStore from "./redux/create-store"

import "./styles/main.css"
import "./styles/fonts.css"
import "./styles/rodal.css"
import "./styles/scrollbar.css"
import "./styles/global.css"

const store = createStore()

store.subscribe(() => {
  saveState(store.getState())
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// serviceWorker.register({
//   onUpdate: reg => {
//     console.log("SW updated", reg)
//   },
// })

// Workaround for viewport units on mobile browsers @SEE https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`)
window.addEventListener("resize", () => document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`))
