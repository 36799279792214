import React from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import logoSvg from "../../img/logo.svg"
import WishlistIcon from "../wishlist-icon"
import hamburgerIcon from "../../img/hamburger.svg"
import closeIcon from "../../img/close-icon.svg"
import { FormattedMessage } from "react-intl"

import "rodal/lib/rodal.css"
import "./style.css"

export default function Header({ isMinimal = false }) {
  const isMenuOpen = useSelector(state => state.menu)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const wishlist = useSelector(state => state.wishList)
  const isWishlistPage = window.location.pathname.includes("wishlist")
  const history = useHistory()
  const location = useLocation()
  function openMenu() {
    dispatch({ type: "MENU_OPEN" })
  }

  function closeMenu() {
    dispatch({ type: "MENU_CLOSE" })
  }

  function logout() {
    dispatch({ type: "LOGOUT" })
  }

  function goHome() {
    dispatch({ type: "GO_HOME" })
  }

  let profilo = auth.listino
  let role = auth.role
  if (auth.listino === "gddo") {
    profilo = "offtrade"
  }

  if (auth.listino === "clienti_privati") {
    profilo = "privati"
  }

  const listingHref = location.pathname.includes("pos") ? "/pos-listing" : "/giftbox-listing"
  const currentWishlist = location.pathname.includes("pos") ? wishlist.positemWishlist : wishlist.giftboxWishlist
  const wishlistLink = location.pathname.includes("pos") ? "/wishlist/pos" : "/wishlist/giftbox"
  return (
    <>
      <header className="Header bg-black flex justify-between fixed z-110 inset-0 text-white px-6 lg:px-8">
        {(isMinimal || !auth.isLoggedIn) && <div style={{ flex: 1 }}></div>}
        {!isMinimal && auth.isLoggedIn && (
          <div className="Header__LeftButtons flex-1 text-left lg:pt-2 flex items-center lg:items-start">
            {!isMenuOpen && !isWishlistPage && (
              <img className="block lg:hidden" src={hamburgerIcon} style={{ height: "20px" }} onClick={openMenu} />
            )}

            {isMenuOpen && (
              <img className="cursor-pointer" src={closeIcon} style={{ height: "20px" }} onClick={closeMenu} />
            )}
          </div>
        )}

        <Link to="/" className="flex-1 text-center" onClick={goHome}>
          <img className="Logo inline" src={logoSvg} alt="logo" />
        </Link>

        <div className="Header__RightButtons font-LatoBold text-11px tracking-2 uppercase flex-1 text-right flex  justify-end items-center lg:items-start">
          <div className="flex items-center ">
            {auth.isLoggedIn && !isMenuOpen && !isWishlistPage && (
              <>
                <div className={`flex items-center justify-end ${auth.direction === "/pos" && "flex-row-reverse"}`}>
                  <div className={`hidden lg:flex justify-end ${auth.direction !== "/pos" && "mr-10"}`}>
                    <>
                      {role && role.toLowerCase() === "admin" && (
                        <>
                          <a _target="blank" className="cursor-pointer" href={listingHref}>
                            Listing
                          </a>
                          <span className="px-3">|</span>
                        </>
                      )}

                      <div>
                        <FormattedMessage id="header.labels.profilo" />: {profilo}
                      </div>
                      <span className="px-3">|</span>
                      <div className="cursor-pointer" onClick={logout}>
                        Log out
                      </div>
                    </>
                  </div>
                  <div className={`flex items-center ${auth.direction === "/pos" && "invisible"}`}>
                    <Link to={wishlistLink} className={`block cursor-pointer `}>
                      <span className="hidden lg:inline mr-4">Wishlist</span>
                    </Link>
                    <Link to={wishlistLink} className={`block cursor-pointer `}>
                      <WishlistIcon nonVuota={currentWishlist && currentWishlist.length > 0} className="inline" />
                    </Link>
                  </div>
                </div>
              </>
            )}

            {isWishlistPage && !isMinimal && (
              <span className="cursor-pointer" onClick={() => history.goBack()}>
                Close
              </span>
            )}
          </div>
        </div>
      </header>
    </>
  )
}
