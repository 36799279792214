import * as React from "react"

function CarouselArrow(props) {
  return (
    <svg width={41} height={41} {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#0E0E0E" d="M0 0h40.8v40.8H0z" />
        <path
          stroke="#FFF"
          strokeWidth={1.2}
          d="M20.4 15.309l5.091 5.091-5.091 5.091"
        />
      </g>
    </svg>
  )
}

export default CarouselArrow
