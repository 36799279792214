import React, { useEffect } from "react"
import { Switch, Route, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AnimatePresence, motion } from "framer-motion"
import { IntlProvider } from "react-intl"

import HomePage from "./pages/home"
import CatalogoPage from "./pages/catalogo"
import { CatalogoPos } from "./pages/catalogo-pos"
import { WishlistPage } from "./pages/wishlist"
import GiftBoxListing from "./pages/giftbox-listing/"
import { POSListing } from "./pages/pos-listing/"
import LoginPage from "./pages/login"
import { WishlistTenutePage } from "./pages/wishlist-tenute"
import LandingPage from "./pages/landing"
import { TenutePasswordReset } from "./pages/tenute/containers/tenute_password_reset"
import ViniPage from "./pages/vini"
import SearchVini from "./pages/tenute/components/search"
import Prodotti from "./pages/prodotti"
import { WishlistDetails } from "./pages/wishlist-details"

import it from "./locales/it"
import en from "./locales/en"
import de from "./locales/de"
import ch from "./locales/ch"
import { tenuteActionsTypes } from "./redux/tenute-reducer"

export default function App() {
  const location = useLocation()
  const isMenuOpen = useSelector(state => state.menuVini)
  const locale = useSelector(state => state.tenute.locale)
  const myPath = location.pathname.split("/")[1]
  const dispatch = useDispatch()

  const pageVariants = {
    initial: { opacity: 0 },
    in: { opacity: 1, transition: { type: "tween", duration: 1 } },
    out: { opacity: 1, transition: { type: "tween", duration: 1 } },
  }

  const allLabels = { it, en, de, ch }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const locale = urlParams.get("lang")

    if (locale && ["de", "en", "it", "ch"].includes(locale)) {
      dispatch({ type: tenuteActionsTypes.TENUTE_CHANGE_LINGUA, payload: locale })
    }
  }, [])

  return (
    <AnimatePresence>
      <IntlProvider messages={allLabels[locale]} locale={locale} defaultLocale="it">
        <SearchVini isOpen={isMenuOpen} />
        <Switch key={myPath} location={location}>
          <Route path="/giftbox-listing">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <GiftBoxListing />
            </motion.div>
          </Route>

          <Route path="/pos-listing">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <POSListing />
            </motion.div>
          </Route>

          <Route path="/pos/:tenutaId?">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <CatalogoPos />
            </motion.div>
          </Route>

          <Route path="/pos">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <CatalogoPos />
            </motion.div>
          </Route>

          <Route exact path="/confezioni-regalo">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <HomePage />
            </motion.div>
          </Route>

          <Route path="/catalogo">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <CatalogoPage />
            </motion.div>
          </Route>

          <Route path="/wishlist">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <WishlistPage />
            </motion.div>
          </Route>

          <Route path="/login">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <LoginPage />
            </motion.div>
          </Route>

          <Route exact path="/password-reset">
            <motion.div
              variants={pageVariants}
              initial="initial"
              animate="in"
              exit={{ opacity: 0, transition: { type: "tween", duration: 0.5 } }}
            >
              <TenutePasswordReset />
            </motion.div>
          </Route>

          <Route exact path="/wishlist-prodotti">
            <motion.div
              variants={pageVariants}
              initial="initial"
              animate="in"
              exit={{ opacity: 0, transition: { type: "tween", duration: 0.5 } }}
            >
              <WishlistTenutePage />
            </motion.div>
          </Route>

          <Route path="/wishlist-details">
            <motion.div
              variants={pageVariants}
              initial="initial"
              animate="in"
              exit={{ opacity: 0, transition: { type: "tween", duration: 0.5 } }}
            >
              <WishlistDetails />
            </motion.div>
          </Route>

          <Route path="/vini">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <ViniPage />
            </motion.div>
          </Route>

          <Route path="/prodotti">
            <Prodotti />
          </Route>

          <Route path="/">
            <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
              <LandingPage />
            </motion.div>
          </Route>
        </Switch>
      </IntlProvider>
    </AnimatePresence>
  )
}
