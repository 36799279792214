import React from "react"
import Slider from "react-slick"
import CarouselArrow from "./carousel-arrow"
import "../../styles/slick.css"

export default function Carousel({ children }) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: dots => {
      return (
        <div>
          <ul className="CustomPaging flex flex-row mt-8 lg:mt-24 px-8 lg:px-32">{dots}</ul>
        </div>
      )
    },
    customPaging: () => <div className="CustomPaging__Dot"></div>,
  }

  return <Slider {...settings}>{children}</Slider>
}

function NextArrow(props) {
  const { className, onClick } = props

  return (
    <div
      className={`${className} ${className.includes("slick-disabled") ? "hidden" : null}`}
      onClick={onClick}
      style={{
        position: "absolute",
        right: "-16px",
        top: "50%",
        cursor: "pointer",
      }}
    >
      <CarouselArrow />
    </div>
  )
}

function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <div
      className={`${className} ${className.includes("slick-disabled") ? "hidden" : null}`}
      onClick={onClick}
      style={{
        position: "absolute",
        left: "-16px",
        top: "50%",
        zIndex: 10,
        transform: "rotate(180deg)",
        cursor: "pointer",
      }}
    >
      <CarouselArrow />
    </div>
  )
}
