import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import "./style.css"
import { getImageUrl } from "../../utils/getImageUrl"
import { motion } from "framer-motion"
import placeholder from "./ugo.jpg"
import Checkbox from "../checkbox"

export const Album = ({
  album,
  toggleCreation = () => {},
  changeSelection = () => {},
  nameColor = "white",
  index,
  showCheckbox,
  selectedItems,
  showRemoveIcon,
}) => {
  const path = album ? `/wishlist-details/${album.id}` : null
  const dispatch = useDispatch()
  const [checkboxState, setCheckboxState] = useState(false)

  function removeItem(e) {
    e.preventDefault()
    dispatch({ type: "WINES_WISHLIST_DELETE", payload: album })
  }

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCheckboxState(false)
    }
  }, [selectedItems])

  function handleClick(e) {
    if (!album) {
      e.preventDefault()
      if (toggleCreation) {
        toggleCreation()
      }
    }
  }
  function checkboxManagement(e) {
    setCheckboxState(prevValue => !prevValue)
    if (changeSelection) {
      changeSelection()
    }
  }

  return (
    <Link to={path ? path : ""} className="Wine relative" onClick={e => handleClick(e)}>
      {/* Foto */}
      <div className="outer">
        <div className="inner">
          <motion.div
            className="absolute w-full top-1/2 transform -translate-y-1/2 "
            initial={{ height: 0 }}
            animate={{ height: "100%", transition: { type: "tween", duration: 1, delay: 0.1 * index } }}
          >
            {/* icona rimozione wishlist */}
            {album?.items?.length > 1 && (
              <img
                className="GiftBox__Photo block absolute top-0  z-0 w-full h-full object-cover transform -rotate-8"
                src={album.items[1]?.Foto ? getImageUrl(album.items[1]?.Foto.url, 800) : placeholder}
                alt={album.name}
              />
            )}
            {album?.items?.length > 2 && (
              <img
                className="GiftBox__Photo block absolute top-0  z-0 w-full h-full object-cover transform rotate-4"
                src={album.items[2]?.Foto ? getImageUrl(album.items[2]?.Foto.url, 800) : placeholder}
                alt={album.name}
              />
            )}
            {album ? (
              album.items?.length > 0 ? (
                <img
                  className={`GiftBox__Photo block z-3 w-full h-full object-cover ${
                    album.items.length > 1 && "transform "
                  } `}
                  src={album.items[0]?.Foto ? getImageUrl(album.items[0]?.Foto.url, 800) : placeholder}
                  alt={album.name}
                />
              ) : (
                <motion.div className="relative border border-white hover:border-brown hover:text-brown h-full w-full duration-200">
                  <div className="absolute w-full text-center h-1/2 top-1/2 transform -translate-y-1/2">
                    <div className="text-2xl font-BodoniBook tracking-wide">{album.name}</div>
                  </div>
                </motion.div>
              )
            ) : (
              <motion.div className="relative border border-white hover:border-brown hover:text-brown h-full w-full duration-200">
                <div className="absolute w-full text-center h-1/2 top-1/2 transform -translate-y-1/2">
                  <div className="text-2xl font-BodoniBook tracking-wide">+</div>
                  <div className="text-2xl font-BodoniBook tracking-wide">
                    <FormattedMessage id="wishlst.popup.crea_una_lista" />
                  </div>
                </div>
              </motion.div>
            )}
            {showCheckbox && <Checkbox changeState={e => checkboxManagement()} selected={checkboxState} />}
            {album && showRemoveIcon && (
              <div
                className=" transform hover:rotate-8 transition duration-300 absolute top-0 right-0 z-10 cursor-pointer p-4"
                onClick={removeItem}
              >
                <svg width={19} height={23} viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.915 0c-.243.024-.45.261-.448.516v2.32H.472c-.259.013-.484.27-.472.54.013.27.26.504.519.492h1.028L3.735 22.23a.52.52 0 00.495.46h10.39c.243.002.469-.21.495-.46l2.188-18.363h1.029c.261.004.502-.244.502-.516s-.241-.52-.502-.516h-4.949V.516c0-.27-.235-.516-.494-.516H5.915zm.541 1.031h5.938v1.805H6.456V1.031zM2.552 3.868h13.746L14.18 21.659H4.67L2.552 3.868zm3.85 3.601c-.243.028-.446.27-.44.524v9.798c-.004.273.233.523.494.523.262 0 .499-.25.495-.523V7.993c.006-.292-.27-.556-.549-.524zm2.969 0c-.243.028-.446.27-.44.524v9.798c-.004.273.233.523.494.523.262 0 .499-.25.495-.523V7.993c.006-.292-.27-.556-.549-.524zm2.969 0c-.243.028-.446.27-.44.524v9.798c-.005.273.232.523.494.523.261 0 .498-.25.495-.523V7.993c.006-.292-.27-.556-.55-.524z"
                    fill={"#FFF"}
                  />
                </svg>
              </div>
            )}
          </motion.div>
        </div>
      </div>

      {album && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0, transition: { type: "tween", delay: 0.8, duration: 0.6 } }}
          className={`font-BodoniBook text-2xl ${nameColor === "dark" ? "text-black" : "text-white"} text-center mt-6`}
        >
          {/* Nome */}
          <div
            className={`font-BodoniBook text-2xl ${
              nameColor === "dark" ? "text-black" : "text-white"
            } text-center mt-6`}
          >
            {album.name ? album.name.charAt(0).toUpperCase() + album.name.slice(1) : "Undefined wishlist"}
          </div>
        </motion.div>
      )}
    </Link>
  )
}
