export default function estateHasDescriptionForSelectedLocale(estate, locale) {
  const locales = {
    it: "italiano",
    en: "inglese",
    de: "tedesco",
    ch: "cinese",
  }

  return !!estate[`testo_${locales[locale]}`]
}
