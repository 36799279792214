import { getImageUrl } from "../utils/getImageUrl"

const getBestPhotoPath = foto => {
  let path = foto.url
  if (foto.formats) {
    if (foto.formats.thumbnail) {
      path = foto.formats.thumbnail.url
    } else if (foto.formats.small) {
      path = foto.formats.small.url
    }
  }
  return path
}

export class PosItem {
  constructor(posItem) {
    this.id = posItem.id
    this.codice = posItem.codice_articolo
    this.unita_conf = posItem.unita_confezione
    this.descrizione_ita = posItem.descrizione_ita
    this.descrizione_eng = posItem.descrizione_eng
    this.published = posItem.published_at !== null
    this.image = posItem.featured_media ? getImageUrl(getBestPhotoPath(posItem.featured_media)) : ""
    this.brand = posItem.brand?.Nome
  }

  extractCsvData() {
    const data = {
      id: this.id,
      codice: this.codice,
      unita_conf: this.unita_conf,
      descrizione_ita: this.descrizione_ita,
      descrizione_eng: this.descrizione_eng,
      published: this.published,
      brand: this.brand,
    }

    return data
  }
}
