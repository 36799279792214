import React from "react"
import { motion } from "framer-motion"
import heroImage from "../../img/catalog/hero.jpg"
import Logo from "../../components/logo"

export const Loading = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={`loader-screen`}
        className="w-screen h-screen bg-cover bg-center bg-black flex items-center justify-center"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, repeatType: "reverse", repeat: Infinity, type: "tween", ease: "linear" }}
          className="text-center w-max text-white font-BodoniBold"
        >
          <Logo className="text-white h-16 flex justify-center mx-auto fill-current" />
        </motion.div>
      </motion.div>
    </>
  )
}
