import React, { useEffect, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import Layout from "../../components/layout"
import Filters from "../../components/filters"
import { GiftBox } from "../../components/gift-box"
import FiltersMobile from "../../components/filters-mobile"
import magnifyingGlassIcon from "../../img/magnifying-glass.svg"

import { productHasPriceDefinedForCurrentProfile } from "../../utils"
import "./style.css"

import hero from "../../img/catalog/hero.jpg"
import heroTipologia from "../../img/catalog/hero-tipologia.jpg"
import heroEdizione from "../../img/catalog/hero-edizione.jpg"
import heroFormato from "../../img/catalog/hero-formato.jpg"
import heroVino from "../../img/catalog/hero-vino.jpg"
import heroTotaleProdotti from "../../img/catalog/hero-totale-prodotti.jpg"
import heroPrezzo from "../../img/catalog/hero-prezzo.jpg"
import { getImageUrl } from "../../utils/getImageUrl"

export default function CatalogoPage() {
  const productState = useSelector(state => state.products)
  const filters = useSelector(state => state.filters)
  const brandFilter = useSelector(state => state.filters.brand)
  const tipologiaConfezioneFilter = useSelector(state => state.filters.tipologiaConfezione)
  const tipologiaVinoFilter = useSelector(state => state.filters.tipologiaVino)
  const edizioneFilter = useSelector(state => state.filters.edizione)
  const formatoBottigliaFilter = useSelector(state => state.filters.formatoBottiglia)
  const vinoFilter = useSelector(state => state.filters.vino)
  const totaleBottiglieFilter = useSelector(state => state.filters.totaleBottiglie)
  const prezzoFilter = useSelector(state => state.filters.prezzo)
  const scalaScontiFilter = useSelector(state => state.filters.scalaSconti)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const heroImage = getHeroImage()
  const history = useHistory()
  const location = useLocation()

  const filtersAreEmpty = useCallback(() => {
    let areFiltersEmpty = true
    Object.keys(filters).forEach(key => {
      if (filters[key].length > 0) {
        areFiltersEmpty = false
      }
    })
    return areFiltersEmpty
  }, [filters])

  useEffect(() => {
    if (filtersAreEmpty()) {
      history.push("/confezioni-regalo/")
    }
  }, [filters, history])

  // Brand
  function matchesFilterBrand(product) {
    // Nessun filtro settato, ritorno true
    if (brandFilter.length === 0) {
      return true
    }

    // C'è un filtro, controllo se c'è un match
    let match = false

    brandFilter.forEach(brand => {
      if (product.brands.find(productBrand => productBrand.Nome === brand)) {
        match = true
      }
    })

    return match
  }

  // Totale bottiglie
  function matchesFilterTotaleBottiglie(product) {
    // Nessun filtro settato, ritorno true
    if (totaleBottiglieFilter.length === 0) {
      return true
    }

    let match = false

    // C'è un filtro, controllo se c'è un match
    switch (product.numero_bottiglie) {
      case 1:
        match = totaleBottiglieFilter.includes("1")
        break
      case 2:
        match = totaleBottiglieFilter.includes("2")
        break
      case 3:
        match = totaleBottiglieFilter.includes("3")
        break
      case 4:
        match = totaleBottiglieFilter.includes("4")
        break
      case 5:
        match = totaleBottiglieFilter.includes("5")
        break
      case 6:
        match = totaleBottiglieFilter.includes("6")
        break

      default:
    }

    return match
  }

  // Tipologia confezione
  function matchesFilterTipologiaConfezione(product) {
    // Nessun filtro settato, ritorno true
    if (tipologiaConfezioneFilter.length === 0) {
      return true
    }

    // C'è un filtro, controllo se c'è un match
    return tipologiaConfezioneFilter.includes(product.tipo)
  }

  // Edizione
  function matchesFilterEdizione(product) {
    // Nessun filtro settato, ritorno true
    if (edizioneFilter.length === 0) {
      return true
    }

    // C'è un filtro, controllo se c'è un match
    let match = false

    edizioneFilter.forEach(edizione => {
      if (product.edizione && edizione.toLowerCase() === product.edizione.toLowerCase()) {
        match = true
      }
    })

    return match
  }

  // Formato bottiglia
  function matchesFilterFormatoBottiglia(product) {
    // Nessun filtro settato, ritorno true
    if (formatoBottigliaFilter.length === 0) {
      return true
    }

    // C'è un filtro, controllo se c'è un match
    let match = false

    formatoBottigliaFilter.forEach(formato => {
      // Se sto cercando un formato di bottiglia (e.g. 75) oppure un oggetto (e.g. Decanter)
      // isNan ritorna false se gli passi una stringa con dentro un numero (e.g. "75")
      const lookingForBottles = !isNaN(formato)

      if (lookingForBottles) {
        const prodottiConQuestoFormato = product.bottiglie.find(bottiglia => {
          return bottiglia.bottle_format && bottiglia.bottle_format.formato === formato
        })

        if (prodottiConQuestoFormato) {
          match = true
        }
      }
    })

    return match
  }

  // Vino
  function matchesFilterVino(product) {
    // Nessun filtro settato, ritorno true
    if (vinoFilter.length === 0) {
      return true
    }

    // C'è un filtro, controllo se c'è un match
    let match = false

    vinoFilter.forEach(vino => {
      if (product.bottiglie.find(bottiglia => bottiglia.label === vino)) {
        match = true
      }
    })

    return match
  }

  // Prezzo
  function matchesFilterPrezzo(product) {
    // Nessun filtro settato, ritorno true
    if (prezzoFilter.length === 0) {
      return true
    }

    // C'è un filtro, controllo se c'è un match
    let match = false

    const prezzoGiusto = product[`prezzo_${auth.listino}`]

    prezzoFilter.forEach(fasciaPrezzo => {
      switch (fasciaPrezzo) {
        case "0-30":
          if (prezzoGiusto < 30) {
            match = true
          }
          break
        case "30-50":
          if (prezzoGiusto >= 30 && prezzoGiusto < 50) {
            match = true
          }
          break
        case "50-80":
          if (prezzoGiusto >= 50 && prezzoGiusto < 80) {
            match = true
          }
          break
        case "80-100":
          if (prezzoGiusto >= 80 && prezzoGiusto < 100) {
            match = true
          }
          break
        case "100-150":
          if (prezzoGiusto >= 100 && prezzoGiusto < 150) {
            match = true
          }
          break
        case "150-200":
          if (prezzoGiusto >= 150 && prezzoGiusto < 200) {
            match = true
          }
          break
        case "200-250":
          if (prezzoGiusto >= 200 && prezzoGiusto < 250) {
            match = true
          }
          break
        case "250-300":
          if (prezzoGiusto >= 250 && prezzoGiusto < 300) {
            match = true
          }
          break
        case "300-oltre":
          if (prezzoGiusto >= 300) {
            match = true
          }
          break
        default:
      }
    })

    return match
  }

  // Scala sconti
  function matchesFilterScalaSconti(product) {
    // Nessun filtro settato, ritorno true
    if (scalaScontiFilter.length === 0) {
      return true
    }

    // C'è un filtro, controllo se c'è un match
    if (scalaScontiFilter.includes("Sì") && product.scala_sconti === "si") {
      return true
    }

    if (scalaScontiFilter.includes("No") && product.scala_sconti === "no") {
      return true
    }

    return false
  }

  // Oggettistica
  function matchesFilterItem(product) {
    // Nessun filtro settato, ritorno true
    if (tipologiaConfezioneFilter.length === 0) {
      return false
    }

    // C'è un filtro, controllo se c'è un match
    let match = false

    tipologiaConfezioneFilter.forEach(tipologia => {
      // Se sto cercando una tipologia di confezione (e.g. "cartotecnica" o "legno") oppure un oggetto (e.g. Decanter)
      const isLookingForItems = tipologia.toLowerCase() !== "legno" && tipologia.toLowerCase() !== "cartotecnica"

      if (isLookingForItems) {
        if (tipologia.toLowerCase() === product.descrizione.toLowerCase()) {
          match = true
        }
      }
    })

    return match
  }

  // Tipologia vino (bianco, rosso...)
  function matchesFilterTipologiaVino(product) {
    // Nessun filtro settato, ritorno true
    if (tipologiaVinoFilter.length === 0) {
      return true
    }

    // C'è un filtro, controllo se c'è un match
    let match = false

    tipologiaVinoFilter.forEach(tipologiaVino => {
      if (product.bottiglie.find(bottiglia => bottiglia.tipologia_vino === tipologiaVino)) {
        match = true
      }
    })

    return match
  }

  function isUnicoFiltro(filtro) {
    const allFilters = {
      brand: brandFilter,
      tipologiaConfezione: tipologiaConfezioneFilter,
      edizione: edizioneFilter,
      formatoBottiglia: formatoBottigliaFilter,
      vino: vinoFilter,
      totaleBottiglie: totaleBottiglieFilter,
      prezzo: prezzoFilter,
      scalaSconti: scalaScontiFilter,
      tipologiaVino: tipologiaVinoFilter,
    }

    let isUnico = allFilters[filtro].length > 0

    Object.keys(allFilters)
      .filter(key => key !== filtro)
      .forEach(key => {
        isUnico = isUnico && allFilters[key].length === 0
      })

    return isUnico
  }

  function getCopertinaBrand() {
    // Se ci sono più di un brand selezionato, ritorno copertina generica
    if (brandFilter.length > 1) {
      return heroTipologia
    }

    const brand = productState.allBrands.find(node => node.Nome === brandFilter[0])

    if (!brand) {
      return heroTipologia
    }

    return getImageUrl(brand.copertina.url)
  }

  function getHeroImage() {
    // Brand
    if (isUnicoFiltro("brand")) {
      return getCopertinaBrand()
    }

    // Tipologia
    if (isUnicoFiltro("tipologiaConfezione")) {
      return heroTipologia
    }

    // Edizione
    if (isUnicoFiltro("edizione")) {
      return heroEdizione
    }

    // Formato
    if (isUnicoFiltro("formatoBottiglia")) {
      return heroFormato
    }

    // Vino
    if (isUnicoFiltro("vino")) {
      return heroVino
    }

    // Totale prodotti
    if (isUnicoFiltro("totaleBottiglie")) {
      return heroTotaleProdotti
    }

    // Prezzo
    if (isUnicoFiltro("prezzo")) {
      return heroPrezzo
    }

    // Scala sconti
    if (isUnicoFiltro("scalaSconti")) {
      return hero
    }

    return hero
  }

  function openMenu() {
    dispatch({ type: "MENU_OPEN" })
  }

  if (!auth.isLoggedIn && location.pathname !== `/login`) {
    history.push("/login")
    return null
  }

  return (
    <>
      {filtersAreEmpty() == false && (
        <Layout>
          <div
            className="Catalog__Hero relative overflow-y-hidden xl:overflow-y-visible"
            style={{ backgroundImage: `url(${heroImage})` }}
          >
            <div className="Hero__Text [ absolute inset-0 z-10 ] [ flex flex-col ]">
              <div
                className="
              flex flex-col items-center justify-center flex-1
              text-white
              px-8
            "
              >
                <div className="Home__Title mb-12 font-BodoniBook text-4xl md:text-6xl text-center leading-none text-white">
                  Confezioni Regalo
                </div>
                <input
                  type="text"
                  placeholder="Cerca"
                  className="Home__Cerca mb-8 p-4 font-LatoRegular text-lg"
                  style={{ backgroundImage: `url(${magnifyingGlassIcon})` }}
                  onClick={openMenu}
                />
              </div>

              <Filters />
            </div>
          </div>

          <FiltersMobile />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {productState.allProducts
              .filter(node => {
                // Se il prezzo per questo profilo è NULL non dobbiamo mostrarlo
                const isVisibleToThisProfile = productHasPriceDefinedForCurrentProfile(node, auth.listino)
                // Brand
                const brandFilterOk = matchesFilterBrand(node)
                // Tipologia confezione
                const tipologiaConfezioneFilterOk = matchesFilterTipologiaConfezione(node)
                // Edizione
                const edizioneFilterOk = matchesFilterEdizione(node)
                // Formato bottiglia
                const formatoBottigliaFilterOk = matchesFilterFormatoBottiglia(node)
                // Vino
                const vinoFilterOk = matchesFilterVino(node)
                // Num bottiglie
                const numBottiglieFilterOk = matchesFilterTotaleBottiglie(node)
                // Prezzo
                const prezzoFilterOk = matchesFilterPrezzo(node)
                // Scala sconti
                const scalaScontiFilterOk = matchesFilterScalaSconti(node)
                // Scala sconti
                const tipologiaVinoFilterOk = matchesFilterTipologiaVino(node)

                return (
                  isVisibleToThisProfile &&
                  numBottiglieFilterOk &&
                  brandFilterOk &&
                  tipologiaConfezioneFilterOk &&
                  vinoFilterOk &&
                  formatoBottigliaFilterOk &&
                  edizioneFilterOk &&
                  prezzoFilterOk &&
                  scalaScontiFilterOk &&
                  tipologiaVinoFilterOk
                )
              })
              .map(node => (
                <GiftBox key={node.id} product={node} striped />
              ))}

            {productState.allOggettistica
              .filter(node => matchesFilterItem(node))
              .map(node => (
                <GiftBox key={node.id} product={node} striped />
              ))}
          </div>
        </Layout>
      )}
    </>
  )
}
