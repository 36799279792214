import { AnimatePresence, motion } from "framer-motion"
import React, { useState, useEffect } from "react"
import Scrollbars from "react-custom-scrollbars"
import { useDispatch, useSelector } from "react-redux"
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router"
import HeaderVini from "../../components/header-vini"
import ListItem from "../../components/list-item"
import Griglia from "../tenute/components/griglia"
import resetIcon from "../../img/reset-icon.svg"
import VinoSingolo from "../../components/vino/vino-singolo"
import VinoGriglia from "../../components/vino/vino-griglia"
import { useWindowWidth } from "@react-hook/window-size"
import { WishlistPanel } from "../../components/wishlistPanel"

export default function Vini() {
  const location = useLocation()
  const filteredWines = useSelector(state => state.wines.filteredWines)
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const tipoVista = useSelector(state => state.tenute.vista)
  const vistaProdottiTenuteVini = useSelector(state => state.tenute.vistaProdottiTenuteVini)
  const locale = useSelector(state => state.tenute.locale)
  const windowWidth = useWindowWidth()
  const winesAvailableForThisLocale = filteredWines.filter(wine => {
    switch (locale) {
      case "it":
        return !!wine.testo_italiano
      case "en":
        return !!wine.testo_inglese
      case "de":
        return !!wine.testo_tedesco
      case "ch":
        return !!wine.testo_cinese //cinese
      default:
        return true
    }
  })

  const [selectedWine, setSelectedWine] = useState(filteredWines[0])
  const [showPopupWishlist, setShowPopupWishlist] = useState({
    showPopup: false,
    wine: null,
    popupStyle: {},
    popupTarget: null,
  })

  useEffect(() => {
    setShowPopupWishlist({
      ...showPopupWishlist,
      showPopup: false,
      wine: null,
    })
  }, [filteredWines, tipoVista])

  function previewWine(wine) {
    if (windowWidth < 768 || wine.id === selectedWine.id) {
      return
    }

    setSelectedWine(wine)
    history.push(`${path}/${wine.id}`)
  }

  // function calcPopupPosition(target) {
  //   let rect = target.getBoundingClientRect()
  //   let gridRect = document.getElementById("lista")
  //   let scrollLeft = gridRect.scrollLeft
  //   let definedWidth = windowWidth < 1024 ? (windowWidth < 768 ? 0.41 : 0.26) : 0.1766
  //   return {
  //     top: rect.top + 32 > windowHeight - 200 ? rect.top - 128 : rect.top + 32,
  //     left: rect.left,
  //   }
  // }

  function togglePopupWishlis(e, wine, collapse = false) {
    // const popupPosition = calcPopupPosition(e.target)
    setShowPopupWishlist({
      showPopup: showPopupWishlist.wine
        ? showPopupWishlist.wine.id === wine.id
          ? !showPopupWishlist.showPopup
          : true
        : true,
      wine: showPopupWishlist.wine ? (showPopupWishlist.wine.id === wine.id ? null : wine) : wine,
      // popupStyle: {
      //   position: "absolute",
      //   top: popupPosition.top,
      //   left: popupPosition.left,
      // },
      popupTarget: e.target,
    })
  }

  return (
    <motion.div className="absolute inset-0 bg-tenute text-black h-screen z-0 overflow-hidden">
      {tipoVista === "lista" && (
        <div className="Tenute__BrandList overflow-hidden absolute left-0 bottom-0 lg:pb-8">
          <Scrollbars
            style={{ width: "100%", height: "100%" }}
            autoHide
            autoHideTimeout={350}
            autoHideDuration={200}
            renderTrackHorizontal={() => <div></div>}
            renderThumbHorizontal={() => <div></div>}
          >
            <ul id="lista" className="Tenute__ColLeft__Scroll">
              {winesAvailableForThisLocale.map(wine => (
                <ListItem
                  key={wine.id}
                  item={wine}
                  togglePopupWishlis={togglePopupWishlis}
                  onHover={() => previewWine(wine)}
                  onClick={e => {
                    setShowPopupWishlist({ ...showPopupWishlist, wine: null, showPopup: false })
                    history.push(`${url}/${wine.id}/details`)
                  }}
                  title={wine.Nome}
                  subtitle={wine.Denominazione}
                  isSelected={selectedWine?.id === wine.id}
                  focused={showPopupWishlist.wine?.id}
                />
              ))}
            </ul>
          </Scrollbars>
        </div>
      )}

      {tipoVista === "griglia" && (
        <>
          <HeaderVini showFilters showSwitchGrigliaLista />

          {vistaProdottiTenuteVini === "vini" && <Griglia contenuto="vini" />}
          {winesAvailableForThisLocale.length === 0 && <NoResultsLista />}

          <Switch key={path} location={location}>
            <Route path={`${path}/:idVino/details`}>
              <HeaderVini showFilters />
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                {windowWidth >= 768 && <VinoGriglia tipoVista={tipoVista} isOpen showAddToWishList />}
                {windowWidth < 768 && <VinoSingolo tipoVista={tipoVista} isOpen />}
              </motion.div>
            </Route>
          </Switch>
        </>
      )}

      {tipoVista === "lista" && (
        <AnimatePresence>
          <Switch key={path} location={location}>
            <Route exact path={`${path}/`}>
              <HeaderVini showFilters showSwitchGrigliaLista />
              {winesAvailableForThisLocale.length > 0 && windowWidth >= 768 && (
                <VinoSingolo idVinoProp={winesAvailableForThisLocale[0].id} tipoVista={tipoVista} />
              )}
              {winesAvailableForThisLocale.length === 0 && <NoResultsLista />}
            </Route>
            <Route exact path={`${path}/:idVino`}>
              <HeaderVini showFilters showSwitchGrigliaLista />
              <VinoSingolo tipoVista={tipoVista} />
            </Route>
            <Route path={`${path}/:idVino/details`}>
              <HeaderVini showFilters />
              <VinoSingolo tipoVista={tipoVista} isOpen />
            </Route>
          </Switch>
        </AnimatePresence>
      )}
      {/* {showPopupWishlist.showPopup && ( 
              <WishlistPopup 
                lightContrast
                showPopup={showPopupWishlist.showPopup}
                wine={showPopupWishlist.wine} 
                popupStyle={showPopupWishlist.popupStyle}
                resetPopup={e => setShowPopupWishlist({...showPopupWishlist, wine:null, showPopup: false, popupStyle : {}})}
              />
      )} */}
      {showPopupWishlist.wine && (
        <WishlistPanel
          colorName="white"
          e={showPopupWishlist.popupTarget}
          wine={showPopupWishlist.wine}
          reference={document.getElementById("lista")}
          referenceSize="lista"
          resetPopup={e => setShowPopupWishlist({ wine: null, showPopupWishlist: false, popupTarget: null })}
        />
      )}
    </motion.div>
  )
}
function NoResultsLista() {
  const dispatch = useDispatch()

  function resetFiltri() {
    dispatch({ type: "FILTERS_RESET" })
  }

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
      <div className="font-BodoniBook text-center" style={{ fontSize: "50px", maxWidth: "800px" }}>
        La selezione non ha prodotto risultati, resetta i filtri per una nuova ricerca
      </div>
      <div
        className="flex items-center mt-4 font-LatoBold text-gray uppercase tracking-2 text-sm"
        onClick={resetFiltri}
      >
        <img className="mr-4" src={resetIcon} alt="" />
        Reset filtri
      </div>
    </div>
  )
}
