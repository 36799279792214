const initialState = false

export default function menuViniReducer(state = initialState, action) {
  switch (action.type) {
    case "VINI_MENU_OPEN":
      document.body.style.overflow = "hidden"
      return true
    case "VINI_MENU_CLOSE":
      document.body.style.overflow = "auto"
      return false
    case "VINI_GO_HOME":
      document.body.style.overflow = "auto"
      return state
    default:
      return state
  }
}
