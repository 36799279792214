import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion"
import { FormattedMessage } from "react-intl"

import Logo from "../logo"
import WishlistIcon from "../wishlist-icon"
import magnifyingGlass from "../../img/magnifying-glass.svg"
import { tenuteActionsTypes } from "../../redux/tenute-reducer"
import FiltersProdotti from "./filters"

import "./style.css"

export default function HeaderViniDesktop({ showFilters }) {
  const dispatch = useDispatch()
  const wishlist = useSelector(state => state.winesWishlist)
  const vistaTenute = useSelector(state => state.tenute.vista)
  const vistaProdottiTenuteVini = useSelector(state => state.tenute.vistaProdottiTenuteVini)
  const filters = useSelector(state => state.wines.filters)
  const [areFiltersVisibile, setAreFiltersVisible] = useState()
  const history = useHistory()
  const location = useLocation()
  const showFiltersIcon = !location.pathname.includes("details")
  const anyFiltersSelected = [...filters.formato, ...filters.tenuta, ...filters.vino].length > 0

  function switchVistaTenute() {
    history.push("/prodotti")
    dispatch({ type: tenuteActionsTypes.TENUTE_CHANGE_VISTA })
  }

  function changeVistaProdotti(vistaSelezionata) {
    if (vistaSelezionata !== vistaProdottiTenuteVini) {
      dispatch({ type: tenuteActionsTypes.PRODOTTI_VISTA_CHANGE, payload: vistaSelezionata })
    }

    history.push("/prodotti")
  }

  function changeFilterDistribuzione(payload) {
    dispatch({ type: "CHANGE_FILTER_DISTRIBUZIONE", payload })
  }

  function toggleFilters() {
    setAreFiltersVisible(areVisible => !areVisible)
  }

  return (
    <motion.header className="HeaderVini flex items-center justify-between bg-black text-white px-8 fixed top-0 left-0 w-screen z-30">
      {/* Left (Logo + filtri + grid) */}
      <div className="grid grid-cols-5 gap-10 items-center">
        <Link to="/">
          <Logo className="h-12 text-white fill-current" />
        </Link>

        {showFilters && (
          <>
            <div
              className={`uppercase text-xs tracking-widest text-center cursor-pointer relative ${
                vistaProdottiTenuteVini === "tenute" ? "text-brown" : "text-tenute"
              }`}
              onClick={() => changeVistaProdotti("tenute")}
            >
              <FormattedMessage id="tenute.header.tenute" />
              {vistaProdottiTenuteVini === "tenute" && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg width={53} height={39} viewBox="0 0 53 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity={0.3} stroke="#F1F0EB" d="M.705 38.596l52-38" />
                  </svg>
                </div>
              )}
            </div>
            <div
              className={`uppercase text-xs tracking-widest text-center cursor-pointer relative ${
                vistaProdottiTenuteVini === "vini" ? "text-brown" : "text-tenute"
              }`}
              onClick={() => changeVistaProdotti("vini")}
            >
              <FormattedMessage id="tenute.header.vini" />
              {vistaProdottiTenuteVini === "vini" && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg width={53} height={39} viewBox="0 0 53 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity={0.3} stroke="#F1F0EB" d="M.705 38.596l52-38" />
                  </svg>
                </div>
              )}
            </div>

            {/* Switch lista / griglia */}
            {
              <div className="flex justify-end cursor-pointer" onClick={switchVistaTenute}>
                {vistaTenute === "griglia" && (
                  <svg width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#787875" d="M0 12h20v1.5H0zM0 6h20v1.5H0zM0 0h20v1.5H0z" />
                  </svg>
                )}

                {vistaTenute === "lista" && (
                  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      stroke="#F1F0EB"
                      strokeOpacity={0.5}
                      strokeWidth={1.5}
                      d="M.75.75h6.735v6.735H.75zM.75 12.515h6.735v6.735H.75zM12.515.75h6.735v6.735h-6.735zM12.515 12.515h6.735v6.735h-6.735z"
                    />
                  </svg>
                )}
              </div>
            }
          </>
        )}
      </div>

      {/* Right (search + wishlist + vini) */}
      <div className="flex items-center">
        {/* Vini */}

        {showFilters && (
          <div className="mr-32 flex items-center">
            <div
              className={`uppercase text-xs tracking-widest text-center ${
                filters.distribuzione === "Antinori" ? "text-tenute" : "text-gray cursor-pointer"
              }`}
              onClick={() => changeFilterDistribuzione("Antinori")}
            >
              <FormattedMessage id="tenute.landing.vini_antinori" />
            </div>
            <div className="mx-4">I</div>
            <div
              className={`uppercase text-xs tracking-widest text-center ${
                filters.distribuzione === "Distribuiti" ? "text-tenute" : "text-gray cursor-pointer"
              }`}
              onClick={() => changeFilterDistribuzione("Distribuiti")}
            >
              <FormattedMessage id="tenute.landing.vini_in_distribuzione" />
            </div>
          </div>
        )}

        {/* Icons */}
        <div className={`grid grid-cols-${showFiltersIcon ? "3" : "2"} gap-5 justify-items-center items-center`}>
          {/* Icona filtri */}

          {showFiltersIcon && (
            <div
              className={`HeaderVini__FilterIcon relative cursor-pointer ${anyFiltersSelected ? "with-badge" : ""}`}
              onClick={toggleFilters}
            >
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#prefix__clip0)">
                  <path
                    d="M1.66 4.086l7.906 9.208c.118.136.184.31.185.49v5.592a.372.372 0 00.253.356l3.757 1.25a.372.372 0 00.49-.355v-6.844a.754.754 0 01.187-.49l7.903-9.207a.656.656 0 00-.496-1.084H2.16a.656.656 0 00-.5 1.084z"
                    stroke="#FFFFFA"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="prefix__clip0">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          )}

          {/* Icona search */}
          <div className="cursor-pointer" style={{ width: "21px" }}>
            <img src={magnifyingGlass} onClick={() => dispatch({ type: "VINI_MENU_OPEN" })} />
          </div>

          {/* Icona wishlist */}
          <Link to="/wishlist-prodotti">
            <WishlistIcon nonVuota={wishlist && wishlist.length > 0} className="inline" />
          </Link>
        </div>
      </div>

      {/* Pannello filtri */}

      <AnimatePresence>
        {areFiltersVisibile && <FiltersProdotti onClose={() => setAreFiltersVisible(false)} />}
      </AnimatePresence>
    </motion.header>
  )
}
