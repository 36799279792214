import React from "react"

export default function Checkbox({
  changeState = () =>{},
  selected
}) {
  return (
    <div
      className="absolute top-0 mt-4 ml-4 h-6 w-6 bg-white"
      onClick={e => {
        e.preventDefault()
        changeState()
      }}
    >
      {selected && <div className="relative h-4 w-4 mx-auto my-1 bg-brown text-brown"></div>}
    </div>
  )
}
