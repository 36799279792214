let initialState = []

const localStorageWishlist = localStorage.getItem("wine-wishlist")

if (localStorageWishlist) {
  try {
    initialState = JSON.parse(localStorageWishlist)
  } catch (error) {
    initialState = []
  }
}

export default function wineWishlistReducer(state = initialState, action) {
  let newState = initialState

  const localStorageWishlist = localStorage.getItem("wine-wishlist")

  if (localStorageWishlist) {
    newState = JSON.parse(localStorageWishlist)
  }

  let index

  switch (action.type) {
    case "WINES_WISHLIST_REPLACE_OLD":
      newState = [action.payload]
      break
    case "LOADED_WISHLIST":
      newState = state
      break
    case "WINES_WISHLIST_CREATE":
      newState = [...state, action.payload]
      localStorage.setItem("wine-wishlist", JSON.stringify([...state, action.payload]))
      break
    case "WINES_WISHLIST_DELETE":
      newState = state.filter(wishlist => wishlist.id !== action.payload.id)
      break
    case "WINES_WISHLIST_ADD":
      index = state.map(wishlist => wishlist.id).indexOf(action.payload.id)
      newState = [...state]
      newState[index] = action.payload
      newState = [...newState]
      break
    case "WINES_WISHLIST_REMOVE":
      newState = [...state]
      break
    case "WINES_WISHLIST_RESET":
      newState = []
      break
    case "WINES_WISHLIST_UPDATE_ALBUM":
      index = state.map(wishlist => wishlist.id).indexOf(action.payload.album.id)
      newState[index] = action.payload.album
      break
    case "@@INIT":
      break
    default:
      break
  }

  if (typeof window !== "undefined" && typeof newState !== "undefined" && newState !== initialState) {
    localStorage.setItem("wine-wishlist", JSON.stringify(newState))
  }

  return newState
}
