import React, { useState, useMemo } from "react"
import ChevronIcon from "../chevron-icon"
import "./style.css"

export function FilterItem({ name, options, onChange, selected, force, isMobile = false, sortOptions }) {
  const [visible, setVisible] = useState(false)
  const memoizedSortedOptions = useMemo(() => {
    if (sortOptions) {
      return options.sort()
    } else {
      return options
    }
  }, [options])

  function handleClick(option) {
    // Se ho cliccato "Vedi tutti" resetto i filtri
    if (option === "all") {
      return onChange([])
    }

    // Se force è a true (solo in home page) ritorno solo l'opzione cliccata
    if (force) {
      return onChange([option])
    }

    // Se l'opzione cliccata è già selezionata, la rimuovo, altrimenti la aggiungo
    const newValue = selected && selected.includes(option) ? selected.filter(o => o !== option) : [...selected, option]

    onChange(newValue)
  }

  function handleClickTitle() {
    if (isMobile) {
      setVisible(!visible)
    }
  }

  return (
    <div
      className="flex items-center justify-center py-8 relative cursor-pointer"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onClick={handleClickTitle}
    >
      <span className="font-LatoBold text-11px tracking-2 uppercase">
        {name}
        {selected.length > 0 && <span> ({selected.length})</span>}
      </span>
      <span className="ml-2">
        <ChevronIcon />
      </span>

      {visible && (
        <div
          className={`Filters__Panel  ${
            name.toLowerCase() === "vino" ? "Filters__Panel--vino" : ""
          } absolute top-0 w-full bg-white px-4 pr-12 select-none`}
        >
          <div className="flex items-center flex-1 justify-center py-8 font-LatoBold text-11px tracking-2 text-black">
            <span className="font-LatoBold text-11px tracking-2 uppercase">{name}</span>
            <span className="ml-2 transform rotate-180">
              <ChevronIcon />
            </span>
          </div>

          <div className="Filter__Options text-black font-BodoniBook text-20px leading-none pb-8">
            {/* {selected.length === 0 && (
              <div className="flex items-center" onClick={() => handleClick("all")}>
                <div
                  className={`checkbox flex-shrink-0 mr-4 ${
                    selected.length === 0 ? "selected bg-brown" : "border border-gray"
                  }`}
                ></div>
                <div className="py-2">Vedi tutti</div>
              </div>
            )} */}

            {selected.length > 0 && (
              <div
                className="py-2 px-8 border border-gray text-gray text-center uppercase text-11px font-LatoBold tracking-2 mb-4 hover:bg-gray hover:text-white"
                onClick={() => handleClick("all")}
              >
                Reset
              </div>
            )}

            {memoizedSortedOptions.map((option, k) => {
              if (option) {
                // Se c'è una virgola si parla del nome di un vino (etichetta, denominazione) e dobbiamo splittarlo per dare 2 stili diversi
                const array = option.split(",")
                let label = array[0]

                // Se c'è un underscore stiamo parlando di numero bottiglie: 1_3, 4_6 e dobbiamo rimpiazzarlo con "da 1 a 3"...
                if (option.includes("_")) {
                  const numOggettiArray = option.split("_")
                  label = `Da ${numOggettiArray[0]} a ${numOggettiArray[1]}`
                }

                return (
                  <div key={k} className="flex items-center" onClick={() => handleClick(option)}>
                    <div
                      className={`checkbox flex-shrink-0 mr-4 hover:bg-gray ${
                        selected.includes(option) ? "selected bg-brown" : "border border-gray"
                      }`}
                    ></div>
                    <div className={`py-2 hover:text-gray `}>
                      {name.toLowerCase() === "confezionamento" && "Da "}
                      {!["brand", "vino"].includes(name.toLowerCase())
                        ? label.substring(0, 1).toUpperCase() + label.substring(1, label.length).toLowerCase()
                        : label}

                      {array.length > 1 && <div className="mt-1 italic text-gray text-sm">{array[1]}</div>}
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterItem
