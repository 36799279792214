export function productHasPriceDefinedForCurrentProfile(product, profile) {
  if (profile === "consultazione") {
    return true
  }

  return !!product[`prezzo_${profile}`]
}

export function brandHasAtLeaseOneProductWithPriceDefinedForCurrentProfile(allProducts, brand, profile) {
  if (profile === "consultazione") {
    return true
  }
  const boxesForThisBrand = allProducts.filter(box => box.brands.find(boxBrand => boxBrand.Nome === brand.Nome))
  return boxesForThisBrand.some(box => productHasPriceDefinedForCurrentProfile(box, profile))
}

export function sortBrandsAlphabetically(a, b) {
  var nameA = a.Nome.toUpperCase()
  var nameB = b.Nome.toUpperCase()

  if (nameA < nameB) {
    return -1
  }

  if (nameA > nameB) {
    return 1
  }

  return 0
}

export const isAnyFilterSelected = obj => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return true
      }
    }
  }
  return false
}
