import { useEffect } from "react"

export const useOnClickOutside = (elementRef, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        onClickOutside()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [onClickOutside, elementRef])
}
