import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size"

import { getImageUrl } from "../../utils/getImageUrl"
import getLinkCorretto from "../../utils/getLinkCorretto"
import Chevron from "../chevron"
import Vino from "../vino"

export default function Tenuta({ isOpen, tipoVista, nomeTenutaProp }) {
  let { path } = useRouteMatch()
  const windowWidth = useWindowWidth()
  const windowHeight = useWindowHeight()
  const params = useParams()
  const nomeTenuta = nomeTenutaProp || params.nomeTenuta
  const location = useLocation()
  const brands = useSelector(state => state.wines.allBrands)
  const locale = useSelector(state => state.tenute.locale)
  const tenuta = brands.find(b => b.Nome === nomeTenuta)
  const [isClosing, setIsClosing] = useState()
  const [textMaxHeight, setTextMaxHeight] = useState("auto")
  const history = useHistory()
  const nome = useRef(null)
  const testo = useRef(null)
  const footer = useRef(null)
  const testi = { it: "testo_italiano", en: "testo_inglese", de: "testo_tedesco", ch: "testo_cinese" }

  const winesAvailableForThisLocale = tenuta.wines.filter(wine => {
    switch (locale) {
      case "it":
        return !!wine.testo_italiano
      case "en":
        return !!wine.testo_inglese
      case "de":
        return !!wine.testo_tedesco
      case "ch":
        return !!wine.testo_cinese //CINESE
      default:
        return true
    }
  })

  // Regolo l'altezza del testo in base al titolo, che può avere altezza variabile
  useEffect(() => {
    if (!testo.current || !footer.current) {
      return
    }

    const rectTesto = testo.current.getBoundingClientRect()
    const rectFooter = footer.current.getBoundingClientRect()
    let maxHeight = `${windowHeight - rectTesto.top - rectFooter.height}px`

    if (windowWidth < 1024) {
      maxHeight = "auto"
    }

    setTextMaxHeight(maxHeight)
  }, [tenuta, testo, footer, isOpen, windowWidth, windowHeight])

  if (!tenuta) {
    history.push("/prodotti")
    return null
  }

  function fadeOutDiretto() {
    setIsClosing(true)
  }

  function Container(props) {
    if (windowWidth < 768) {
      return <div {...props}>{props.children}</div>
    }

    return <motion.div {...props}>{props.children}</motion.div>
  }

  return (
    <Container
      className="Tenuta fixed lg:absolute inset-0 w-screen z-10 lg:flex flex-row bg-tenute overflow-y-auto"
      animate={{ left: isOpen ? 0 : "33vw", transition: { type: "tween", duration: 0.75 }, opacity: isClosing ? 0 : 1 }}
      initial={{ left: isOpen ? 0 : "33vw", opacity: 1 }}
      exit={{ opacity: tipoVista === "griglia" ? 0 : 1 }}
      key={`${tenuta.id}-tenuta`}
    >
      <div className="Tenuta__Container relative">
        <motion.div
          className={`lg:absolute top-0 right-0 bottom-0 w-full ${isOpen ? "" : "cursor-pointer"} overflow-hidden`}
          initial={{ width: windowWidth < 768 ? "100%" : 0 }}
          animate={{ width: "100%", transition: { type: "tween", duration: 1 } }}
          onClick={() => {
            if (!isOpen) {
              history.push(`/prodotti/${tenuta.Nome}/details`)
            }
          }}
          onWheel={e => {
            if (!isOpen) {
              return
            }

            // Scroll down
            if (e.deltaY > 0) {
              history.push(`/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[0].id}`)
            }
          }}
        >
          <motion.img
            initial={{ scale: 1.2 }}
            animate={{ scale: 1, transition: { type: "tween", duration: 1 } }}
            exit={{ opacity: 0, transition: { type: "tween", duration: 0.5, delay: 0.5 } }}
            className={`Tenuta__Copertina object-cover ${isOpen ? "object-right" : "object-center"} w-full h-full`}
            src={getImageUrl(tenuta.copertina.url, 1300)}
            alt="Copertina"
            key="copertina"
          />
        </motion.div>

        {!isOpen && tenuta.mostra_logo_ocm && (
          <motion.img
            className="absolute block right-0 top-1/2 transform -translate-y-1/2 px-2 lg:px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5, type: "tween", delay: 1.5 } }}
            src={`${process.env.PUBLIC_URL}/logo-ocm.svg`}
            alt="ocm logo"
            style={{ maxHeight: "70%" }}
          />
        )}

        {isOpen && tenuta.mostra_logo_ocm && (
          <motion.img
            className="absolute block right-0 top-1/2 transform -translate-y-1/2 px-2 lg:px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5, type: "tween", delay: 1.5 } }}
            src={`${process.env.PUBLIC_URL}/logo-ocm.svg`}
            alt="ocm logo"
            style={{ maxHeight: "70%" }}
          />
        )}

        {isOpen && windowWidth >= 768 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                repeat: Infinity,
                repeatType: "reverse",
                duration: 1,
              },
            }}
            className="Tenuta__ArrowContainer absolute left-0 bottom-0"
          >
            <Link
              to={`/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[0].id}`}
              className="absolute bottom-0 left-1/2 transform -translate-x-1/2 pb-8 flex flex-col items-center"
            >
              <svg width={52} height={28} viewBox="0 0 52 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1l25 25L51 1" stroke="#fff" strokeWidth={2} />
              </svg>

              <div className="mt-4 text-center font-LatoBold text-xs text-white uppercase tracking-2">Scroll down</div>
            </Link>
          </motion.div>
        )}

        {/* Logo tenuta */}
        <motion.img
          onClick={() => {
            if (!isOpen) {
              history.push(`/prodotti/${tenuta.Nome}/details`)
            }
          }}
          onWheel={e => {
            if (!isOpen) {
              return
            }

            // Scroll down
            if (e.deltaY > 0) {
              history.push(`/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[0].id}`)
            }
          }}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { type: "tween", duration: 0.5, delay: 1 },
          }}
          exit={{ opacity: 0, transition: { delay: 0.5, type: "tween", duration: 0.5 } }}
          className={`Tenuta__Logo absolute transform top-1/2 -translate-y-1/2 -translate-x-1/2 ${
            !isOpen ? "cursor-pointer" : ""
          }`}
          src={getImageUrl(tenuta.logo_bianco ? tenuta.logo_bianco.url : tenuta.Logo.url)}
        />
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            exit={{ opacity: 1, transition: { delay: 1 } }}
            className="Tenuta__Content overflow-hidden bg-tenute text-black bottom-0"
          >
            {/* Nome */}
            <motion.div
              className="Tenuta__Nome font-BodoniBook text-4xl lg:text-5xl leading-none mt-2"
              initial={{ opacity: 0, y: 25 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.5, type: "tween", duration: 0.6 } }}
              exit={{ opacity: 0 }}
              ref={nome}
            >
              {tenuta.Nome}
            </motion.div>

            {/* Testo */}
            <motion.div
              className="Tenuta__Testo font-BodoniBook mt-8 lg:mt-12 text-xl leading-relaxed overflow-y-auto pr-4"
              initial={{ opacity: 0, y: 25 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.75, type: "tween", duration: 0.6 } }}
              exit={{ opacity: 0 }}
              style={{ maxHeight: textMaxHeight }}
              ref={testo}
            >
              {tenuta[testi[locale]]}
            </motion.div>

            {/* Footer */}
            <motion.div
              initial={{ opacity: 0, y: 25 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 1.25, type: "tween", duration: 0.6 } }}
              exit={{ opacity: 0 }}
              className="lg:absolute bottom-0 left-0 right-0 p-4 lg:px-12 lg:py-8"
              ref={footer}
            >
              <div className="border border-gray border-t mb-6"></div>
              <div className="flex items-center justify-between">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-BodoniBook text-xl text-brown"
                  href={getLinkCorretto(tenuta.link_sito)}
                >
                  <FormattedMessage id="tenute.prodotti.visita_il_sito" />
                </a>

                <div className="Tenuta__Pagination grid items-center grid-cols-3">
                  <div className="flex justify-center cursor-pointer p-2">
                    <Chevron disabled />
                  </div>

                  <div className="flex justify-center font-LatoBold text-sm">
                    1 / {winesAvailableForThisLocale.length + 1}
                  </div>

                  <Link
                    className="flex justify-center transform rotate-180 cursor-pointer p-2"
                    to={`/prodotti/${tenuta.Nome}/details/${winesAvailableForThisLocale[0].id}`}
                  >
                    <Chevron />
                  </Link>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <Switch key={location.pathname} location={location}>
          <Route path={`${path}/:idVino`}>
            <Vino tenuta={tenuta} tipoVista={tipoVista} onClose={fadeOutDiretto} />
          </Route>
        </Switch>
      </AnimatePresence>
    </Container>
  )
}
