import { motion } from "framer-motion"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { tenuteActionsTypes } from "../../redux/tenute-reducer"
import { getImageUrl } from "../../utils/getImageUrl"
import getLinkCorretto from "../../utils/getLinkCorretto"
import Wine from "../wine"

import { WishlistPanel } from "../wishlistPanel"
export default function TenutaGriglia() {
  const params = useParams()
  const nomeTenuta = params.nomeTenuta
  const brands = useSelector(state => state.wines.allBrands)
  const tenuta = brands.find(b => b.Nome === nomeTenuta)
  const locale = useSelector(state => state.tenute.locale)
  const testi = { it: "testo_italiano", en: "testo_inglese", de: "testo_tedesco", ch: "testo_cinese" }

  const dispatch = useDispatch()
  const [showPopupWishlist, setShowPopupWishlist] = useState({
    showPopup: false,
    wine: null,
    popupStyle: {},
    popupTarget: null,
  })

  // function calcPopupPosition(target) {
  //   let rect = target.getBoundingClientRect()
  //   let gridRect = document.getElementById("griglia")
  //   let scrollLeft = gridRect.scrollLeft
  //   let scrollTop = gridRect.scrollTop
  //   let definedWidth = windowWidth < 1024 ? (windowWidth < 768 ? 0.41 : 0.26) : 0.1766
  //   let preTest = rect.left + scrollLeft - windowWidth * definedWidth
  //   return {
  //     top: rect.top + scrollTop - gridRect.getBoundingClientRect().top + 32,
  //     left: preTest < 0 ? preTest - scrollLeft + 42 : preTest - scrollLeft + 28,
  //   }
  // }

  function togglePopupWishlis(e, wine, collapse = false) {
    // const popupPosition = calcPopupPosition(e.target)
    setShowPopupWishlist({
      showPopup: showPopupWishlist.wine
        ? showPopupWishlist.wine.id === wine.id
          ? !showPopupWishlist.showPopup
          : true
        : true,
      wine: showPopupWishlist.wine ? (showPopupWishlist.wine.id === wine.id ? null : wine) : wine,
      // popupStyle: {
      //   position: "absolute",
      //   top: popupPosition.top,
      //   left: popupPosition.left,
      // },
      popupTarget: e.target,
    })
  }

  return (
    <div id="griglia" className="absolute inset-0 bg-tenute z-10 overflow-y-auto">
      <motion.div
        className="pb-16"
        initial={{ left: 0, opacity: 1 }}
        animate={{ left: 0, transition: { type: "tween", duration: 0.75 }, opacity: 1 }}
        exit={{ opacity: 0 }}
        key={`${tenuta.id}-tenuta`}
      >
        <div className="TenutaGriglia w-full text-black px-12">
          {/* Foto */}
          <div style={{ gridArea: "foto" }}>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { type: "tween", duration: 1 } }}
              className={`object-cover w-full h-full`}
              src={getImageUrl(tenuta.copertina.url, 1300)}
              alt="Copertina"
            />
          </div>

          {/* Sidebar */}
          <div style={{ gridArea: "sidebar" }}>
            <motion.div className="bg-white" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <motion.h2
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5 } }}
                className="pt-10 px-12 font-BodoniBook text-5xl"
              >
                {tenuta.Nome}
              </motion.h2>
              <div className="font-BodoniBook mt-12 text-xl leading-relaxed">
                <motion.div
                  className="Tenuta__Testo font-BodoniBook mt-12 text-xl leading-relaxed overflow-y-auto px-12"
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0, transition: { delay: 0.75, type: "tween", duration: 0.6 } }}
                  exit={{ opacity: 0 }}
                >
                  {tenuta[testi[locale]]}
                </motion.div>
              </div>

              <motion.div
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0, transition: { delay: 1.25, type: "tween", duration: 0.6 } }}
                exit={{ opacity: 0 }}
                className="px-12 py-8"
              >
                <div className="border border-gray border-t mb-6"></div>
                <div className="flex items-center justify-between">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-BodoniBook text-xl text-brown"
                    href={getLinkCorretto(tenuta.link_sito)}
                  >
                    <FormattedMessage id="tenute.prodotti.visita_il_sito" />
                  </a>
                </div>
              </motion.div>
            </motion.div>
          </div>

          {/* Vini */}
          <div style={{ gridArea: "vini" }}>
            <div className="w-full flex items-center">
              <h3 className="hidden lg:block w-3/5 flex-grow font-BodoniBook text-2xl">
                <FormattedMessage id="tenute.vini_della_tenuta" />
              </h3>
              <div className={"w-full lg:w-3/5 xl:w-2/5"}>
                <WishlistPanel showPopup noWrap wine={tenuta.wines} />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-12">
              {tenuta.wines.map(wine => (
                <Wine
                  key={wine.id}
                  wine={wine}
                  tipoVista="griglia"
                  showAddToWishList
                  togglePopupWishlis={togglePopupWishlis}
                  focused={showPopupWishlist.wine ? showPopupWishlist.wine.id : null}
                  onClick={() => dispatch({ type: tenuteActionsTypes.PRODOTTI_VISTA_CHANGE, payload: "vini" })}
                />
              ))}
            </div>
          </div>
        </div>
      </motion.div>
      {/* {showPopupWishlist.showPopup && (
        <WishlistPopup
          showPopup={showPopupWishlist.showPopup}
          wine={showPopupWishlist.wine}
          popupStyle={showPopupWishlist.popupStyle}
          resetPopup={e => setShowPopupWishlist({ ...showPopupWishlist, wine: null, showPopup: false, popupStyle: {} })}
        />
      )} */}
      {showPopupWishlist.wine && (
        <WishlistPanel
          colorName="tenute"
          e={showPopupWishlist.popupTarget}
          wine={showPopupWishlist.wine}
          reference={document.getElementById("griglia")}
          referenceSize="window"
          resetPopup={e => setShowPopupWishlist({ wine: null, showPopupWishlist: false, popupTarget: null })}
        />
      )}
    </div>
  )
}
