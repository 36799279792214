const initialState = {
  vista: "lista", // lista | griglia
  locale: "it",
  openBrand: null,
  vistaProdottiTenuteVini: "tenute", // tenute | vini
}

export const tenuteActionsTypes = {
  TENUTE_CHANGE_VISTA: "TENUTE_CHANGE_VISTA",
  TENUTE_CHANGE_LINGUA: "TENUTE_CHANGE_LINGUA",
  TENUTE_LOGIN: "TENUTE_LOGIN",
  TENUTE_LOGOUT: "TENUTE_LOGOUT",
  OPEN_BRAND: "OPEN_BRAND",
  PRODOTTI_VISTA_CHANGE: "PRODOTTI_VISTA_CHANGE",
}

export default function tenuteReducer(state = initialState, action) {
  switch (action.type) {
    case tenuteActionsTypes.TENUTE_CHANGE_VISTA:
      return { ...state, vista: state.vista === "griglia" ? "lista" : "griglia" }
    case tenuteActionsTypes.PRODOTTI_VISTA_CHANGE:
      return { ...state, vistaProdottiTenuteVini: action.payload }
    case tenuteActionsTypes.TENUTE_CHANGE_LINGUA:
      return { ...state, locale: action.payload }
    case tenuteActionsTypes.TENUTE_LOGIN:
      return { ...state, auth: action.payload }
    case tenuteActionsTypes.TENUTE_LOGOUT:
      return { ...state, auth: undefined }
    case tenuteActionsTypes.OPEN_BRAND:
      return { ...state, openBrand: action.payload }
    default:
      return state
  }
}
