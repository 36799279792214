import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useHistory } from "react-router"
import { Paginator } from "../../components/paginator"
import { Grid } from "../../components/grid"
import { HeaderPanel } from "./header-panel/index"
import { useGeneralHelpers } from "../../helpers/useGeneralHelpers"
import { GiftBox } from "../../models/giftBox"
import toast, { Toaster } from "react-hot-toast"

export default function GiftBoxListing() {
  const auth = useSelector(state => state.auth)
  const location = useLocation()
  const history = useHistory()
  const [giftBoxItems, setGiftBoxItems] = useState(null)
  const { buildPublicationFilter } = useGeneralHelpers()
  const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/gift-boxes`

  const [itemsCount, setItemsCount] = useState(null)
  const [info, setInfo] = useState(null)

  const notify = (info, isError) =>
    toast(info, {
      className: `font-LatoRegular uppercase text-white text-center ${
        isError ? "bg-error" : "bg-black"
      } bg-opacity-100 opacity-100 rounded-sm tracking-wider text-sm`,
    })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const state = searchParams.get("state")
    const code = searchParams.get("code")
    const order = searchParams.get("order")
    const page = searchParams.get("page")
    const itemsPerPage = searchParams.get("itemsPerPage")

    setInfo({
      state: state || "ALL",
      code: code || "",
      order: order || "ASC",
      page: page || 1,
      itemsPerPage: itemsPerPage || 10,
    })
  }, [location])

  useEffect(() => {
    if (info != null) {
      getPosItems()
    }
  }, [info])

  async function getPosItems() {
    const start = (info.page - 1) * info.itemsPerPage
    const offset = `_start=${start}&_limit=${info.itemsPerPage}`

    const codeFilterString = info.code !== "" ? `&codice_confezione_contains=${info.code}` : ``
    const stateFilterString = buildPublicationFilter(info.state)
    const urlFiltersSection = `?_publicationState=preview${stateFilterString}&_sort=codice_confezione:${info.order}&${offset}${codeFilterString}`

    countItems(urlFiltersSection)

    const fetchUrl = baseUrl + urlFiltersSection
    const data = await fetch(fetchUrl, { method: "GET" })
      .then(response => {
        if (response.ok === false) {
          notify("There was an error while retrieving gift boxes", true)
        }
        return response.json()
      })
      .then(data => {
        return data.map(giftBoxItem => new GiftBox(giftBoxItem))
      })
      .catch(() => {
        notify("There was an error while retrieving gift boxes", true)
      })
    setGiftBoxItems(data)
  }

  async function countItems(urlFilterSection) {
    const fetchUrl = `${process.env.REACT_APP_API_BASE_URL}/gift-boxes/count` + urlFilterSection
    const count = await fetch(fetchUrl, { method: "GET" })
      .then(response => {
        if (response.ok === false) {
          notify("There was an error while counting gift boxes", true)
        }
        return response.json()
      })
      .then(data => {
        return data
      })
      .catch(() => {
        notify("There was an error while counting gift boxes", true)
      })
    setItemsCount(count)
  }

  const checkIfUserIsLogged = () => {
    const doesRoleExist = typeof auth.role !== "undefined"
    const isRoleOk = doesRoleExist && auth.role.toLowerCase() === "admin"
    const isUserLoggedIn = auth.isLoggedIn
    return isRoleOk && isUserLoggedIn
  }

  if (checkIfUserIsLogged() === false) {
    history.push("/login", { redirectTo: "/login" })
  }

  const toggleDraftPublished = async (id, turnIntoPublished) => {
    const newState = turnIntoPublished === true ? "published" : "unpublished"
    if (id && typeof turnIntoPublished !== "undefined") {
      const strapiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/gift-boxes/${id}`
      const bodyData = {
        published_at: turnIntoPublished ? new Date() : null,
      }
      fetch(strapiEndpoint, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(bodyData),
      })
        .then(response => {
          const message = response.ok === true ? `Item ${newState}` : "There was an error while switching state"
          notify(message, !response.ok)
          return response.json()
        })
        .then(data => {
          const updatedItem = new GiftBox(data)
          const indexOfelementInsideStateArray = giftBoxItems.findIndex(item => item.id === updatedItem.id)
          giftBoxItems[indexOfelementInsideStateArray] = updatedItem
        })
        .catch(() => {})
    }
  }

  return (
    <>
      <Toaster />
      {auth.isLoggedIn && (
        <>
          {info && (
            <HeaderPanel code={info.code} state={info.state} order={info.order} itemsPerPage={info.itemsPerPage} />
          )}
          <div className="w-10/12 my-12 mx-auto font-LatoRegular">
            <Grid
              type="GIFTBOXES"
              entity={giftBoxItems}
              toggleDraftPublished={(id, turnIntoPublished) => toggleDraftPublished(id, turnIntoPublished)}
            />
            {info && itemsCount !== null && (
              <Paginator
                itemsPerPage={info.itemsPerPage}
                currentPage={parseInt(info.page)}
                totalPages={Math.ceil(itemsCount / info.itemsPerPage)}
                baseURL={`/giftbox-listing?code=${info.code}&state=${info.state}&order=${info.order}`}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}
