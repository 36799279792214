import React from "react"
import { useHistory } from "react-router"
const PrevNextLinkWrapper = ({ children, link }) => {
  if (link) {
    return (
      <a href={link}>
        <div className="cursor-pointer">{children}</div>
      </a>
    )
  } else {
    return <>{children}</>
  }
}

export const Paginator = ({ currentPage, totalPages, type = "SIMPLE", baseURL = "/", itemsPerPage }) => {
  const pagesWindow = []
  const history = useHistory()
  currentPage - 2 > 0 && pagesWindow.push(currentPage - 2)
  currentPage - 1 > 0 && pagesWindow.push(currentPage - 1)
  pagesWindow.push(currentPage)
  currentPage + 1 <= totalPages && pagesWindow.push(currentPage + 1)
  currentPage + 2 <= totalPages && pagesWindow.push(currentPage + 2)

  const firstPage = 1 < pagesWindow[0] ? 1 : undefined
  const lastPage = totalPages > pagesWindow[pagesWindow.length - 1] ? totalPages : undefined
  const nextPage = currentPage < totalPages ? currentPage + 1 : undefined
  const prevPage = currentPage > 1 ? currentPage - 1 : undefined

  return (
    <>
      <div
        className={`relative ${
          type === "ENHANCED" ? " w-11/12 mx-auto border-t border-slate-800 py-10 mt-10 md:mt-8 lg:mt-20" : "w-full"
        }`}
      >
        <div className=" absolute top-1/2 transform -translate-y-1/2 flex items-center ">
          <div>Elementi per pagina: </div>
          <select onChange={e => history.push(baseURL + `&itemsPerPage=${e.target.value}`)}>
            <option selected={itemsPerPage === "10"} value="10">
              10
            </option>
            <option selected={itemsPerPage === "20"} value="20">
              20
            </option>
            <option selected={itemsPerPage === "30"} value="30">
              30
            </option>
          </select>
        </div>
        <div
          className={`relative flex items-center justify-between w-full px-2.5 md:w-1/2 xl:w-1/3 mx-auto ${
            type === "SIMPLE" && "mt-10 md:mt-8 lg:mt-20"
          } `}
        >
          <PrevNextLinkWrapper link={baseURL + `&itemsPerPage=${itemsPerPage}&page=${prevPage}`}>
            <div className="flex items-center space-x-2">
              <svg
                className="pointer-events-none "
                width="11"
                height="16"
                viewBox="0 0 11 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292845 8.70711C-0.0976801 8.31658 -0.0976801 7.68342 0.292845 7.29289L6.6568 0.928933C7.04733 0.538408 7.68049 0.538408 8.07102 0.928932C8.46154 1.31946 8.46154 1.95262 8.07102 2.34315L2.41416 8L8.07102 13.6569C8.46154 14.0474 8.46154 14.6805 8.07102 15.0711C7.6805 15.4616 7.04733 15.4616 6.65681 15.0711L0.292845 8.70711ZM10.9688 9L0.999951 9L0.999951 7L10.9687 7L10.9688 9Z"
                  fill="currentColor"
                  fillOpacity={prevPage ? "1.0" : "0.5"}
                />
              </svg>
              <div className="pointer-events-none ml-7 uppercase  text-slate-600">Prev</div>
            </div>
          </PrevNextLinkWrapper>
          <div className="text-slate-600 flex items-center justify-center proportional-nums lining-nums space-x-2 z-20">
            {firstPage && (
              <a href={baseURL + `&itemsPerPage=${itemsPerPage}&page=${firstPage}`}>
                <div
                  className={`px-1  cursor-pointer border-b ${
                    firstPage === currentPage ? "text-navy border-navy" : "border-transparent"
                  }`}
                >
                  {firstPage}...
                </div>
              </a>
            )}
            {pagesWindow.map((item, index) => (
              <a href={baseURL + `&itemsPerPage=${itemsPerPage}&page=${item}`} key={"paginator-page-" + index}>
                <div
                  className={`px-1  cursor-pointer border-b ${
                    item === currentPage ? "text-navy border-navy" : "border-transparent"
                  }`}
                >
                  {item}
                </div>
              </a>
            ))}
            {lastPage && (
              <a href={baseURL + `&itemsPerPage=${itemsPerPage}&page=${lastPage}`}>
                <div
                  className={`px-1  cursor-pointer border-b ${
                    lastPage === currentPage ? "text-navy border-navy" : "border-transparent"
                  }`}
                >
                  ...{lastPage}
                </div>
              </a>
            )}
          </div>

          <PrevNextLinkWrapper link={baseURL + `&itemsPerPage=${itemsPerPage}&page=${nextPage}`}>
            <div className="flex items-center space-x-2">
              <div className="mr-7 uppercase  text-slate-600 pointer-events-none">Next</div>
              <svg
                className="pointer-events-none"
                width="11"
                height="16"
                viewBox="0 0 11 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7072 8.70711C11.0977 8.31658 11.0977 7.68342 10.7072 7.29289L4.3432 0.928933C3.95267 0.538408 3.31951 0.538408 2.92898 0.928932C2.53846 1.31946 2.53846 1.95262 2.92898 2.34315L8.58584 8L2.92898 13.6569C2.53846 14.0474 2.53846 14.6805 2.92898 15.0711C3.3195 15.4616 3.95267 15.4616 4.34319 15.0711L10.7072 8.70711ZM0.0312499 9L10 9L10 7L0.0312501 7L0.0312499 9Z"
                  fill="currentColor"
                  fillOpacity={nextPage ? "1.0" : "0.5"}
                />
              </svg>
            </div>
          </PrevNextLinkWrapper>
        </div>
      </div>
    </>
  )
}
