import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import LineaDiagonale from "../linea-diagonale"
import WishlistIcon from "../wishlist-icon"
import { FormattedMessage } from "react-intl"

export const WishlistButton = ({ isInWishlist, product, isInDetailsPage, category = "GIFTBOX" }) => {
  const [itemQuantity, setItemQuantity] = useState(product.quantity ? product.quantity : 1)

  const cat = {
    POSITEM: "positemWishlist",
    GIFTBOX: "giftboxWishlist",
  }

  const dispatch = useDispatch()

  const handleQuantityChange = event => {
    if (!event.target.value || event.target.value <= 0) {
      setItemQuantity(1)
      return
    }

    setItemQuantity(event.target.value)
  }

  const handleToggleWishlist = e => {
    e.preventDefault()
    e.stopPropagation()

    if (isInWishlist) {
      product.quantity = 0
      dispatch({ type: "WISHLIST_REMOVE", payload: product, category: cat[category] })
    } else {
      product.quantity = +itemQuantity
      dispatch({ type: "WISHLIST_ADD", payload: product, category: cat[category] })
    }
  }

  return (
    <>
      <div className={isInDetailsPage ? "mt-16" : ""}>
        <div className="flex justify-center gap-x-4 items-center font-LatoBold uppercase text-11px  tracking-2 py-2  pb-8">
          <div className=" overflow-hidden text-left flex items-center pr-4">
            <WishlistIcon color="#000000" fill={isInWishlist} />
          </div>
          {!isInWishlist && category === "POSITEM" && (
            <input
              className="border-slate-200 h-[26px] border-[1px] text-right w-10 text-black"
              type="number"
              min={1}
              value={itemQuantity}
              onChange={handleQuantityChange}
            />
          )}
          <button className="font-LatoBold uppercase text-11px " onClick={handleToggleWishlist}>
            {isInWishlist ? (
              <FormattedMessage id="wishlist.popup.aggiunto_alla_wishlist" />
            ) : (
              <FormattedMessage id="wishlist.popup.aggiunti_alla_wishlist" />
            )}
          </button>
        </div>
      </div>
    </>
  )
}
