import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import trashIcon from "../../img/trash-icon.svg"
import { getValidatedImageUrl, getImageUrl } from "../../utils/getImageUrl"
import Details from "./details"
import "./style.css"
import { WishlistButton } from "../wishlist-button"

export const GiftBox = ({ product, showRemoveIcon, striped = false }) => {
  const auth = useSelector(state => state.auth)
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [itemQuantity, setItemQuantity] = useState(product.quantity ? product.quantity : 1)
  const dispatch = useDispatch()
  const wishlist = useSelector(state => state.wishList)

  const isInWishlist = wishlist.giftboxWishlist && wishlist.giftboxWishlist.find(box => box.id === product.id)

  useEffect(() => {
    document.body.style.overflow = "auto"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  function removeItem(e) {
    // debugger
    e.preventDefault()
    dispatch({ type: "WISHLIST_REMOVE", payload: product })
  }
  function closeMenu() {
    dispatch({ type: "MENU_CLOSE" })
  }

  function openDetail() {
    setIsDetailOpen(true)
    document.body.style.overflow = "hidden"
  }

  function closeDetail() {
    setIsDetailOpen(false)
    document.body.style.overflow = "auto"
  }

  function handleClick() {
    closeMenu()
    openDetail()
  }

  const handleQuantityChange = event => {
    const itemQuantity = event.target.value
    if (!itemQuantity || itemQuantity <= 0) {
      product.quantity = 1
      return
    }
    product.quantity = +itemQuantity

    setItemQuantity(product.quantity)
    dispatch({
      type: "WISHLIST_UPDATE_ALBUM",
      payload: { product: product },
    })
  }

  return (
    <div className={`flex flex-col justify-center ${striped ? "Catalog__Item" : ""}`}>
      <div className="p-8 pb-0 w-full cursor-pointer" onClick={handleClick}>
        <div style={{ paddingBottom: "100%" }} className="w-full  relative overflow-hidden">
          <img
            className="GiftBox__Photo absolute h-full w-full object-cover"
            loading="eager"
            src={getImageUrl(getValidatedImageUrl(product))}
          />
        </div>
        <div className="flex flex-col items-center">
          <h4 className="text-center text-sm font-LatoBold text-gray tracking-2 uppercase">{product.descrizione}</h4>
          {product.brands && (
            <h2 className="font-LatoRegular text-center mt-4 text-lg">
              {product.brands.map(({ Nome }) => Nome).join(",")}
            </h2>
          )}
          {product[`prezzo_${auth.listino}`] && (
            <div className="text-center">€ {product[`prezzo_${auth.listino}`].toFixed(2)} Iva escl.</div>
          )}
        </div>
      </div>
      <div className="flex gap-x-6 items-center justify-center py-2">
        {showRemoveIcon && <img className="p-4  cursor-pointer" src={trashIcon} onClick={removeItem} />}
        {/* {showRemoveIcon && (
          <input
            className="border-slate-200/30 h-[26px] border-[1px] text-right w-10 text-white bg-black"
            type="number"
            min={1}
            value={itemQuantity}
            onChange={handleQuantityChange}
          />
        )} */}
      </div>

      <div className="text-center p-8 pt-0">
        {!showRemoveIcon && <WishlistButton isInWishlist={isInWishlist} product={product} />}
      </div>

      <Details isOpen={isDetailOpen} onClose={closeDetail} product={product} />
    </div>
  )
}
