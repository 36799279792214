import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useDownloader } from "../../../helpers/useDownloader"
import { GiftBox } from "../../../models/giftBox"

export const HeaderPanel = ({ code, state, order, itemsPerPage }) => {
  const [codeFilter, setCodeFilter] = useState(code)
  const history = useHistory()
  const [downloadState, setDownloadState] = useState("idle")
  const download = useDownloader()
  const baseUrl = `/giftbox-listing`

  useEffect(() => {
    setCodeFilter(code)
  }, [code])

  const initDownload = async () => {
    setDownloadState("downloading")
    const fetchUrl = `${process.env.REACT_APP_API_BASE_URL}/gift-boxes?_publicationState=preview&_sort=id:ASC&_start=0&_limit=1000`
    const data = await fetch(fetchUrl, { method: "GET" })
      .then(response => response.json())
      .then(data => {
        return data.map(giftbox => new GiftBox(giftbox).extractCsvData())
      })
    download(data, "Confezioni regalo")
    setDownloadState("downloaded")
  }

  const variants = {
    idle: { backgroundColor: "#000000" },
    downloading: {
      backgroundColor: "#cccccc",
      transition: { duration: 0.5, repeat: Infinity, repeatType: "reverse" },
    },
    downloaded: {
      backgroundColor: "#000000",
      transition: { duration: 0.5 },
    },
  }
  return (
    <>
      <div className=" w-10/12 mt-12 mx-auto flex items-center space-x-4">
        <div
          className="p-3 bg-black text-white text-center font-LatoRegular cursor-pointer"
          onClick={e => history.push("/confezioni-regalo")}
        >
          Back to giftboxes
        </div>
        <div className="font-LatoRegular p-3 outline-none focus:outline-none">
          <select
            onChange={e => {
              const redirect =
                e.target.value === ""
                  ? baseUrl + `?order=${order}`
                  : baseUrl + `?state=${e.target.value}&itemsPerPage=${itemsPerPage}&order=${order}`
              history.push(redirect)
            }}
          >
            <option selected={"ALL" === state} value={"ALL"}>
              ALL
            </option>
            <option selected={"LIVE" === state} value={"LIVE"}>
              LIVE
            </option>
            <option selected={"DRAFT" === state} value={"DRAFT"}>
              DRAFT
            </option>
          </select>
        </div>

        <div className="flex space-x-4 items-center ">
          <div className="w-max relative">
            <div
              onClick={e => {
                const redirect = baseUrl + `?itemsPerPage=10&order=${order}`
                history.push(redirect)
              }}
              style={{ right: `1em` }}
              className="cursor-pointer absolute top-1/2  transform -translate-y-1/2 "
            >
              x
            </div>
            <input
              value={codeFilter}
              className=" w-56 text-black appearance-none border-b border-brown p-3 outline-none font-LatoRegular text-sm"
              placeholder="ricerca codice articolo"
              onChange={e => setCodeFilter(e.target.value)}
            />
          </div>

          <motion.div
            onClick={e => {
              const redirect =
                codeFilter === ""
                  ? baseUrl + `?order=${order}`
                  : baseUrl + `?code=${codeFilter}&itemsPerPage=${itemsPerPage}&order=${order}`
              history.push(redirect)
            }}
            style={{ width: "150px" }}
            className="p-3 text-center iniline-block bg-black uppercase text-xs tracking-wider transition duration-300 cursor-pointer text-white  hover  "
          >
            Cerca
          </motion.div>
        </div>

        <motion.div
          variants={variants}
          onClick={e => {
            const filterCode = codeFilter === "" ? "" : `code=${codeFilter}`
            const stateFilter = state === "" ? "" : `state=${state}`
            const orderFilter = order === "ASC" ? "order=DESC" : "order=ASC"
            const itemsPerPageFilter = `itemsPerPage=${itemsPerPage}`
            const redirect = baseUrl + `?${filterCode}&${stateFilter}&${orderFilter}&${itemsPerPageFilter}`
            history.push(redirect)
          }}
          style={{ width: "250px" }}
          className="p-3 text-center iniline-block bg-black uppercase text-xs tracking-wider transition duration-300 cursor-pointer text-white  hover  "
        >
          Codice articolo {order}
        </motion.div>
        <motion.div
          variants={variants}
          initial="idle"
          animate={downloadState}
          onClick={e => initDownload()}
          className="p-3 w-32 text-center iniline-block bg-black uppercase text-xs tracking-wider transition duration-300 cursor-pointer text-white   hover  "
        >
          dowload
        </motion.div>
      </div>
    </>
  )
}
