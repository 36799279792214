const initialState = {
  data: [],
  pagination: {
    itemsPerPage: 10,
    currentPage: 1,
    totalArticles: null,
    order: "ASC",
    filters: {
      code: null,
      state: "ALL",
    },
  },
}

export default function giftBoxListingReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_PAGINATION":
      return {
        ...state,
        pagination: { ...state.pagination, ...action.payload },
      }
    case "LOAD_BOXES":
      return {
        ...state,
        data: action.payload,
      }
    case "UPDATE_PUBLISHED_STATE":
      const indexOfelementInsideStateArray = state.data.findIndex(item => item.id === action.payload.id)
      state.data[indexOfelementInsideStateArray] = action.payload
      return { ...state, data: [...state.data] }
    default:
      return state
  }
}
