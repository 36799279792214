import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { motion } from "framer-motion"
import { FormattedMessage, useIntl } from "react-intl"
import HeaderWishlistTenute from "./components/header-wishlist-tenute"
import Spinner from "../../components/spinner"
import Wine from "../../components/wine"
import Message from "../../components/modal-message"

import "./style.css"

export const WishlistDetails = () => {
  const location = useLocation()
  const wishlist = useSelector(state => state.winesWishlist)
  const brands = useSelector(state => state.wines.allBrands)
  const albumIndex = parseInt(location.pathname.split("/")[location.pathname.split("/").length - 1])
  const album = useSelector(state => state.winesWishlist.find(item => item.id === albumIndex))
  const [generatingPDF, setGeneratingPDF] = useState(false)
  const [email, setEmail] = useState("")
  const [clientName, setClientName] = useState("")
  const intl = useIntl()

  const [selectedItems, setSelectedItems] = useState([])

  const [messageInfo, setMessageInfo] = useState({
    title: "",
    message: "",
  })

  const brandList = []
  album.items.forEach(wine => {
    if (!brandList.find(brand => brand.id === wine.brand.id)) {
      brandList.push(wine.brand)
    }
  })

  useEffect(() => {
    album.items.forEach(wine => {
      if (!brandList.find(brand => brand.id === wine.brand.id)) {
        brandList.push(wine.brand)
      }
    })
    document.body.classList.add("bg-black")
    return function cleanup() {
      document.body.classList.remove("bg-black")
    }
  }, [album, wishlist])

  // useEffect(() => {
  //   console.log(selectedItems)
  // }, [selectedItems])

  function selectAll(wines, isUnselecting = false) {
    if (isUnselecting) {
      setSelectedItems(selectedItems => {
        return selectedItems.filter(item => !wines.find(wine => wine.id === item.id))
      })
    } else {
      wines.forEach(wine => {
        const isAlreadySelected = selectedItems.find(item => wine.id === item.id)
        if (!isAlreadySelected) {
          setSelectedItems(selectedItems => [...selectedItems, wine])
        }
      })
    }
  }

  function manageSelectedItems(wine) {
    const found = selectedItems.find(item => item.id === wine.id)
    if (!found) {
      setSelectedItems(selectedItems => [...selectedItems, wine])
    } else {
      const newSelectedItems = selectedItems.filter(item => item.id !== wine.id)
      setSelectedItems(newSelectedItems)
    }
  }

  const requestPDFBuild = async () => {
    const productsIds = selectedItems.map(p => p.id).join(",")
    if (productsIds.length === 0) {
      setMessageInfo({
        title: "Attenzione",
        message: "Devi aver selezionato almeno un vino per poter inoltrare la mail",
      })
      return
    }
    setGeneratingPDF(true)

    const selectedEstates = []
    for (let wine of selectedItems) {
      let relatedEstate = selectedEstates.find(estate => estate.id === wine.brand.id)

      if (!relatedEstate) {
        //CONTROLLO NUMERO VINI DELLA TENUTA
        let amountOfWinesInWishlist = brands.find(
          brand => brand.id === wine.brand.id && brand.Distribuzione === wine.Distribuzione
        )

        //CREO OGGETTO ESTATE
        let newEstate = {
          id: wine.brand.id,
          wines: [{ id: wine.id, quantity: wine.quantity || 1 }],
          full: false,
          brandWinesCount: amountOfWinesInWishlist?.wines?.length || 0,
        }

        selectedEstates.push(newEstate)
      } else {
        if (!relatedEstate.wines.find(item => item.id === wine.id)) {
          relatedEstate.wines.push({ id: wine.id, quantity: wine.quantity || 1 })
          relatedEstate.full = relatedEstate.wines.length === relatedEstate.brandWinesCount
        }
      }
    }
    const wishlistToSend = {
      name: album.name,
      tenute: selectedEstates,
    }

    const postObj = {
      to: email,
      clientName: clientName,
      wishlist: wishlistToSend,
    }

    try {
      // const resetRes = await fetch(`https://antinori-cataloghi-api.com:3000/pdf?products=${productsIds}&to=${email}`, {
      const resetRes = await fetch(`${process.env.REACT_APP_API_BACKEND_URL}/wishlist/pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postObj),
      })

      if (resetRes.ok) {
        setMessageInfo({
          title: "Fatto",
          message: "La selezione di wishlist è stata inviata correttamente all'indirizzo mail fornito",
        })
        setGeneratingPDF(false)
      }
    } catch (e) {
      setGeneratingPDF(false)
      setMessageInfo({
        title: "Errore",
        message: "Errore nell'invio della selezione, riprovare o contattare l'assistenza",
      })
    }

    setSelectedItems([])
    setEmail("")
  }
  return (
    <div className="absolute inset-0 z-20">
      <HeaderWishlistTenute />
      <div className="Wishlist h-full bg-black text-white px-6 lg:px-32 py-8 overflow-y-auto">
        {/* Wishlist vuota */}
        {album.items === null && (
          <div className="Wishlist__Empty h-full flex flex-col items-center justify-center">
            <div className="font-BodoniBook text-center text-4xl lg:text-6xl text-white leading-none">
              {album.name} <FormattedMessage id="wishlist.details.wishlist_vuota" />
            </div>
          </div>
        )}

        {/* Wishlist piena */}

        {wishlist.length > 0 && (
          <>
            <h1 className="font-BodoniBook text-40px mt-32 lg:text-60px leading-none text-center text-white">
              {album.name}
            </h1>
            <form
              onSubmit={e => {
                e.preventDefault()
                requestPDFBuild()
              }}
              className="mt-24 flex items-center gap-6 pr-[1.25rem]"
            >
              <input
                type="text"
                required
                value={clientName}
                onChange={e => setClientName(e.target.value)}
                className="outline-none border-b-2 w-full placeholder-white placeholder-opacity-50 border-white  border-opacity-50 text-white bg-transparent py-2 font-LatoRegular lg:text-lg"
                placeholder={intl.formatMessage({ id: "tenute.wishlist.inserisci_nome" })}
              />
              <input
                type="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="Wishlist__InputEmail text-white bg-transparent py-2 font-LatoRegular lg:text-lg"
                placeholder={intl.formatMessage({ id: "tenute.wishlist.inserisci_email" })}
              />

              <button className="Wishlist__SubmitBtn w-6">{generatingPDF && <Spinner />}</button>
              <button className="p-3 px-12 bg-brown hover:bg-white hover:text-brown duration-200">
                <FormattedMessage id="wishlist.invia" />
              </button>
            </form>
            <div className=" mt-16 tracking-widest text-white bg-transparent py-2 font-LatoRegular lg:text-lg">
              {/* <span
                className="Animated__Border inline relative text-brown transition duration-300 font-bold pb-2  cursor-pointer"
                onClick={e => setIsSelecting(!isSelecting)}
              >
               
              </span>{" "} */}
              <FormattedMessage id="wishlist.seleziona_prodotti" />
              <FormattedMessage id="wishlist.per_ricevere_pdf" />
            </div>

            <div className="Wishlist__Prodotti mt-32 mb-32 ">
              {brandList?.map((brand, index) => {
                const wines = album.items.filter(wine => wine.brand.id === brand.id).map(item => item)
                const winesNodes = album.items
                  .filter(wine => wine.brand.id === brand.id)
                  .map(item => {
                    return (
                      <Wine
                        key={item.id}
                        nameColor="light"
                        wine={item}
                        album={album}
                        showRemoveIcon
                        showCheckbox
                        selectedItems={selectedItems}
                        hiddenText
                        adjustHeight
                        moveAndDelete
                        showWishListPopup
                        changeSelection={e => manageSelectedItems(item)}
                      />
                    )
                  })

                return (
                  <div key={`tenuta-${index}`}>
                    <div className="flex items-center pr-5 my-4">
                      <div className="text-gray pl-0 mr-4  ">{brand.Nome}</div>
                      <div className="h-0 border-b border-b-gray opacity-50 flex-grow  "></div>
                      <div
                        className="py-3 px-8 ml-4 text-center bg-brown cursor-pointer hover:bg-white hover:text-brown duration-200"
                        onClick={e => {
                          const isEveryWineSelected =
                            selectedItems.filter(wineItem => wineItem.brand.id === brand.id).length == wines.length
                          isEveryWineSelected ? selectAll(wines, true) : selectAll(wines)
                        }}
                      >
                        {selectedItems.filter(wineItem => wineItem.brand.id === brand.id).length === wines.length ? (
                          <FormattedMessage id={"wishlist.popup.deseleziona_tutto"} />
                        ) : (
                          <FormattedMessage id={"wishlist.popup.seleziona_tutto"} />
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-16 pr-5 lg:pr-12">
                      {winesNodes}
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
      <motion.img
        className="absolute block right-0 top-1/2 transform -translate-y-1/2 pr-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5, type: "tween", delay: 1 } }}
        src={`${process.env.PUBLIC_URL}/logo-ocm.svg`}
        alt="ocm logo"
      />
      <Message title={messageInfo.title} close={e => setMessageInfo({ title: "", message: "" })}>
        {messageInfo.message}
      </Message>
    </div>
  )
}
