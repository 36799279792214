import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { AnimatePresence } from "framer-motion"

import Logo from "../logo"
import WishlistIcon from "../wishlist-icon"
import magnifyingGlass from "../../img/magnifying-glass.svg"
import { tenuteActionsTypes } from "../../redux/tenute-reducer"
import FiltersProdotti from "./filters"
import MobileMenu from "./mobile-menu"

import "./style.css"

export default function HeaderViniMobile() {
  const dispatch = useDispatch()
  const wishlist = useSelector(state => state.winesWishlist)
  const vistaProdottiTenuteVini = useSelector(state => state.tenute.vistaProdottiTenuteVini)
  const history = useHistory()
  const location = useLocation()
  const vistaTenute = useSelector(state => state.tenute.vista)
  const [areFiltersVisibile, setAreFiltersVisible] = useState()
  const [isMenuVisible, setIsMenuVisible] = useState()
  const showFiltersIcon = location.pathname === "/prodotti"
  const filters = useSelector(state => state.wines.filters)
  const anyFiltersSelected = [...filters.formato, ...filters.tenuta, ...filters.vino].length > 0

  function changeVistaProdotti(vistaSelezionata) {
    if (vistaSelezionata !== vistaProdottiTenuteVini) {
      dispatch({ type: tenuteActionsTypes.PRODOTTI_VISTA_CHANGE, payload: vistaSelezionata })
    }

    history.push("/prodotti")
  }

  function switchVistaTenute() {
    history.push("/prodotti")
    dispatch({ type: tenuteActionsTypes.TENUTE_CHANGE_VISTA })
  }

  return (
    <>
      <header className="HeaderVini flex items-center justify-between bg-black text-white pl-2 pr-4 fixed top-0 left-0 w-screen z-30">
        <div className="flex-1">
          <Hamburger onClick={() => setIsMenuVisible(isVisible => !isVisible)} />
        </div>

        {/* Left (Logo + filtri + grid) */}
        <div className="flex-1 flex justify-center">
          <Link to="/">
            <Logo className="h-12 text-white fill-current" />
          </Link>
        </div>

        {/* Right (search + wishlist + vini) */}
        <div className="flex-1 flex items-center justify-end">
          {/* Icona search */}
          <div className="cursor-pointer mr-4" style={{ width: "20px" }}>
            <img src={magnifyingGlass} onClick={() => dispatch({ type: "VINI_MENU_OPEN" })} />
          </div>

          {/* Icona wishlist */}
          <Link to="/wishlist-prodotti">
            <WishlistIcon nonVuota={wishlist && wishlist.length > 0} className="inline" />
          </Link>
        </div>
      </header>
      <div
        className="Subheader fixed left-0 right-0 bg-tenute flex items-center justify-between px-8 shadow z-10"
        style={{ top: "72px", height: "60px" }}
      >
        <div className="flex items-center">
          <div
            className={`uppercase text-xs tracking-widest text-center cursor-pointer relative mr-4 text-black`}
            onClick={() => changeVistaProdotti("tenute")}
          >
            <FormattedMessage id="tenute.header.tenute" />
            {vistaProdottiTenuteVini === "tenute" && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <svg width={53} height={39} viewBox="0 0 53 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity={0.3} stroke="#000" d="M.705 38.596l52-38" />
                </svg>
              </div>
            )}
          </div>
          <div
            className={`uppercase text-xs tracking-widest text-center cursor-pointer relative text-black`}
            onClick={() => changeVistaProdotti("vini")}
          >
            <FormattedMessage id="tenute.header.vini" />
            {vistaProdottiTenuteVini === "vini" && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <svg width={53} height={39} viewBox="0 0 53 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity={0.3} stroke="#000" d="M.705 38.596l52-38" />
                </svg>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end">
          {/* Icona filtri */}
          {showFiltersIcon && (
            <div
              className={`HeaderVini__FilterIcon relative cursor-pointer mr-4 ${
                anyFiltersSelected ? "with-badge" : ""
              }`}
              onClick={() => setAreFiltersVisible(true)}
            >
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#prefix__clip0)">
                  <path
                    d="M1.66 4.086l7.906 9.208c.118.136.184.31.185.49v5.592a.372.372 0 00.253.356l3.757 1.25a.372.372 0 00.49-.355v-6.844a.754.754 0 01.187-.49l7.903-9.207a.656.656 0 00-.496-1.084H2.16a.656.656 0 00-.5 1.084z"
                    stroke="#000000"
                    strokeWidth={1}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="prefix__clip0">
                    <path fill="#000000" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          )}
          <div className="flex justify-end cursor-pointer" onClick={switchVistaTenute}>
            {vistaTenute === "griglia" && (
              <svg width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="#000000" d="M0 12h20v1.5H0zM0 6h20v1.5H0zM0 0h20v1.5H0z" />
              </svg>
            )}

            {vistaTenute === "lista" && (
              <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  stroke="#000000"
                  strokeOpacity={0.5}
                  strokeWidth={1.5}
                  d="M.75.75h6.735v6.735H.75zM.75 12.515h6.735v6.735H.75zM12.515.75h6.735v6.735h-6.735zM12.515 12.515h6.735v6.735h-6.735z"
                />
              </svg>
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {areFiltersVisibile && <FiltersProdotti onClose={() => setAreFiltersVisible(false)} />}
      </AnimatePresence>

      <AnimatePresence>
        {isMenuVisible && (
          <MobileMenu
            onClose={() => {
              setIsMenuVisible(false)
              history.push("/prodotti")
            }}
          />
        )}
      </AnimatePresence>
    </>
  )
}

function Hamburger({ onClick }) {
  return (
    <div className="pl-2" onClick={onClick}>
      <svg width={32} height={14} viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.35 1.35h16.1M13.5 12.5l17.6.05M1.35 6.95h12.6M20.5 1.5l10.25-.15M1.875 12.55l8.625-.05M16.75 6.95h14"
          stroke="#fff"
          strokeWidth={1.2}
          strokeLinecap="square"
        />
      </svg>
    </div>
  )
}
