import React from "react"
import { Link, useHistory } from "react-router-dom"
import Logo from "../../../../components/logo"
import logoWishlistWhite from "../../../../img/logo-wishlist-white.svg"

export default function HeaderWishlistTenute() {
  const history = useHistory()
  return (
    <header className="HeaderVini flex items-center justify-between bg-black py-12  text-white px-8 absolute top-0 left-0 w-full z-30">
      <Link to="/" className="invisible grid grid-cols-5 gap-10 items-center">
        <Logo className="h-12 text-white fill-current" />
      </Link>

      <img className="h-20 bg-black absolute left-1/2 transform -translate-x-1/2 block" src={logoWishlistWhite} />
      {/* Right (search + wishlist + vini) */}
      <div className="flex items-center cursor-pointer" onClick={() => history.goBack()}>
        <svg width={37} height={37} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path stroke="#fff" d="M9.546 8.839l18.385 18.385M8.839 27.224L27.224 8.839" />
        </svg>
      </div>
    </header>
  )
}
