const initialState = false

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case "MENU_OPEN":
      document.body.style.overflow = "hidden"
      return true
    case "MENU_CLOSE":
      document.body.style.overflow = "auto"
      return false
    case "GO_HOME":
      document.body.style.overflow = "auto"
      return state
    default:
      return state
  }
}
