import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Tenute from "../tenute"
import Vini from "../vini"
import { useLocation } from "react-router-dom"

import { Loading } from "../../components/loading"
import "./style.css"
import { AnimatePresence } from "framer-motion"

export default function Prodotti() {
  const vistaProdottiTenuteVini = useSelector(state => state.tenute.vistaProdottiTenuteVini)
  const dispatch = useDispatch()
  const winesState = useSelector(state => state.wines)
  const location = useLocation()
  const [isContentLoaded, setIsContentLoaded] = useState(false)
  const [isTenuta, setIsTenuta] = useState(null)
  // All'avvio, carico i brand e i vini
  useEffect(() => {
    async function fetchWinesAndBrands() {
      const allWines = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wines?_limit=1000&_sort=ordine:ASC`).then(
        resp => resp.json()
      )
      const allBrands = await fetch(`${process.env.REACT_APP_API_BASE_URL}/brands`).then(resp => resp.json())
      dispatch({ type: "LOADED_BRANDS", payload: allBrands })
      dispatch({ type: "LOADED_WINES", payload: allWines })
    }

    const locationPathPieces = location.pathname.split("/")
    const isDetailsPage = location.pathname.includes("details")
    const entityId = isDetailsPage
      ? locationPathPieces[locationPathPieces.length - 2]
      : locationPathPieces[locationPathPieces.length - 1]
    setIsTenuta(entityId ? isNaN(entityId) : true)

    // if (winesState && winesState.allWines.length === 0) {
    fetchWinesAndBrands().then(() => setIsContentLoaded(true))
  }, [])

  useEffect(() => {
    dispatch({ type: "PRODOTTI_VISTA_CHANGE", payload: isTenuta ? "tenute" : "vini" })
  }, [isTenuta])

  if (winesState?.allWines.length === 0 || winesState?.filteredBrands.length === 0) {
    return <Loading />
  }

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {!isContentLoaded && <Loading />}
        {isContentLoaded && vistaProdottiTenuteVini === "tenute" && <Tenute />}
        {isContentLoaded && vistaProdottiTenuteVini === "vini" && <Vini />}
      </AnimatePresence>
    </>
  )
}
