import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import "./style.css"
// import { tenuteActionsTypes } from "../../redux/tenute-reducer"
import placeholder from "./ugo.jpg"
import { getImageUrl } from "../../utils/getImageUrl"
import { motion } from "framer-motion"
import { useWindowWidth } from "@react-hook/window-size"
import Checkbox from "../checkbox"
import WishlistIcon from "../wishlist-icon"
import { WishlistPanel } from "../wishlistPanel"

export default function Wine({
  wine,
  album,
  onClick = () => {},
  togglePopupWishlis = () => {},
  changeSelection = () => {},
  nameColor = "dark",
  index,
  showFormati = false,
  tipoVista = "lista",
  showRemoveIcon = false,
  showAddToWishList = false,
  showWishListPopup = false,
  adjustHeight = false,
  showCheckbox = false,
  isTheBiggestOne = false,
  selectedItems,
  focused,
}) {
  const dispatch = useDispatch()
  const vistaProdottiTenuteVini = useSelector(state => state.tenute.vistaProdottiTenuteVini)
  const locale = useSelector(state => state.tenute.locale)
  const [checkboxState, setCheckboxState] = useState(false)
  const windowWidth = useWindowWidth()
  const wishlist = useSelector(state => state.winesWishlist)

  const wishlistPresence =
    wishlist &&
    wishlist.filter(
      wish =>
        wish.items.filter(item => {
          return item.id === wine.id
        }).length > 0
    )

  const locales = {
    it: "italiano",
    en: "inglese",
    de: "tedesco",
    ch: "cinese",
  }

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCheckboxState(false)
    } else {
      const isSelected = selectedItems?.find(item => item.id === wine.id)
      setCheckboxState(!!isSelected)
    }
  }, [selectedItems])

  let path = `/prodotti/${wine.brand.Nome}/details/${wine.id}`
  if (vistaProdottiTenuteVini === "vini" || tipoVista === "griglia") {
    path = `/prodotti/${wine.id}/details`
  }

  function removeItem(e) {
    e.preventDefault()
    album.items = album.items.filter(item => item.id !== wine.id)
    dispatch({ type: "WINES_WISHLIST_REMOVE", payload: album })
    if (changeSelection) {
      changeSelection()
    }
  }

  function handleClick(e) {
    onClick()
    closeMenu()
  }

  function closeMenu() {
    dispatch({ type: "VINI_MENU_CLOSE" })
  }

  function checkboxManagement(e) {
    setCheckboxState(checkboxState => !checkboxState)
    if (changeSelection) {
      changeSelection()
    }
  }
  return (
    <div className="Wine relative">
      <Link to={path} onClick={e => handleClick(e)}>
        {/* Icona remove */}

        {/* Foto */}
        <div className="outer">
          <div className="inner">
            <motion.div
              className="absolute top-1/2 transform -translate-y-1/2"
              initial={{ height: 0 }}
              animate={{
                height: "100%",
                transition: { type: "tween", duration: 1, delay: 0.1 * index },
              }}
            >
              <img
                className="GiftBox__Photo w-full h-full object-cover"
                src={wine.Foto ? getImageUrl(wine.Foto.url, 800) : placeholder}
                alt={wine.Nome}
              />

              {showRemoveIcon && (
                <div
                  className=" transform hover:rotate-8 transition duration-300 absolute top-0 right-0 z-10 cursor-pointer p-4"
                  onClick={removeItem}
                >
                  <svg width={19} height={23} viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.915 0c-.243.024-.45.261-.448.516v2.32H.472c-.259.013-.484.27-.472.54.013.27.26.504.519.492h1.028L3.735 22.23a.52.52 0 00.495.46h10.39c.243.002.469-.21.495-.46l2.188-18.363h1.029c.261.004.502-.244.502-.516s-.241-.52-.502-.516h-4.949V.516c0-.27-.235-.516-.494-.516H5.915zm.541 1.031h5.938v1.805H6.456V1.031zM2.552 3.868h13.746L14.18 21.659H4.67L2.552 3.868zm3.85 3.601c-.243.028-.446.27-.44.524v9.798c-.004.273.233.523.494.523.262 0 .499-.25.495-.523V7.993c.006-.292-.27-.556-.549-.524zm2.969 0c-.243.028-.446.27-.44.524v9.798c-.004.273.233.523.494.523.262 0 .499-.25.495-.523V7.993c.006-.292-.27-.556-.549-.524zm2.969 0c-.243.028-.446.27-.44.524v9.798c-.005.273.232.523.494.523.261 0 .498-.25.495-.523V7.993c.006-.292-.27-.556-.55-.524z"
                      fill="#fff"
                    />
                  </svg>
                </div>
              )}

              {showCheckbox && <Checkbox selected={checkboxState} changeState={e => checkboxManagement()} />}
            </motion.div>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { type: "tween", delay: 0.8, duration: 0.6 },
          }}
          className={`font-BodoniBook text-2xl ${nameColor === "dark" ? "text-black" : "text-white"} text-center mt-6`}
        >
          {/* Nome */}
          <div
            className={`font-BodoniBook text-2xl ${
              nameColor === "dark" ? "text-black" : "text-white"
            } text-center mt-6`}
          >
            {wine.Nome}
          </div>

          {/* Denominazione - tipologia */}
          <div
            className={`text-center ${
              adjustHeight && windowWidth > 768 && " h-16"
            }  text-sm font-LatoBold text-brown tracking-2 uppercase`}
          >
            {wine.Denominazione} | {wine.wine_type[locales[locale]].replace("_", " e ")}
          </div>

          {/* Formati */}
          {showFormati && (
            <div className="text-gray text-center mt-5 font-LatoRegular text-xl">
              {wine.formati &&
                wine.formati.map((f, i) => (
                  <span key={i}>
                    cl. {f.formato}
                    {i < wine.formati.length - 1 && <span>, </span>}
                  </span>
                ))}
            </div>
          )}
        </motion.div>
        {showWishListPopup && <div className=" bottom-0 left-0 h-20 w-full"></div>}
      </Link>
      {showAddToWishList && (
        <motion.div
          className="absolute top-0 right-0 p-4 z-10 cursor-pointer"
          initial={{ opacity: 0, y: -10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { type: "tween", delay: 0.8, duration: 0.6 },
          }}
          onClick={e => {
            e.preventDefault()
            togglePopupWishlis(e, wine)
          }}
        >
          <WishlistIcon
            color="#ffffff"
            fill={wishlistPresence?.length > 0 || (focused ? focused === wine.id : false)}
          />
        </motion.div>
      )}
      {showWishListPopup && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { type: "tween", delay: 0.8, duration: 0.6 },
          }}
          className={`${adjustHeight && "mt-4"} absolute bottom-0 py-8 w-full mx-auto`}
        >
          <WishlistPanel showPopup album={album} noWrap wine={wine} moveItem />
          {/* <WishlistPopup showPopup album={album} wrap={false} wine={wine} removeItem={moveAndDelete ? e => removeItem(e) : null}/> */}
        </motion.div>
      )}
    </div>
  )
}
