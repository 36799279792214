import { initial } from "lodash"

const loggedOutState = {
  isLoggedIn: false,
}

const initialState = {
  isLoggedIn: false,
}

if (sessionStorage.getItem("listino")) {
  initialState.isLoggedIn = true

  const user = JSON.parse(sessionStorage.getItem("user"))
  initialState.user = user
  initialState.jwt = JSON.parse(sessionStorage.getItem("jwt"))
  initialState.listino = user ? user.listino.Tipo : ""
  initialState.listinoPOS = user ? user.listino_pos.Tipo.toLowerCase() : ""
  initialState.role = user ? user.role.name : ""
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      const userData = action.payload
      sessionStorage.setItem("loggedIn", true)
      sessionStorage.setItem("jwt", JSON.stringify(userData.jwt))
      sessionStorage.setItem("user", JSON.stringify(userData.user))
      return {
        ...state,
        isLoggedIn: true,
        user: userData.user,
        listino: userData.user.listino.Tipo,
        listinoPOS: userData.user.listino_pos?.Tipo.toLowerCase() || null,
        role: userData.user.role.name,
        jwt: userData.jwt,
      }
    // // Italia
    // if (email.includes("italia") && password === "italia") {
    //   sessionStorage.setItem("loggedIn", true)
    //   sessionStorage.setItem("listino", "italia")

    //   return {
    //     ...state,
    //     isLoggedIn: true,
    //     listino: "italia",
    //   }
    // }

    // // B2b
    // if (email.includes("b2b") && password === "b2b") {
    //   sessionStorage.setItem("loggedIn", true)
    //   sessionStorage.setItem("listino", "b2b")

    //   return {
    //     ...state,
    //     isLoggedIn: true,
    //     listino: "b2b",
    //   }
    // }

    // // Gddo
    // if (email.includes("offtrade") && password === "offtrade") {
    //   sessionStorage.setItem("loggedIn", true)
    //   sessionStorage.setItem("listino", "gddo")

    //   return {
    //     ...state,
    //     isLoggedIn: true,
    //     listino: "gddo",
    //   }
    // }

    // // Clienti privati
    // if (email.includes("privati") && password === "privati") {
    //   sessionStorage.setItem("loggedIn", true)
    //   sessionStorage.setItem("listino", "clienti_privati")

    //   return {
    //     ...state,
    //     isLoggedIn: true,
    //     listino: "clienti_privati",
    //   }
    // }

    // // Solo consultazione
    // if (email.includes("antinori") && password === "antinori") {
    //   sessionStorage.setItem("loggedIn", true)
    //   sessionStorage.setItem("listino", "consultazione")

    //   return {
    //     ...state,
    //     isLoggedIn: true,
    //     listino: "consultazione",
    //   }
    // }

    case "LOGOUT":
      sessionStorage.removeItem("loggedIn")
      sessionStorage.removeItem("listino")
      sessionStorage.removeItem("listinoPOS")
      return { ...state, isLoggedIn: false }
    default:
      return state
  }
}
