import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useHistory } from "react-router"
import { Paginator } from "../../components/paginator"
import { PosItem } from "../../models/posItem"
import { Grid } from "../../components/grid"
import { HeaderPanel } from "./header-panel/index"
import { useGeneralHelpers } from "../../helpers/useGeneralHelpers"
import toast, { Toaster } from "react-hot-toast"

export const POSListing = () => {
  const auth = useSelector(state => state.auth)
  const location = useLocation()
  const history = useHistory()
  const [posItems, setPosItems] = useState(null)
  const { buildPublicationFilter } = useGeneralHelpers()
  const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/pos-items`

  const [itemsCount, setItemsCount] = useState(null)
  const [brands, setBrands] = useState([])
  const [info, setInfo] = useState(null)

  const notify = (info, isError) =>
    toast(info, {
      className: `font-LatoRegular uppercase text-white text-center ${
        isError ? "bg-error" : "bg-black"
      } bg-opacity-100 opacity-100 rounded-sm tracking-wider text-sm`,
    })

  useEffect(() => {
    if (brands.length === 0) {
      getBrands()
    }

    const searchParams = new URLSearchParams(location.search)
    const brand = searchParams.get("brand")
    const code = searchParams.get("code")
    const order = searchParams.get("order")
    const page = searchParams.get("page")
    const itemsPerPage = searchParams.get("itemsPerPage")

    setInfo({
      brand: brand || "",
      code: code || "",
      order: order || "ASC",
      page: page || 1,
      itemsPerPage: itemsPerPage || 10,
    })
  }, [location])

  useEffect(() => {
    if (info != null) {
      getPosItems()
    }
  }, [info])

  async function getBrands() {
    const fetchBrandsUrl = `${process.env.REACT_APP_API_BASE_URL}/brands`
    const brands = await fetch(fetchBrandsUrl, { method: "GET" })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(() => {
        notify("There was an error while retrieving brands", true)
      })
    setBrands(brands)
  }

  async function getPosItems() {
    const start = (info.page - 1) * info.itemsPerPage
    const offset = `_start=${start}&_limit=${info.itemsPerPage}`

    const brandFilterString = info.brand !== "ALL" ? `&brand.Nome_contains=${info.brand}` : ``
    const codeFilterString = info.code !== "" ? `&codice_articolo_contains=${info.code}` : ``
    const stateFilterString = buildPublicationFilter("ALL")
    const urlFiltersSection = `?_publicationState=preview${stateFilterString}&_sort=codice_articolo:${info.order}&${offset}${codeFilterString}${brandFilterString}`

    countItems(urlFiltersSection)

    const fetchUrl = baseUrl + urlFiltersSection
    const data = await fetch(fetchUrl, { method: "GET" })
      .then(response => {
        if (response.ok === false) {
          notify("There was an error while retrieving pos items", true)
        }
        return response.json()
      })
      .then(data => {
        return data.map(posItem => new PosItem(posItem))
      })
      .catch(() => {
        notify("There was an error while retrieving pos items", true)
      })
    setPosItems(data)
  }

  async function countItems(urlFilterSection) {
    const fetchUrl = `${process.env.REACT_APP_API_BASE_URL}/pos-items/count` + urlFilterSection
    const count = await fetch(fetchUrl, { method: "GET" })
      .then(response => {
        if (response.ok === false) {
          notify("There was an error while counting pos items", true)
        }
        return response.json()
      })
      .then(data => {
        return data
      })
      .catch(() => {
        notify("There was an error while counting pos items", true)
      })
    setItemsCount(count)
  }

  const checkIfUserIsLogged = () => {
    const doesRoleExist = typeof auth.role !== "undefined"
    const isRoleOk = doesRoleExist && auth.role.toLowerCase() === "admin"
    const isUserLoggedIn = auth.isLoggedIn
    return isRoleOk && isUserLoggedIn
  }

  if (checkIfUserIsLogged() === false) {
    history.push("/login", { redirectTo: "/login" })
  }

  const toggleDraftPublished = async (id, turnIntoPublished) => {
    const newState = turnIntoPublished === true ? "published" : "unpublished"
    if (id && typeof turnIntoPublished !== "undefined") {
      const strapiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/pos-items/${id}`
      const bodyData = {
        published_at: turnIntoPublished ? new Date() : null,
      }
      fetch(strapiEndpoint, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(bodyData),
      })
        .then(response => {
          const message = response.ok === true ? `Item ${newState}` : "There was an error while switching state"
          notify(message)
          return response.json()
        })
        .then(data => {
          const updatedItem = new PosItem(data)
          const indexOfelementInsideStateArray = posItems.findIndex(item => item.id === updatedItem.id)
          posItems[indexOfelementInsideStateArray] = updatedItem
          getPosItems()
        })
        .catch(() => {
          notify("There was an error while switching state", true)
        })
    }
  }

  return (
    <>
      {auth.isLoggedIn && (
        <>
          <Toaster />
          {info && (
            <HeaderPanel
              code={info.code}
              brand={info.brand}
              order={info.order}
              brands={brands}
              itemsPerPage={info.itemsPerPage}
            />
          )}

          <div className="w-10/12 my-12 mx-auto font-LatoRegular">
            <Grid
              entity={posItems}
              toggleDraftPublished={(id, turnIntoPublished) => toggleDraftPublished(id, turnIntoPublished)}
            />
            {info && itemsCount !== null && (
              <Paginator
                itemsPerPage={info.itemsPerPage}
                currentPage={parseInt(info.page)}
                totalPages={Math.ceil(itemsCount / info.itemsPerPage)}
                baseURL={`/pos-listing?code=${info.code}&brand=${info.brand}&order=${info.order}`}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}
