import React, { useRef, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { motion } from "framer-motion"
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size"
import { useOnClickOutside } from "../../helpers/useOnClickOutside"
import { getImageUrl } from "../../utils/getImageUrl"

import { WishlistCreationModal } from "../wishlist-creation-modal"
import WishlistIcon from "../wishlist-icon"
import Rodal from "rodal"
import "./style.css"
import placeholder from "./ugo.jpg"

export const WishlistPanel = ({
  e,
  wine,
  reference,
  referenceSize,
  noWrap,
  showIcon,
  hideQuantity = false,
  moveItem = false,
  album,
  colorName,
  resetPopup = () => {},
}) => {
  const windowWidth = useWindowWidth()
  const windowHeight = useWindowHeight()
  const dispatch = useDispatch()
  const wishlists = useSelector(state => state.winesWishlist)
  const container = useRef(null)
  const [wishlistManagement, setWishlistManagement] = useState({
    isSelectingWishlist: false,
    isCreatingWishlist: false,
    popupPosition: {
      top: 0,
      left: 0,
    },
    foucusedWishlist: null,
  })
  const [itemQuantity, setItemQuantity] = useState(wine.quantity ? wine.quantity : 1)

  useOnClickOutside(container, () => {
    if (wishlistManagement.isCreatingWishlist) {
      return
    }
    if (wishlistManagement.isSelectingWishlist) {
      setWishlistManagement({
        ...wishlistManagement,
        isSelectingWishlist: false,
      })
    }
    if (resetPopup) {
      resetPopup()
    } else {
      setWishlistManagement({
        ...wishlistManagement,
        isSelectingWishlist: false,
      })
    }
  })

  useEffect(() => {
    if (e) {
      calcPopupPosition()
    }
  }, [wine])

  useEffect(() => {
    wine.quantity = +itemQuantity

    if (album && wine) {
      dispatch({
        type: "WINES_WISHLIST_UPDATE_ALBUM",
        payload: { album: album },
      })
    }
  }, [itemQuantity])

  // useEffect(() => {
  //   console.log(wine, wishlistManagement.isCreatingWishlist)
  // }, [wishlistManagement.isCreatingWishlist])

  function calcPopupPosition() {
    let rect = e.getBoundingClientRect()
    let gridRect = reference
    let scrollLeft = gridRect.scrollLeft
    let scrollTop = gridRect.scrollTop
    let definedWidth = windowWidth < 1024 ? (windowWidth < 768 ? 0.41 : 0.26) : 0.21
    let preTestLeft = rect.left + scrollLeft - windowWidth * definedWidth

    let finalTop = 0
    let finalLeft = 0

    switch (referenceSize) {
      default:
        finalTop = rect.top + scrollTop - gridRect.getBoundingClientRect().top + 32
        finalLeft = rect.left + scrollLeft - windowWidth * definedWidth + 32
        break
      case "griglia-small":
        finalTop = rect.top + scrollTop - gridRect.getBoundingClientRect().top + 32
        finalLeft = preTestLeft < 0 ? preTestLeft - scrollLeft + 42 : preTestLeft - scrollLeft + 28
        break
      case "lista":
        finalTop = rect.top + 32 > windowHeight - 200 ? rect.top - 128 : rect.top + 32
        finalLeft = rect.left
        break
      case "window":
        finalTop = rect.top + scrollTop - gridRect.getBoundingClientRect().top + 32
        finalLeft = preTestLeft < 0 ? preTestLeft - scrollLeft + 42 : preTestLeft - scrollLeft + 28
    }

    setWishlistManagement({
      ...wishlistManagement,
      popupPosition: {
        position: "absolute",
        display: "block",
        top: finalTop,
        left: finalLeft < 0 ? 0 : finalLeft,
        visibility: "visible",
        zIndex: 30,
      },
    })
  }

  function removeItem(wishlist) {
    wishlist.items = wishlist.items.filter(item => item.id !== wine.id)
    dispatch({ type: "WINES_WISHLIST_REMOVE", payload: wishlist })
  }

  function addToWishlist(wishlist) {
    if (Array.isArray(wine)) {
      //SE AGGIUNGO TUTTI I VINI DI UNA TENUTA
      let winesToAdd = []

      wine.forEach(item => {
        //const found = wishlist.items ? wishlist.items.find(wine => wine.Id === item.Id ) : item
        let found = wishlist.items?.find(wine => wine.id === item.id)
        if (found == null) {
          winesToAdd.push(item)
        }
      })
      let newWishlistItems = wishlist.items ? wishlist.items.concat(winesToAdd) : winesToAdd
      wishlist.items = newWishlistItems
      dispatch({ type: "WINES_WISHLIST_ADD", payload: wishlist })
    } else {
      //SE AGGIUNGO SOLO UN VINO
      let presence = wishlist.items ? wishlist.items.find(item => item.id === wine.id) : null
      if (presence) {
        //SE IL VINO E' GIA PRESENTE NELLA WISHLIST
        setWishlistManagement({
          ...wishlistManagement,
          foucusedWishlist: wishlist,
        })
        return
      } else {
        //SE NON E' PRSENTE NELLA WISHLIST
        wishlist.items === null ? (wishlist.items = [wine]) : wishlist.items.push(wine)
        dispatch({ type: "WINES_WISHLIST_ADD", payload: wishlist })
        if (moveItem) {
          removeItem(album)
        }
      }
    }
    setWishlistManagement({
      ...wishlistManagement,
      isSelectingWishlist: false,
    })
    resetPopup()
  }

  const handleQuantityChange = event => {
    if (!event.target.value || event.target.value <= 0) {
      setItemQuantity(1)
      return
    }

    setItemQuantity(event.target.value)
  }

  return (
    wine && (
      <>
        {wishlistManagement.isCreatingWishlist && (
          <WishlistCreationModal
            wine={wine}
            resetModal={e => {
              if (e?.hasOwnProperty("items") && moveItem) {
                removeItem(album)
              }
              setWishlistManagement({
                ...wishlistManagement,
                isCreatingWishlist: false,
                isSelectingWishlist: false,
                popupPosition: { ...wishlistManagement.popupPosition, display: !noWrap ? "none" : "block" },
              })
            }}
          />
        )}
        {wishlistManagement.foucusedWishlist && (
          <WishlistMessage
            closeModal={e => setWishlistManagement({ ...wishlistManagement, foucusedWishlist: null })}
            wishlist={wishlistManagement.foucusedWishlist}
            wine={wine}
          />
        )}
        <div
          ref={container}
          style={wishlistManagement.popupPosition}
          className={`${noWrap ? "w-full " : "invisible w-1/2 z-20 max-w-[500px]"}
                      ${moveItem ? "absolute bottom-0 " : ""} 
                      ${colorName ? "bg-" + colorName : ""}`}
        >
          <div className="flex gap-x-6 items-center px-4 ">
            {!noWrap && (
              <div className="m-2">
                <FormattedMessage id="wishlist.popup.wishlist" />
              </div>
            )}

            {showIcon && (
              <div className="align-middle ">
                <WishlistIcon color="#000000" />
              </div>
            )}
            <div className={` ${showIcon ? "w-11/12" : "w-full"} inline-block relative my-2 cursor-pointer`}>
              <div className="flex gap-x-2">
                {/* {!hideQuantity && (
                  <input
                    className="bg-hazelnut text-center w-12 text-black"
                    type="number"
                    min={1}
                    value={itemQuantity}
                    onChange={handleQuantityChange}
                  />
                )} */}
                <div
                  className={`font-LatoRegular bg-hazelnut bg-blend-multiply text-black w-full p-2 px-3`}
                  onClick={e =>
                    setWishlistManagement({
                      ...wishlistManagement,
                      isSelectingWishlist: !wishlistManagement.isSelectingWishlist,
                    })
                  }
                >
                  {moveItem ? (
                    <FormattedMessage id="wishlist.popup.sposta" />
                  ) : Array.isArray(wine) ? (
                    <FormattedMessage id={"wishlist.popup.aggiungi_tenuta_wishlist"} />
                  ) : (
                    <FormattedMessage
                      id={windowWidth < 1240 ? "wishlist.popup.aggiungi" : "wishlist.popup.aggiunti_a_wishlist"}
                    />
                  )}
                </div>
                <div
                  className="bg-brown text-white w-10 p-2 content-center"
                  onClick={e =>
                    setWishlistManagement({
                      ...wishlistManagement,
                      isSelectingWishlist: !wishlistManagement.isSelectingWishlist,
                    })
                  }
                >
                  <svg
                    id="selector"
                    className="h-full w-4 m-auto"
                    viewBox="0 0 20 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.5 1.5L9.99981 9.5L18.5 1.5" stroke="#F1F0EB" strokeWidth="2" />
                  </svg>
                </div>
              </div>
              {wishlistManagement.isSelectingWishlist && (
                <motion.div className="absolute w-full bg-hazelnut mt-2 overflow-x-scroll max-h-48 z-20 ">
                  {wishlists?.length > 0 &&
                    wishlists.map(item => (
                      <div
                        key={item.id}
                        onClick={e => addToWishlist(item)}
                        className="flex items-center bg-blend-multiply text-black w-full p-3 py-3 hover:bg-brown hover:text-white cursor-pointer"
                      >
                        {windowWidth >= 1240 && (
                          <img
                            src={
                              item.items != null && item.items[0]?.Foto
                                ? getImageUrl(item.items[0]?.Foto.url, 800)
                                : placeholder
                            }
                            className="w-8 h-8 mx-auto"
                          />
                        )}
                        <div className="pl-3 flex-grow ">{item.name}</div>
                      </div>
                    ))}

                  {/* {wishlists.length > 0 && (
                  <div className="px-3 mt-2 opacity-50">
                    <div className="w-full m-auto border-t border-0.5 border-black"></div>
                  </div>
                )} */}
                  <div
                    className="text-black w-full px-3 py-3 hover:bg-brown hover:text-white flex items-center"
                    onClick={e => {
                      setWishlistManagement({ ...wishlistManagement, isCreatingWishlist: true })
                    }}
                  >
                    <span className="font-LatoRegular inline-block text-xl w-6 text-center"> + </span>
                    <span className="pl-2 flex-grow">
                      <FormattedMessage id="wishlst.popup.crea_una_lista" />
                    </span>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  )
}

function WishlistMessage(props) {
  const windowWidth = useWindowWidth()
  function closeModal(e) {
    e.preventDefault()
    props.closeModal()
  }

  return (
    <Rodal
      measure={"px"}
      width={windowWidth <= 640 ? windowWidth * 0.95 : 560}
      height={windowWidth <= 640 ? 540 : 392}
      visible={true}
      animation="fade"
      duration={300}
      onClose={closeModal}
      className="h-3/5"
    >
      <div id="message-modal" className="bg-tenute h-full w-full">
        <div className="font-LatoBold py-6 px-8 text-black">
          <FormattedMessage id="wishlist.popup.articolo_gia_in_lista" /> <br />
          <Link className="text-gray" to={`/wishlist-details/${props.wishlist.id}`}>
            {props.wishlist.name}
          </Link>
        </div>
        <div className="border-t h-0.5 border-black"> </div>
        <div className="py-6 px-8 border-1">
          <div className="block w-full sm:inline-block sm:w-1/3 align-middle">
            <img
              src={props.wine?.Foto ? getImageUrl(props.wine.Foto.url, 800) : placeholder}
              className="w-full h-34"
              alt={props.wine.Name}
            />
          </div>
          <div className="block mt-2 w-full align-middle text-black sm:inline-block sm:w-2/3 sm:mt-0 px-5">
            <h3 className="text-2xl font-BodoniBook">{props.wine.Nome}</h3>
            <p className="font-LatoRegular text-gray uppercase tracking-widest">{props.wine.Denominazione} </p>
          </div>
          <div className="flex gap-x-12 gap-y-2 justify-between mt-8">
            <Link
              to={`/wishlist-details/${props.wishlist.id}`}
              className="w-full bg-slateGray  uppercase text-white text-center px-4 py-5 font-LatoBold text-11px mt-4 tracking-2"
            >
              <FormattedMessage id="wishlist.popup.visualizza_lista" />
            </Link>
            <button
              className="w-full  bg-brown  uppercase text-white text-center px-3 py-5 font-LatoBold text-11px mt-4 tracking-2"
              onClick={e => closeModal(e)}
            >
              <FormattedMessage id="wishlist.popup.continua" />
            </button>
          </div>
        </div>
      </div>
    </Rodal>
  )
}
