import { motion } from "framer-motion"
import React from "react"
import imgFormatoBottiglia from "../vino/formato.png"

export default function FormatoBottiglia({ formato, k }) {
  let scale

  switch (parseInt(formato)) {
    case 37:
    case 37.5:
      scale = 0.375
      break
    case 50:
      scale = 0.4
      break
    case 70:
    case 75:
      scale = 0.5
      break
    case 150:
      scale = 0.6
      break
    case 300:
      scale = 0.7
      break
    case 600:
      scale = 0.8
      break
    case 900:
      scale = 0.9
      break
    default:
      break
  }

  return (
    <motion.div
      key={k}
      className="flex flex-col items-center"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { delay: k * 0.2, type: "tween", duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <img src={imgFormatoBottiglia} style={{ transform: `scale(${scale})` }} className="transform origin-bottom" />
      <div className="text-gray text-xs md:text-sm font-LatoRegular mt-2">cl. {formato}</div>
    </motion.div>
  )
}
