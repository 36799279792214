import React from "react"
import ReactDOM from "react-dom"
import { useDispatch, useSelector } from "react-redux"
import Rodal from "rodal"

import ChevronIcon from "../chevron-icon"
import Header from "../header"

import "./details.css"
import { getImageUrl, getValidatedImageUrl } from "../../utils/getImageUrl"
import { WishlistButton } from "../wishlist-button"

export default function Details({ isOpen, onClose, product }) {
  const auth = useSelector(state => state.auth)
  const wishlist = useSelector(state => state.wishList)
  const isInWishlist = wishlist.giftboxWishlist && wishlist.giftboxWishlist.find(box => box.id === product.id)

  const modal = (
    <Rodal
      measure="%"
      width={100}
      height={100}
      visible={isOpen}
      onClose={() => onClose("x")}
      animation="fade"
      duration={400}
      customStyles={{ display: "flex" }}
      className="ProductDetailModal"
    >
      <Header isMinimal />
      <div className="Confezione relative [ p-4 lg:p-6 pt-32 lg:pt-40 ] [ lg:grid lg:grid-cols-2 items-center ]">
        <div
          className="lg:absolute lg:top-0 lg:mt-40 pl-8 left-0 z-10 cursor-pointer hover:text-gray"
          onClick={onClose}
        >
          <div className="flex items-center">
            <ChevronIcon className="transform rotate-90 mr-2" />
            Indietro
          </div>
        </div>

        <img src={getImageUrl(product.foto?.url || "")} style={{ flex: 1 }} />

        <div>
          {product.brands.length > 0 && (
            <div className="Details__LogoContainer mb-12 pt-8 lg:pt-0 flex">
              <img src={getImageUrl(getValidatedImageUrl(product))} className="object-contain" />
            </div>
          )}
          <div className="Confezione__Data__Descrizione uppercase [ text-[30px] lg:text-[50px] font-BodoniBook leading-tight ] mb-12">
            {product.descrizione.substring(0, 1).toUpperCase() +
              product.descrizione.substring(1, product.descrizione.length).toLowerCase()}
          </div>

          <div>
            <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
              Codice
            </span>
            <span className="font-BodoniBook text-20px ml-2">{product.codice_confezione}</span>
          </div>

          {auth.listino !== "consultazione" && product[`prezzo_${auth.listino}`] && (
            <div>
              <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
                Prezzo
              </span>
              <span className="font-BodoniBook text-20px ml-2">
                € {product[`prezzo_${auth.listino}`].toFixed(2)} Iva escl.
              </span>
            </div>
          )}

          {product.scala_sconti && (
            <div>
              <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
                Scala sconti
              </span>
              <span className="font-BodoniBook text-20px ml-2 capitalize">
                {product.scala_sconti === "no" ? "No" : "Sì"}
              </span>
            </div>
          )}

          {product.edizione && (
            <div>
              <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
                Edizione
              </span>
              <span className="font-BodoniBook text-20px ml-2">{product.edizione}</span>
            </div>
          )}

          {product.bottiglie && product.bottiglie.length > 0 && (
            <div>
              <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px tracking-1">
                Formato
              </span>
              <span className="font-BodoniBook text-20px ml-2 capitalize">
                {product.bottiglie[0]?.bottle_format?.formato && (
                  <>
                    {product.bottiglie[0].bottle_format.formato} <span className="lowercase">cl</span>
                  </>
                )}
              </span>
            </div>
          )}

          {product.bottiglie && (
            <div className="flex flex-row">
              <span className="Confezione__Data__Label text-gray uppercase font-LatoBold text-11px leading-9 tracking-1">
                Vino
              </span>
              <span className="font-BodoniBook text-20px ml-2 inline">
                {product.bottiglie.map((bottiglia, index) => (
                  <div key={index}>{bottiglia.label}</div>
                ))}
              </span>
            </div>
          )}

          <WishlistButton product={product} isInWishlist={isInWishlist} isInDetailsPage />
        </div>
      </div>
    </Rodal>
  )

  return ReactDOM.createPortal(modal, document.body)
}
