import React from "react"
import { motion } from "framer-motion"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

export default function MobileMenu({ onClose }) {
  const filters = useSelector(state => state.wines.filters)
  const dispatch = useDispatch()

  function changeFilterDistribuzione(payload) {
    dispatch({ type: "CHANGE_FILTER_DISTRIBUZIONE", payload })
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="MobileMenu bg-black text-white fixed inset-0 z-20 pt-20 flex items-center justify-center"
    >
      <div>
        <div
          className={`font-BodoniBook text-4xl text-center ${
            filters.distribuzione === "Antinori" ? "text-tenute" : "text-gray cursor-pointer"
          }`}
          onClick={() => {
            changeFilterDistribuzione("Antinori")
            onClose()
          }}
        >
          <FormattedMessage id="tenute.landing.vini_antinori" />
        </div>
        <div
          className={`font-BodoniBook text-4xl text-center mt-4 ${
            filters.distribuzione === "Distribuiti" ? "text-tenute" : "text-gray cursor-pointer"
          }`}
          onClick={() => {
            changeFilterDistribuzione("Distribuiti")
            onClose()
          }}
        >
          <FormattedMessage id="tenute.landing.vini_in_distribuzione" />
        </div>
      </div>
    </motion.div>
  )
}
