import React, { useState } from "react"
import Rodal from "rodal"
import ReactDOM from "react-dom"
import "./style.css"
import Filters from "../filters"

export default function FiltersMobile() {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div className="p-6 xl:hidden">
      <div
        className="
          font-LatoBold uppercase text-11px tracking-2 
          border border-gray 
          py-6 px-8
          flex items-center justify-between
        "
        onClick={() => {
          document.body.style.overflow = "hidden"
          setModalVisible(true)
        }}
      >
        Filtra per
        <ChevronIcon />
      </div>

      <FilterModal
        isOpen={modalVisible}
        onClose={() => {
          document.body.style.overflow = "auto"
          setModalVisible(false)
        }}
      />
    </div>
  )
}

function ChevronIcon(props) {
  return (
    <svg width={15} height={8} viewBox="0 0 15 8" {...props}>
      <path d="M.5.5l6.952 7L14.5.5" stroke="#454545" strokeWidth="2" fill="none" fillRule="evenodd" />
    </svg>
  )
}

function FilterModal({ isOpen, onClose }) {
  const modal = (
    <Rodal
      measure="%"
      width={100}
      height={100}
      visible={isOpen}
      onClose={() => onClose("x")}
      animation="fade"
      duration={300}
      className="FiltersModal"
    >
      <div className="py-16">
        <Filters isMobile />
      </div>
    </Rodal>
  )

  return ReactDOM.createPortal(modal, document.body)
}
