import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import Layout from "../../components/layout"
import resetIcon from "../../img/reset-icon.svg"
import { GiftBox } from "../../components/gift-box"
import LineaDiagonale from "../../components/linea-diagonale"
import Spinner from "../../components/spinner"
import { PosItem } from "../../components/pos-item"
import "./style.css"
import { FormattedMessage, useIntl } from "react-intl"

export const WishlistPage = () => {
  const wishlist = useSelector(state => state.wishList)

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const auth = useSelector(state => state.auth)
  const history = useHistory()
  const location = useLocation()
  const intl = useIntl()
  const [liveProducts, setLiveProducts] = useState([])
  const [clientName, setClientName] = useState("")

  const currentCategory = location.pathname.includes("pos") ? "positem" : "giftbox"
  const currentWishlist = location.pathname.includes("pos") ? wishlist.positemWishlist : wishlist.giftboxWishlist
  const designedPathnameForWishlistMail = currentCategory === "positem" ? "pos-pdf" : "pdf"
  const designedEndpointForWishlistMail = `${process.env.REACT_APP_API_BACKEND_URL}/${designedPathnameForWishlistMail}`

  //this function was created to avoid showing inside the wishlist products that are set as draft on strapi
  //it basically asks strapi which of the listed products are currently published
  //when the live products are returned they're set aa state which is subsequentially used to filter wishlist products
  //that are no live anymore rows 169 & 172
  const getLiveProducts = async () => {
    const productsPathName = currentCategory === "positem" ? "pos-items" : "gift-boxes"
    let url = `${process.env.REACT_APP_API_BASE_URL}/${productsPathName}`

    currentWishlist.map((p, index) => {
      const query = index === 0 ? `?id_in=${p.id}` : `&id_in=${p.id}`
      url += query
    })

    await fetch(url, { method: "GET" })
      .then(response => {
        return response.json()
      })
      .then(data => {
        data.map(item => {
          if (wishlist.giftboxWishlist) {
            const itemQnt = wishlist.giftboxWishlist.find(localItem => localItem.id === item.id)?.quantity || 1
            item.quantity = itemQnt
          }
          if (wishlist.positemWishlist) {
            const itemQnt = wishlist.positemWishlist.find(localItem => localItem.id === item.id)?.quantity || 1
            item.quantity = itemQnt
          }
          return item
        })
        setLiveProducts(data)
      })
  }

  useEffect(() => {
    getLiveProducts()

    document.body.classList.add("bg-black")
    return function cleanup() {
      document.body.classList.remove("bg-black")
    }
  }, [])

  if (!auth.isLoggedIn && location.pathname !== `/login`) {
    history.push("/login")
    return null
  }

  function resetWishlist() {
    dispatch({ type: "WISHLIST_RESET" })
  }

  async function handleSubmit(e) {
    if (isLoading) {
      return
    }

    e.preventDefault()
    setIsLoading(true)

    const item = liveProducts.map(p => {
      const storedProduct = currentWishlist.find(storedItem => storedItem.id === p.id)

      return { id: p.id, quantity: storedProduct.quantity }
    })

    // console.log(
    //   `${designedEndpointForWishlistMail}?products=${commaSeparatedProducts}&listino=${auth.listino}&to=${email}`
    // )
    // debugger
    // const url = `${designedEndpointForWishlistMail}?products=${commaSeparatedProducts}&listino=${
    //   auth.listino
    // }&to=${email}&clientName=${encodeURIComponent(clientName)}`
    // const url = designedEndpointForWishlistMail

    const postObj = {
      to: email,
      clientName: clientName,
      listino: auth.listino,
      wishlist: item,
    }

    const emailResponse = await fetch(designedEndpointForWishlistMail, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postObj),
    })

    const result = await emailResponse.text()

    setIsLoading(false)

    if (result === "OK") {
      alert("Wishlist inviata correttamente")
    } else {
      alert("Errore nell'invio della wishlist, riprovare o contattare l'assistenza")
    }
  }

  return (
    <Layout fullHeight>
      <div className="Wishlist h-full bg-black text-white px-6 lg:px-32 py-8 overflow-y-auto">
        {/* Wishlist vuota */}

        {currentWishlist?.length === 0 && (
          <div className="Wishlist__Empty h-full flex flex-col items-center justify-center">
            <div className="font-BodoniBook text-center text-4xl lg:text-6xl text-gray leading-none">
              Wishlist <FormattedMessage id="wishlist.details.wishlist_vuota" />
            </div>
            <Link
              to={currentCategory === "positem" ? "/pos" : "/confezioni-regalo"}
              className="font-LatoBold uppercase text-11px text-white mt-8 tracking-2 px-4 py-2 flex flex-row items-center gap-2"
            >
              <LineaDiagonale white />
              <FormattedMessage id="tenute.wishlist.vai_al_catalogo" />
            </Link>
          </div>
        )}

        {/* Wishlist piena */}

        {currentWishlist?.length > 0 && (
          <>
            <div
              className="mb-8 font-LatoBold text-11px text-gray tracking-2 uppercase flex items-center cursor-pointer"
              onClick={resetWishlist}
            >
              <img className="block mr-3" src={resetIcon} />
              Reset
            </div>

            <h1 className="font-BodoniBook text-40px lg:text-60px leading-none text-white">
              {currentCategory === "positem" ? (
                <FormattedMessage id="tenute.wishlist.pos_wishlist" />
              ) : (
                <FormattedMessage id="tenute.wishlist.giftbox_wishlist" />
              )}
            </h1>

            <form onSubmit={handleSubmit} className="mt-24 flex items-center gap-6 pr-[1.25rem]">
              <input
                type="text"
                required
                value={clientName}
                onChange={e => setClientName(e.target.value)}
                className="outline-none border-b-2 w-full placeholder-white placeholder-opacity-50 border-white  border-opacity-50 text-white bg-transparent py-2 font-LatoRegular lg:text-lg"
                placeholder={intl.formatMessage({ id: "tenute.wishlist.inserisci_nome" })}
              />
              <input
                type="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="Wishlist__InputEmail text-white bg-transparent py-2 font-LatoRegular lg:text-lg"
                placeholder={intl.formatMessage({ id: "tenute.wishlist.inserisci_email" })}
              />

              <button className="Wishlist__SubmitBtn w-6">
                {isLoading && <Spinner />}
                {!isLoading && (
                  <svg stroke="white" strokeWidth="2" viewBox="0 0 50 50">
                    <line x1="3" y1="25" x2="47" y2="25"></line>
                    <line x1="47" y1="25" x2="25" y2="3"></line>
                    <line x1="47" y1="25" x2="25" y2="47"></line>
                  </svg>
                )}
              </button>
            </form>

            <div className="Wishlist__Prodotti mt-32 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {currentWishlist?.map(node => {
                const isLiveProduct = liveProducts.find(liveItem => liveItem.id === node.id)
                if (currentCategory === "giftbox") {
                  const priceListino = node[`prezzo_${auth.listino}`]
                  if (priceListino !== null && priceListino !== "") {
                    return isLiveProduct && <GiftBox key={node.id} product={node} showRemoveIcon />
                  }
                } else {
                  return isLiveProduct && <PosItem key={node.id} posItem={node} showRemoveIcon />
                }
              })}
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}
