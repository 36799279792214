import { sortBrandsAlphabetically } from "../utils"

const initialState = {
  allBrands: [],
  allProducts: [],
  allOggettistica: [],
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case "LOADED_GIFT_BOXES":
      const brandsObject = {}
      const allProducts = [...action.payload]

      allProducts.forEach(product => {
        product.brands.forEach(brand => {
          if (!brandsObject[brand.Nome]) {
            brandsObject[brand.Nome] = { ...brand, products: [] }
          }
          brandsObject[brand.Nome].products.push(product)
        })
      })

      const brandsArray = Object.keys(brandsObject)
        .map(key => brandsObject[key])
        .filter(brand => brand.products.length > 0)
        .sort(sortBrandsAlphabetically)

      return { ...state, allBrands: brandsArray, allProducts }

    case "LOADED_OGGETTISTICA":
      return { ...state, allOggettistica: action.payload }

    default:
      return state
  }
}
